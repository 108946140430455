<template lang="pug">
main#red_Tape
    pageTop(:pageTitle="$t('__PRODUCTS.red_Tape.title')" imgSrc="Products/red_Tape/redmud page top img_desktop.jpg" imgSrc_Mobile="Products/red_Tape/redmud page top img_mobile.jpg")
    Product_Template(colorType="coil" :importProduct_Data="red_Tape_Data")
        template(v-slot:specification_Form)
            //- .slot_Area
            //-     .title
            //-         h2.productTemplate_H2 規格表
            .formatTable
                ul
                    li {{$t('__PRODUCTS.__TEMPLATE.coil_Form_header.thickness')}}
                    li 1.5mm - 3.0mm
                ul
                    li {{$t('__PRODUCTS.__TEMPLATE.coil_Form_header.width')}}
                    li 72”(182cm)
                ul
                    li {{$t('__PRODUCTS.__TEMPLATE.coil_Form_header.rollpackaging')}}
                    li 25-35 yard/roll
</template>

<script>
import pageTop from "@/components/pageTop.vue"
import Product_Template from "@/components/Product_Template.vue"
// import Button_Style from "@/components/Button_Style.vue"

export default {
    metaInfo() {
        return{
            title: `${this.$t('__PRODUCTS.red_Tape.title')}-`,
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: this.$t('__PRODUCTS.red_Tape.Page.description')
                },
                // Open Graph / Facebook
                { property: 'og:url', content: `${this.$t('__MAIN.domainName_URL')}/Products-${this.$route.params.type}/red_Tape` },
                { property: 'og:description', content: this.$t('__PRODUCTS.red_Tape.Page.description') },

                // Twitter
                { property: 'twitter:url', content: `${this.$t('__MAIN.domainName_URL')}/Products-${this.$route.params.type}/red_Tape` },
                { property: 'twitter:description', content: this.$t('__PRODUCTS.red_Tape.Page.description') },
            ]
        }
    },

    components: {
        pageTop,
        Product_Template
        // Button_Style
    },

    data() {
        return{
            red_Tape_Data: {
                introduce: {
                    title: this.$t('__PRODUCTS.red_Tape.title'),
                    introText: this.$t('__PRODUCTS.red_Tape.Page.introText'),
                    swiper_Img: [
                        "Products/red_Tape/products discription_redmud1.jpg",
                        "Products/red_Tape/products discription_redmud2.jpg",
                        "Products/red_Tape/products discription_redmud3.jpg",
                    ],
                    featureList: this.$t('__PRODUCTS.red_Tape.Page.featureList')
                },
                advantageText: this.$t('__PRODUCTS.red_Tape.Page.advantageText'),
                productApp: {
                    appText: this.$t('__PRODUCTS.red_Tape.Page.applicationText'),
                    circle_Img: [
                        {
                            imgSrc: "Products/red_Tape/products application_redmud1.jpg",
                            altText: this.$t('__PRODUCTS.red_Tape.Page.application_Img_alt[0]')
                        },
                        {
                            imgSrc: "Products/red_Tape/products application_redmud2.jpg",
                            altText: this.$t('__PRODUCTS.red_Tape.Page.application_Img_alt[1]')
                        },
                        {
                            imgSrc: "Products/red_Tape/products application_redmud3.jpg",
                            altText: this.$t('__PRODUCTS.red_Tape.Page.application_Img_alt[2]')
                        },
                        {
                            imgSrc: "Products/red_Tape/products application_redmud4.jpg",
                            altText: this.$t('__PRODUCTS.red_Tape.Page.application_Img_alt[3]')
                        }
                    ]
                    
                    // [
                    //     "Products/red_Tape/products application_redmud1.jpg",
                    //     "Products/red_Tape/products application_redmud2.jpg",
                    //     "Products/red_Tape/products application_redmud3.jpg",
                    //     "Products/red_Tape/products application_redmud4.jpg",
                    // ]
                },
                // mediaList: [
                //     {
                //         id: 0,
                //         isDisplay: true,
                //         mediaTitle: "長庚大學屋頂防水工程",
                //         mediaSrc: "https://www.youtube.com/embed/bmPVtNaXJ3k",
                //     },
                //     {
                //         id: 1,
                //         isDisplay: false,
                //         mediaTitle: "透水路面施作",
                //         mediaSrc: "https://www.youtube.com/embed/N3kHb3ZwFBM",
                //     },
                // ],
                fileList: this.$t('__PRODUCTS.red_Tape.Page.fileList')
            }
        }
    },

    methods: {
        getImgUrl(pic) {
            return require('@/assets/' + pic)
        },
    },
}
</script>

<style lang="sass">
@import "@/css/my_Style.sass"
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap');

.tw #red_Tape
    // .slot_Area
    //     *
    //         // border: 1px solid #000
    //     .title
    //         border-bottom: 1px solid #707070
    //         padding-bottom: 18px

    .formatTable
        +size(100%, 130px)
        min-width: 476px
        display: flex
        border: solid 1px #767777
        ul
            flex: 1
            li
                +text_Setting(calc(14px + 0.5vw), 1.33, 1.2px, #707070, 300, "Noto Sans TC", center)
                +flex_Center_Setting
                border: solid 1px #b5baba
                padding: 10px
                @include _Upper_1920_width()
                    font-size: 24px
            li:nth-child(1)
                background-color: #0e3347
                color: #fafafa
                height: 40%
                & ~ li
                    height: 60%

// ==== 英文版CSS ====
.en 
    // 繼承tw css
    @extend .tw
.en #red_Tape
    
</style>        