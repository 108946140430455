<template lang="pug">
nav#navbar(:class="{navbarHide: navbar_hide_Control}")
    .navbar.navbar-expand-lg.navbar-light(:class="{navbar_isExpand: navbar_isExpand}")
        router-link.logoLink.navbar-brand(:to="{path: `/${computed_routeName_Lang_from_Vuex}`}")
            img.logoImg(src="@/assets/logo.png")
            img.mobileImg(src="@/assets/logo.png" @click="click_toggle_Button" v-show="!navbar_isExpand")
            img.mobileImg(src="@/assets/logo_white.png" @click="click_toggle_Button" v-show="navbar_isExpand")
        button#toggle_Button.navbar-toggler.collapsed(@click="navbar_toggle_event" type='button' data-toggle='collapse' data-target='#navbarContent' aria-controls='navbarContent' aria-expanded='false' aria-label='Toggle navigation')
            //- span.navbar-toggler-icon
            .line.line1
            .line.line2
            .line.line3
        #navbarContent.collapse.navbar-collapse.justify-content-end
            ul.navbar-nav.navbarList

                //- 公司簡介
                li.nav-item(@click="click_toggle_Button")
                    router-link.nav-link.navLink_Setting(:to="{name: `Company${computed_routeName_Lang_addBaseline_from_Vuex}`}") {{$t('__MAIN_TITLE.Company')}}
                        //- span.sr-only (current)
                //- 產品介紹
                li.nav-item.nav-productMenu.d-none.d-lg-block(@click="productMenu_isActive = false" @mouseover="productMenu_isActive = true" @mouseleave="productMenu_isActive = false")
                    router-link.nav-link.navLink_Setting(:to="{name: `Products${computed_routeName_Lang_addBaseline_from_Vuex}`, params: {type: 'coil'}}") {{$t('__MAIN_TITLE.Products')}}
                    .productMenu_Container(v-show="productMenu_isActive" @mouseover="productMenu_isActive = true" @mouseleave="productMenu_isActive = false")
                        .row
                            //- 捲材類
                            .productMenu.coil_Menu
                                .menuTitle
                                    router-link.menuProduct_Link_Title(:to="{name: `Products${computed_routeName_Lang_addBaseline_from_Vuex}`, params: {type: 'coil'}}" @click="productMenu_isActive = false") {{$t('__TYPE.coil')}}
                                .productMenuList
                                    router-link.menuProduct_Link(v-for="(product, index) of productMenu_Data.coil" :key="product"
                                                                :to="{name: `${index}${computed_routeName_Lang_addBaseline_from_Vuex}`, params: {type: 'coil'}}" 
                                                                @click="productMenu_isActive = false") {{ product }}
                                        b-icon(icon="arrow-right")
                            //- 塗料類
                            .productMenu.coating_Menu
                                .menuTitle
                                    router-link.menuProduct_Link_Title(:to="{name: `Products${computed_routeName_Lang_addBaseline_from_Vuex}`, params: {type: 'coating'}}" @click="productMenu_isActive = false") {{$t('__TYPE.coating')}}
                                .productMenuList
                                    router-link.menuProduct_Link(v-for="(product, index) of productMenu_Data.coating" :key="product"
                                                                :to="{name: `${index}${computed_routeName_Lang_addBaseline_from_Vuex}`, params: {type: 'coating'}}" 
                                                                @click="productMenu_isActive = false") {{ product }}
                                        b-icon(icon="arrow-right")
                                    //- router-link.menuProduct_Link(:to="{name: `PU_Waterproof${computed_routeName_Lang_addBaseline_from_Vuex}`, params: {type: 'coating'}}" @click="productMenu_isActive = false") PU防水材
                                    //-     b-icon(icon="arrow-right")
                        .row
                            a.menuProduct_Mall_Link(:href="$t('__BUTTON_TEXT.PlasticMall_Link')", :title="$t('__BUTTON_TEXT.PlasticMallName')", target="_blank") {{$t('__BUTTON_TEXT.more_NanYaProducts')}}
                                b-icon(icon="arrow-right-circle")
                            //- img(src="@/assets/nanya-plastics-mall-IMG.png")
                //- 產品介紹-摺疊
                li.nav-item.d-block.d-lg-none.nav-item_FoldMenu
                    .navbar-toggler.collapsed.navLink_Setting(data-toggle='collapse' data-target='#products_List' aria-controls='products_List' aria-expanded='false' aria-label='Toggle navigation') {{$t('__MAIN_TITLE.Products')}}
                        b-icon(icon="chevron-up")
                        //- b-icon(icon="chevron-down")
                    #products_List.collapse.navbar-collapse
                        ul.navbar-nav.ml-auto
                            li.nav-item(@click="click_toggle_Button")
                                router-link.navLink_Setting(:to="{name: `Products${computed_routeName_Lang_addBaseline_from_Vuex}`, params: {type: 'coil'}}") {{$t('__TYPE.coil')}}
                                    .arrowRight
                            li.nav-item(@click="click_toggle_Button")
                                router-link.navLink_Setting(:to="{name: `Products${computed_routeName_Lang_addBaseline_from_Vuex}`, params: {type: 'coating'}}") {{$t('__TYPE.coating')}}
                                    .arrowRight
                
                //- 使用案例
                li.nav-item(@click="click_toggle_Button")
                    router-link.nav-link.navLink_Setting(:to="{name: `UseCase${computed_routeName_Lang_addBaseline_from_Vuex}`}") {{$t('__MAIN_TITLE.UseCase')}}
                
                //- 產品問與答
                li.nav-item(@click="click_toggle_Button")
                    router-link.nav-link.navLink_Setting(:to="{name: `QA_Page${computed_routeName_Lang_addBaseline_from_Vuex}`}") {{$t('__MAIN_TITLE.QA_Page')}}
                
                //- 品質認證
                li.nav-item(@click="click_toggle_Button")
                    router-link.nav-link.navLink_Setting(:to="{name: `Certification${computed_routeName_Lang_addBaseline_from_Vuex}`}") {{$t('__MAIN_TITLE.Certification')}}

                //- 聯絡我們
                li.nav-item(@click="click_toggle_Button")
                    router-link.nav-link.navLink_Setting(:to="{name: `Contact${computed_routeName_Lang_addBaseline_from_Vuex}`}") {{$t('__MAIN_TITLE.Contact')}}
                
                //- 語言清單-dropdown list
                li.nav-item.dropdown.d-none.d-lg-block
                    a#languageDropdown_List.nav-link.dropdown-toggle.navLink_Setting(href='#' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false') {{currentLanguage_Display}}
                    .dropdown-menu.languageDropdown_List(aria-labelledby='languageDropdown_List')
                        button.dropdown-item.language_item(v-for="langItem of languageList" 
                                                            v-show="langItem.lang !== currentLanguage" 
                                                            @click="changeLanguage(langItem.lang)") {{langItem.language}}
                        //- button.dropdown-item.language_item(@click="changeLanguage('tw')") 繁體中文
                //- 語言清單-摺疊
                li.nav-item.d-block.d-lg-none.nav-item_FoldMenu
                    .nav-item.navbar-toggler.collapsed.navLink_Setting(data-toggle='collapse' data-target='#languageList' aria-controls='languageList' aria-expanded='false' aria-label='Toggle navigation') {{currentLanguage_Display}}
                        b-icon(icon="chevron-up")
                        //- b-icon(icon="chevron-down")
                    #languageList.collapse.navbar-collapse
                        ul.navbar-nav.ml-auto
                            li.nav-item(v-for="langItem of languageList" v-show="langItem.lang !== currentLanguage"
                                        @click="changeLanguageMix(langItem.lang)")
                                button.navLink_Setting {{langItem.language}}
                                    .arrowRight
                            //- li.nav-item(@click="click_toggle_Button")
                            //-     button.navLink_Setting 繁體中文
                            //-         .arrowRight

</template>

<script>
import Vue from 'vue'
import $ from "jquery"
import { mapGetters } from 'vuex'

export default Vue.extend( {

    props: {
        navbar_hide_Control: {
            type: Boolean
        }
    },

    data() {
        return{
            // 當前的語系，從Vuex抓語系值
            currentLanguage: this.$store.state.currentLanguage,
            
            // 這裡可直接新增需要的語言清單，後續可以直接增加
            languageList: [
                {
                    lang: "tw",
                    language: "繁體中文"
                },
                {
                    lang: "en",
                    language: "English"
                },
            ],

            // 控制navbar背景顏色
            navbar_isExpand: false,

            // 控制摺疊按鈕延遲
            button_Click: false,

            // 控制產品選單hover
            productMenu_isActive: false,
        }
    },

    computed: {
        // 顯示現在語系文字
        currentLanguage_Display() {
            /*  原型寫法
                const temp = this.languageList.find(el => {
                    if(el.lang === this.currentLanguage){
                        return el
                    }
                })
                return temp?.language
            */
            // 簡略寫法
            return this.languageList.find(el => {
                return el.lang === this.currentLanguage
            })?.language
        },
        
        // 從Vuex拿getters
        ...mapGetters(['computed_routeName_Lang_from_Vuex', 'computed_routeName_Lang_addBaseline_from_Vuex']),

        productMenu_Data: {
            get() {
                return {
                    coil: {
                        tough_Waterproof: this.$t('__PRODUCTS.tough_Waterproof.title'),
                        roof_Waterproof: this.$t('__PRODUCTS.roof_Waterproof.title'),
                        tunnel_Waterproof: this.$t('__PRODUCTS.tunnel_Waterproof.title'),
                        red_Tape: this.$t('__PRODUCTS.red_Tape.title'),
                        pool: this.$t('__PRODUCTS.pool.title'),
                    },
                    coating: {
                        Cooler_Paint: this.$t('__PRODUCTS.Cooler_Paint.title'),
                        PU_Waterproof: this.$t('__PRODUCTS.PU_Waterproof.title')
                    }
                }
            }
        }
    },


    methods: {
        // 點LOGO模擬click收合的button
        click_toggle_Button() {
            if(this.$store.state.windows_Width < 992  && this.navbar_isExpand === true) {
                $('#toggle_Button').trigger("click")
            }
        },

        // 控制navbar背景顏色
        navbar_toggle_event() {
            if(this.button_Click) { return false}
            
            this.navbar_isExpand = !this.navbar_isExpand
            
            // 控制摺疊按鈕延遲
            this.button_Click = true
            setTimeout(()=>{
                this.button_Click = false
            },300)
        },

        // 當下頁面的path切割，return成字串陣列
        return_currentPageArray() {
            return window.location.pathname.split('/').filter(el => {return el})
        },

        // 切換語系button
        changeLanguage(lang = "tw") {
            this.$store.commit('changeLanguage_mutationsVuex', lang)
            switch (lang) {
                case 'tw':
                    return this.$router.push(this.change_To_TW())
                case 'en':
                    return this.$router.push(this.change_To_OtherLanguage('en'))
            }
        },

        changeLanguageMix(lang = "tw"){
            this.changeLanguage(lang)
            this.click_toggle_Button()
        },

        change_To_TW() {
            const currentPageArray = this.return_currentPageArray()
            let fullPath = ""
            if(currentPageArray.length === 1){
                return '/'
            }
            currentPageArray.shift()
            while(currentPageArray.length > 0){
                const routePath = currentPageArray.shift()
                fullPath = fullPath + '/' + routePath
            }
            return fullPath
        },

        change_To_OtherLanguage(lang) {
            const currentPageArray = this.return_currentPageArray()
            
            // 首頁: TW > other || other > other(路徑的Array第一個值為網址中語系代碼)
            if(currentPageArray.length === 0 || currentPageArray[0].length === 2){
                return `/${lang}`
            }

            // 路徑的Array第一個值代表網址中含有語系代碼，有的話shift掉
            if(currentPageArray[0].length === 2){ currentPageArray.shift() }
            
            let fullPath = `/${lang}`
            while(currentPageArray.length > 0){
                const routePath = currentPageArray.shift()
                fullPath = fullPath + '/' + routePath
            }
            return fullPath
        }
    },

    watch: {
        // 偵聽在Vuex store中的語系切換
        '$store.state.currentLanguage': function() {
            this.currentLanguage = this.$store.state.currentLanguage
            this.$i18n.locale = this.currentLanguage
            // this.change_RouterName_Language()
        }
    },

    created() {
        let isExpand = $('#toggle_Button').attr('aria-expanded')
        isExpand === 'true' ? this.navbar_isExpand = true : this.navbar_isExpand = false
    }
} )
</script>

<style lang="sass">
@import "@/css/my_Style.sass"
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap');

// @function power($number, $power)
//     $exponent: 1
//     @if $power > 0
//         @for $i from 1 through $power
//             $exponent: $exponent * $number
//     @else if $power < 0
//         @for $i from 1 through $power
//             $exponent: $exponent / $number
//     @return $exponent

// #navbar.navbarHide
//     @include _under_768_width
//         top: -85px

.tw #navbar
    *
        // border: 1px solid #000

    &.navbarHide
        @include _under_768_width
            top: -85px

    // 產品選單連結文字設定
    a.menuProduct_Link_Title
        +text_Setting(20px, 1, 1px, #60ade7, 500, "Noto Sans TC", center)
        display: flex
        align-items: center
        height: 100%
    a.menuProduct_Link
        +text_Setting(18px, 3, 0.9px, #fff, 400, "Noto Sans TC", center)
    a.menuProduct_Mall_Link
        @extend .menuProduct_Link_Title
        letter-spacing: 2.3px
    // #navbar.navbarHide
    //     @include _under_768_width
    //         top: -100px
    
    +size(100%, auto)
    +flex_Center_Setting
    position: fixed
    left: 0
    top: 0
    z-index: 1030
    transition: top 0.3s

    .navbar
        width: 100%
        max-width: 1400px
        padding-left: 50px
        padding: 0
        background-color: #F8F8F8
        position: static
        .logoLink
            +size(auto)
            +flex_Center_Setting
            img
                +size(90%)
            .mobileImg
                display: none
        &::before
            +pseudoBlock(100%, 100%, #F8F8F8)
            left: 0
            top: 0
            z-index: -1

    .navbarList
        +size(80%, auto)
        +flex_Center_Setting(space-between)
        @include _under_1440_width()
            width: 95%
        // @include _under_1200_width
        //     width: 100%
        
        li.nav-item
            font-size: font(6)
            text-align: center
            flex-grow: 1
            height: 85px

            .toggle
                display: none

            & > .navLink_Setting
                +flex_Center_Setting
                +size(100%)
                font-size: font(4)
                color: black
                line-height: normal
                cursor: pointer
                border: 0px solid transparent

            &:hover
                background-color: #0b151d
                & > .navLink_Setting
                    color: white

        .languageDropdown_List
            width: 100%
            padding: 0
            min-width: auto
            & > .language_item
                font-size: font(6)
                text-align: center
                padding: 5px 0
                &:hover
                    background-color: $theme_color
                    color: white

        // 產品選單區塊
        .nav-productMenu
            *
                // border: 1px solid #fff
            &:hover > .productMenu_Container
                opacity: 1
            .productMenu_Container
                +size(100%, auto)
                max-width: 1200px
                background-color: rgba(#0b151d, 0.9)
                position: absolute
                left: 50%
                top: 85px
                transform: translateX(-50%)
                animation: fadeIn 0.5s
                // display: none
                .row:first-child
                    +size(100%, auto)
                    flex-direction: column
                    padding: 45px 40px 22.5px
                    .productMenu
                        display: flex
                        justify-content: space-between
                        .menuTitle
                            .menuProduct_Link_Title
                                position: relative
                                &::before
                                    +pseudoBlock(0, 3px, #60ade7)
                                    left: 0
                                    bottom: 0
                                    transition: width 0.3s
                                &:hover::before
                                    width: 100%
                        .productMenuList
                            width: 90%
                            display: flex
                            flex-flow: wrap
                            .menuProduct_Link
                                +flex_Center_Setting(space-between)
                                border-bottom: 1px solid #fff
                                .b-icon
                                    +size(25px)
                                    transform: translateX(-10px)
                                    margin-left: calc(10px + 5px)   // 預設transformX 10px，要與文字間隔5px
                                    transition: transform 0.3s
                                &:last-child
                                    margin-right: 0
                                &:hover
                                    .b-icon
                                        transform: translateX(0)
                    .coil_Menu > .productMenuList
                        justify-content: space-between
                        .menuProduct_Link
                            width: 17%
                    .coating_Menu
                        margin-top: 45px
                        .productMenuList > .menuProduct_Link
                            width: 17%
                            margin-right: 3.75%
                .row:last-child
                    +flex_Center_Setting(space-between)
                    padding: 0 75px 0 40px
                    overflow: hidden
                    a.menuProduct_Mall_Link
                        +size(30%, auto)
                        +flex_Center_Setting(space-between)
                        align-self: flex-end
                        padding: 22.5px 10px 22.5px 0
                        transition: padding-right 0.3s
                        .b-icon
                            +size(30px)
                        &:hover
                            padding-right: 0
                    // img
                    //     +size(50%, auto)
                    //     position: relative
                    //     bottom: -7px
            // &:hover .productMenu_Container
            //     display: block

    @include _under_992_width
        .navbar
            // 導覽列高度設定100px，LOGO圖片高度預設58px
            padding: (85-52)/2+px 2rem
            .logoLink
                .logoImg
                    display: none
                .mobileImg
                    display: block

            // 摺疊選單展開時按鈕線條定位為XX叉叉
            button.navbar-toggler
                +size(50px)
                background-color: $theme_color
                padding: 0
                position: relative
                border: solid 1px #bbb
                outline: none
                .line
                    transition: 0.3s    // 切換class動畫0.3s
                    // -ms-width: 42.72px
                    +size(calc(30px * 1.41421), 3px)
                    background-color: #fff
                    position: absolute
                .line.line1
                    left: 50%
                    top: 50%
                    transform-origin: left
                    transform: rotate(45deg) translate(-50%, -50%)
                .line.line2
                    opacity: 0
                .line.line3
                    left: 50%
                    bottom: 50%
                    transform-origin: left
                    transform: rotate(-45deg) translate(-50%, -(3/2)+3+px)  // line線條高度3px，相等於：translate( -50%, calc(-50% + 3px) ) -->IE的calc不支援

            // 摺疊選單收合後按鈕線條定位為3條直線
            button.navbar-toggler.collapsed
                +flex_Center_Setting(space-around)
                flex-direction: column
                position: static
                padding: 5px 10px
                .line
                    width: 100%
                    opacity: 1
                    position: static
                    transform: rotate(0deg) translate(0)

            .navbarList
                margin-left: 0
                display: block
                margin-top: 25px
                max-height: calc(100vh - 100px - 25px)  // 導覽列高100px，navbarList的margin-top: 25px
                overflow-y: scroll
                
                //--- 隱藏scrollbar ---
                &::-webkit-scrollbar    // Chrome、Edge、Safari
                    display: none
                scrollbar-width: none   // firefox
                -ms-overflow-style: none    // IE
                //--- 隱藏scrollbar ---
                
                li.nav-item
                    height: auto
                    text-align: left
                    position: relative
                    border-bottom: solid 1px #bbb
                    &:last-child
                        border-bottom: solid 0px
                    &:hover
                        background-color: transparent
                        & > .navLink_Setting
                            color: black
                    .navLink_Setting
                        justify-content: space-between
                        padding: 15px 40px

                    .toggle
                        +size(100%)
                        position: absolute
                        background-color: transparent
                        display: block

                .nav-item_FoldMenu
                    #products_List, #languageList
                        margin-bottom: 10px
                        li.nav-item
                            border: solid 0px transparent
                            // &:first-child
                            //     margin-top: 0px
                            // &:last-child
                            //     margin-bottom: 30px
                            .navLink_Setting
                                padding: 10px 60px
                                
                    .b-icon
                        transition: 0.3s
                    // 折疊的icon旋轉
                    .collapsed > .b-icon
                        transform: rotate(-180deg)
        
        // 摺疊選單展開後CSS
        .navbar.navbar_isExpand
            background-color: $theme_color
            .navbarList
                li.nav-item > .navLink_Setting
                    color: #fff
                .nav-item_FoldMenu
                    #products_List, #languageList
                        li.nav-item > .navLink_Setting
                            +flex_Center_Setting(space-between)
                            color: $title1_color
                            +arrowRight(35px, 8px)

    @include _under_768_width
        .navbar
            padding-top: 15px
            padding-bottom: 15px
            .logoLink
                max-width: 170px
            button#toggle_Button
                +size(40px)
                .line
                    +size(calc(20px * 1.41421), 3px)
                &.collapsed > .line
                    width: 100%

    @include _under_576_width()
        .navbar
            padding-left: 0.5rem
            padding-right: 1rem
            .navbarList 
                li.nav-item > .navLink_Setting
                    font-size: 16px
                    padding: 15px 30px
                .nav-item_FoldMenu
                    #products_List, #languageList
                        li.nav-item > .navLink_Setting
                            padding: 10px 40px

// ==== 英文版CSS ====
.en
    // 繼承tw css
    @extend .tw
    #navbar
        *
            // border: 1px solid #fff
        .navbarList
            .nav-productMenu
                .productMenu_Container
                    .row .productMenu
                        flex-wrap: wrap
                        .menuTitle .menuProduct_Link_Title
                            padding-bottom: 10px
                    .row:first-child
                        padding: 25px
                        .coil_Menu, .coating_Menu
                            .productMenuList
                                width: 100%
                                margin-top: 15px
                                & > .menuProduct_Link
                                    width: auto
                                    max-width: 22%
                        .coating_Menu
                            margin-top: 30px
                    .row:last-child
                        padding: 0 25px
                        a.menuProduct_Mall_Link
                            width: 100%
                            max-width: 320px
                a.menuProduct_Link
                    line-height: 1.5
                    text-align: left
                    padding-bottom: 10px
                    font-size: 14px
</style>