<template lang="pug">
main#QA_Page
    pageTop(:pageTitle="$t('__MAIN_TITLE.QA_Page')" imgSrc="QA_Page/FAQ_page top img_desktop.jpg" imgSrc_Mobile="QA_Page/FAQ_page top img_mobile.jpg")
    .QA_Container
        //- 捲材類 + 塗料類兩區塊版型
        .block(v-for="(data, block_index) of QA_Data")
            .sectionTitle
                img.icon(:src="require(`@/assets/banner/icon0${block_index + 1}.png`)")
                h1.containerTitle {{data.title}}
            .QA_List_Block
                .QA_List(v-for="(QA, QA_index) of data.questionList")
                    input(type="checkbox" :id="`expandActive_checkbox_${block_index}_${QA_index}`" :value="QA.questionTitle" v-model="checkShow[block_index][QA_index]")
                    .QA_Collapse(:class="{isExpand: checkShow[block_index][QA_index]}")
                        label.question(:for="`expandActive_checkbox_${block_index}_${QA_index}`")
                            h2.qusTitle.Q_mark Q: 
                            h2.qusTitle {{QA.questionTitle}}
                            .icon
                                b-icon(icon="chevron-down")
                        ol(:style="checkShow[block_index][QA_index] ? dynamic_calculate_the_height_of_the_folded_answerBlock(QA.answer) : false")
                            li(v-for="ans of QA.answer").ans {{ans}}
    
        Button_Style.button_more(:btnTitle="$t('__MAIN_TITLE.Contact')" linkType="routerName" link="Contact" arrow="right")
</template>

<script>
import pageTop from "@/components/pageTop.vue"
import Button_Style from "@/components/Button_Style.vue"

export default {
    metaInfo() {
        return{
            title: `${this.$t('__MAIN_TITLE.QA_Page')}-`,
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: this.$t('__SUB_TITLE.FAQ.description')
                },
                // Open Graph / Facebook
                { property: 'og:url', content: `${this.$t('__MAIN.domainName_URL')}/QA_Page` },
                { property: 'og:description', content: this.$t('__SUB_TITLE.FAQ.description') },

                // Twitter
                { property: 'twitter:url', content: `${this.$t('__MAIN.domainName_URL')}/QA_Page` },
                { property: 'twitter:description', content: this.$t('__SUB_TITLE.FAQ.description') },
            ]
        }
    },

    components: {
        pageTop,
        Button_Style
    },

    data() {
        return{
            checkShow: [
                // 跑 this.caculate_QA_length() 後會長下面這樣
                // [false, false, false, false, false, false, false, false, false, false, false, false],
                // [false, false, false, false, false, false, false]
            ],
        }
    },

    computed: {
        QA_Data: {
            get() {
                return [
                    {
                        title: this.$t('__TYPE.coil'),
                        questionList: this.$t('__SUB_TITLE.FAQ.questionList').slice(0, 12)
                    },
                    {
                        title: this.$t('__TYPE.coating'),
                        questionList: this.$t('__SUB_TITLE.FAQ.questionList').slice(12, 19)
                    },
                ]
            }
        }
    },

    methods: {
        getImgUrl(pic) {
            return require('@/assets/' + pic)
        },

        // 根據answer資料筆數，計算需要多少的折疊高度(因為CSS的transition不支援0~auto的區間動畫)
        // 所以只能給定一個高度來執行動畫
        dynamic_calculate_the_height_of_the_folded_answerBlock(ans) {
            return {'max-height': ans.length * 100 * 2 + 'px'}
        },

        // 計算兩類產品有幾筆QA資料，產生boolean Array控制checkbox展開收合
        caculate_QA_length() {
            this.checkShow = []
            const coil_length = this.QA_Data[0].questionList.length
            const coating_length = this.QA_Data[1].questionList.length
            const arr1 = []
            const arr2 = []

            for(let i = 0; i < coil_length; i++){ arr1.push(false)  }
            for(let i = 0; i < coating_length; i++){ arr2.push(false) }
            return this.checkShow.push(arr1, arr2)
        }
    },

    // watch: {
    //     QA_Data: function() {
    //         console.log('change')
    //     }
    // },

    created() {
        this.caculate_QA_length()
    }
}
</script>

<style lang="sass">
@import "@/css/my_Style.sass"
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap');

.tw #QA_Page
    *
        // border: 1px solid #000
    h1.containerTitle
        +text_Setting(calc(20px + 0.9vw), 1, 1.9px, $title1_color, 500, "Noto Sans TC", left)
        @include _Upper_1920_width()
            font-size: 38px
    h2.qusTitle
        +text_Setting(calc(18px + 0.35vw), 2, 1.1px, #292929, 500, "Noto Sans TC", left)
        @include _Upper_1920_width()
            font-size: 22px
        @include _under_576_width()
            font-size: 18px
    li.ans
        +text_Setting(calc(16px + 0.35vw), 1.82, 1.76px, #0876b2, 300, "Noto Sans TC", left)
        @include _Upper_1920_width()
            font-size: 22px
        @include _under_576_width()
            font-size: 16px
    
    .QA_Container
        // *
        //     border: 1px solid #000
        +size(100%, auto)
        padding-top: 100px
        .block
            .sectionTitle
                +size(80%, auto)
                +flex_Center_Setting(flex-start)
                max-width: $max_Container_Width
                margin: 0 auto
                padding-bottom: 30px
                .icon
                    +size(60px)
                    margin-right: 30px
            .QA_List_Block
                background-color: $container1_color
                padding: 60px 0 90px
                position: relative
                // background-image: linear-gradient(92deg, #5ab8eb 2%, #6a71dc 50%, #865fcd 98%)
                .QA_List
                    +size(80%, auto)
                    max-width: $max_Container_Width
                    margin: 0 auto
                    &:nth-child(1) ~ .QA_List
                        margin-top: 35px
                    input
                        display: none
                    .QA_Collapse
                        .question
                            +flex-Center_Setting(space-between)
                            width: 100%
                            cursor: pointer
                            border-bottom: 1px solid #707070
                            padding-bottom: 15px
                            h2.qusTitle
                                // Q_mark margin-right: 5px, qusTitle margin-right: 20px, icon width: 45px, icon border: 3px
                                width: calc(100% - 5px - 20px - 45px - 3px*2)
                                // flex: 1
                                margin-right: 20px
                            h2.Q_mark
                                width: auto
                                align-self: flex-start
                                margin-right: 5px
                            .icon
                                +size(45px)
                                +flex_Center_Setting
                                border: 3px solid $title1_color
                                border-radius: 50%
                                .b-icon
                                    +size(60%)
                                    color: $title1_color
                                    transition: 0.3s ease-in-out
                                @include _under_576_width()
                                    +size(35px)
                        ol
                            // height: 0
                            max-height: 0
                            transition: 0.3s ease-in-out
                            overflow: hidden
                                
                    .isExpand
                        .question > .icon
                            background-color: $title1_color
                            .b-icon
                                color: #fff
                                transform: rotate(180deg)
                        ol
                            // max-height: 300px
                            margin: 26px 0
            .QA_List_Block::before
                +pseudoBlock(100%, 12px)
                background-image: linear-gradient(92deg, #5ab8eb 2%, #6a71dc 50%, #865fcd 98%)
                bottom: 0
                opacity: 0.7
        @include _under_768_width()
            padding-top: 50px
            .block .sectionTitle
                .icon
                    +size(40px)
                    margin-right: 20px
        // @include _under_576_width()
            
        .button_more > a
            background-image: linear-gradient(97deg, #865fcd -23%, #5ab8eb 147%)
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16)
            margin: 100px auto

    // 塗料類-顏色更換
    .block:nth-child(2)
        margin-top: 80px
        h1.containerTitle
            color: $title2_color
        .QA_List_Block
            background-color: $container2_color
            .QA_List
                .QA_Collapse > .question > .icon
                    border-color: $title2_color
                    .b-icon
                        color: $title2_color
                ol > li.ans
                    color: $title2_color
                .isExpand > .question > .icon
                    background-color: $title2_color
                    .b-icon
                        color: #fff
        .QA_List_Block::before
            background-image: linear-gradient(192deg, #5ab8eb 2%, #6a71dc 50%, #865fcd 98%)

// ==== 英文版CSS ====
.en 
    // 繼承tw css
    @extend .tw
.en #QA_Page
    // *
    //     border: 1px solid #000
    h2.qusTitle, li.ans
        letter-spacing: normal
    h2.qusTitle
        font-size: 20px
        @include _under_768_width()
            font-size: 18px
        @include _under_576_width()
            font-size: 16px
    li.ans
        font-size: 18px
        @include _under_768_width()
            font-size: 16px
        @include _under_576_width()
            font-size: 14px
    
    .QA_Container
        .block .QA_List_Block
            .QA_List
                &:nth-child(1) ~ .QA_List
                    margin-top: 20px
</style>        