<template lang="pug">

#Button_Style(v-if="linkType === 'routerName'")
    router-link.button_url(:to="{name: `${link}${computed_routeName_Lang_addBaseline_from_Vuex}`, params: link_Params}" :class="arrowActive_computedClass") {{btnTitle}}
        .arrowRight(v-if="arrow === 'right' ")
        .arrowLeft(v-else-if="arrow === 'left' ")

#Button_Style(v-else-if="linkType === 'url'")
    a.button_url(:href="link" target="_blank" :class="arrowActive_computedClass") {{btnTitle}}
        .arrowRight(v-if="arrow === 'right' ")
        .arrowLeft(v-else-if="arrow === 'left' ")
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    props: {
        btnTitle: String,
        linkType: String,
        link: String,
        link_Params: {
            type: Object,
        },
        arrow: {
            type: String,
            default: null
        },
    },

    computed: {
        // 從Vuex拿getters
        ...mapGetters(['computed_routeName_Lang_from_Vuex', 'computed_routeName_Lang_addBaseline_from_Vuex']),

        arrowActive_computedClass() {
        
        // 三元判斷式寫法：時間複雜度4
        return this.arrow === 'right' ? 'arrowActiveRight' : this.arrow === 'left' ? 'arrowActiveLeft' : false

        // switch寫法：時間複雜度8
            // switch(this.arrow){
            //     case 'right': 
            //         return 'arrowActiveRight'
            //     case 'left': 
            //         return 'arrowActiveLeft'
            //     default: 
            //         return false
            // }

        // if-else寫法：時間複雜度8
            // if(this.arrow === 'right'){
            //     return 'arrowActiveRight'
            // }
            // else if(this.arrow === 'left'){
            //     return 'arrowActiveLeft'
            // }
            // else{
            //     return false
            // }
        }
    }
}
</script>

<style lang="sass">
@import "@/css/my_Style.sass"
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.tw #Button_Style
    *
        // border: 1px solid #000
    .button_url
        +size(240px, 69px)
        // +flex_Center_Setting(space-between)
        +flex_Center_Setting()
        +text_Setting(font(3), normal, 2.8px, white, 400, 'Noto Sans TC', center)
        position: relative
        border-radius: 35px
        transition: 0.2s linear 0s
        border: solid 1px #fff
        transition: 0.2s
        +arrowRight(40px, 10px, #fff)
        +arrowLeft(40px, 10px, #fff)

        @include _under_576_width()
            +size(200px, 60px)
            font-size: 16px
            .arrowRight, .arrowLeft
                +size(35px, 8px)
        @include _under_375_width()
            +size(185px, 55px)
            font-size: 14px

        // &:hover
    
    .button_url.arrowActiveRight
        justify-content: space-between
        padding-left: 40px
        padding-right: 25px
        &:hover
            padding-right: 15px

    .button_url.arrowActiveLeft
        justify-content: space-between
        flex-direction: row-reverse
        padding-left: 25px
        padding-right: 40px
        &:hover
            padding-left: 15px

// ==== 英文版CSS ====
.en 
    // 繼承tw css
    @extend .tw
.en #Button_Style
    
</style>