// tw.js
export const locale = {
    __MAIN: {
        domainName_URL: 'https://www.nanya-waterproof.com',
        html_lang: 'zh-Hant-TW'
    },
    __MAIN_TITLE: {
        WebsiteName: '南亞防水材料',
        WebsiteTitle: '南亞防水材料・PVC防水膜・PU防水材 | 頂級防護漏水OUT',
        Home: '首頁',
        Company: '公司簡介',
        Products: '產品介紹',
        Products_T2: '產品資訊',
        UseCase: '使用案例',
        QA_Page: '產品問與答',
        Certification: '品質認證',
        Contact: '聯絡我們',
        News: '最新消息',
        Company_Name: '南亞塑膠工業股份有限公司',
        Division: '塑膠第一事業部',
        CopyrightRef: '(台塑企業 南亞公司提供，2023/03/01)'
    },
    __MAIN_TITLE_TEXT: {
        ContactText: '如果您對南亞公司有任何疑問，非常歡迎您來信或致電客服專線。我們將會以最快的速度回應給您，謝謝。'
    },
    __NEWS_LIST: [
        '樹林一廠及仁武二廠於2020年12月均全數取得TAF合格實驗室證書。',
        '樹林一廠於2020年6月榮獲綠建材建材標章。'
    ],
    __TYPE: {
        coil: '捲材類',
        coating: '塗料類'
    },
    __BUTTON_TEXT: {
        more: '了解更多',
        more_NanYaProducts: '了解更多南亞產品',
        more_Products: '了解產品',
        PlasticMallName: '南亞塑膠網絡商城',
        PlasticMall_Link: 'https://www.nanya-plastics-mall.com.tw/',
        more_NanYaCyberShoppingMall: "南亞雲端網絡商城",
        Contact: '前往聯繫',
        sendData: '送出資料',
        backHome: '返回首頁',
        getProducts: '索取樣品',
        case: '實際案例'
    },
    __BANNER: {
        __BANNER_0:{
            __TITLE1: '南亞優質防水材料  頂級防護漏水OUT！',
            __TITLE2: '"從隧道到山坡地、地下室到屋頂、蓄水池到游泳池，提供您全方位的守護。"',
            img_alt: '南亞優質PVC及PU防水材料'
        },
        __BANNER_1:{
            __TITLE1: '最高規格的管制\n成就最單純的防水材料',
            __TITLE2: '南亞強韌防水膜具備良好物性且經久耐用，適用於各種土木環境工程。',
            img_alt: '南亞強韌防水膜應用及產品圖'
        },
        __BANNER_2:{
            __TITLE1: '國內領導生產廠商 銷售國際\n專業設計團隊 客製化開發',
            __TITLE2: '南亞泳池裡襯可符合標準泳池耐氯測試ASTM-D-534及EN15836\n防霉、抗菌耐化學品測試通過，耐候佳可耐寒-37℃',
            img_alt: '南亞泳池裡襯應用及產品圖'
        },
        __BANNER_3:{
            __TITLE1: '隧道內的行車安全\n交給最高品質的材料',
            __TITLE2: '南亞隧道防水膜使用高強度配方，物性優越且加工性良好，適用於各種隧道工程。',
            img_alt: '南亞隧道防水膜應用及產品圖'
        },
        __BANNER_4:{
            __TITLE1: '不管風吹日曬還是雨淋\n給您滴水不漏是我們的堅持',
            __TITLE2: '南亞屋頂防水膜通過國家品質要求，搭配施工可耐候10年以上，適用於各類頂樓及彩鋼屋頂。',
            img_alt: '南亞屋頂防水膜應用及產品圖'
        },
        __BANNER_5:{
            __TITLE1: '配合永續特殊環保配方\n烈日曝曬仍可維持強韌物性',
            __TITLE2: '南亞紅泥膠布具備極佳之抗老化耐候性，可耐化學品及抗酸鹼。',
            img_alt: '南亞紅泥膠布應用及產品圖'
        },
        __BANNER_6:{
            __TITLE1: '業界老字號產品 擁有無數實績\n符合綠建材標章 品質有保障',
            __TITLE2: '南亞PU防水材通過CNS 等國家標準認證，採用環保原料，不含TDI、MOCA及溶劑。',
            img_alt: '南亞PU防水材應用及產品圖'
        },
        __BANNER_7:{
            __TITLE1: '日光反射率高達90%以上\n有效降低塗層內部之溫度 節能省電',
            __TITLE2: '南亞隔熱漆通過綠建材認證及CNS 4940規範，低VOC無有害重金屬，安心選擇。',
            img_alt: '南亞隔熱漆應用及產品圖'
        },
    },
    __SUB_TITLE: {
        Home: {
            description: '從隧道到山坡地、地下室到屋頂、蓄水池到游泳池，不管是居家DIY防水或大型防水工程，南亞防水材料提供頂級PVC防水膜及PU防水材，給您全方位的防漏守護。',
            introduce: {
                Block_Title: '選擇南亞防水材料',
                Block_Text: '南亞塑膠具備一套系統性的防水解決方案，從防水材料、防水工程設計到施工技術等，整合下游各專業領域技術及經驗，只為給您最有保障的防水體驗，改善人們的生活環境及品質。',
                Title_1: '防水材料製造經驗',
                Text_1: '包含各類防水膜(捲材)及防水塗料等，製程及配方以數據化管理生產，品質穩定符合各類防水規範。',
                Title_2: '遍佈各大公共工程建案',
                Text_2: '如雪山隧道、蘇花改隧道及國道四號隧道等，兼顧外觀及耐用的高品質產品，可面對各種應用需求。',
                Title_3: '屋頂防水鋪設面積',
                Text_3: '不僅具備優良的品質之外，更可依照客戶的需求客製化產品規格，並擁有專業之研發及設計團隊。',
                Word_1: '年以上',
                Word_2: '約',
                Word_3: '公里',
                Word_4: '千米平方',
            },
            productsBlock_Text: '南亞塑膠生產從住家、商業空間到大型公共工程等塗料類及捲材類的防水材料，產品皆通過嚴格認證把關，並且擁有最佳研發團隊可依客戶需求客製化商品。',
            greenIntroduce: {
                Block_Title: '南亞防水材料 致力研發環境友善 綠色能源產品',
                Block_Text: '南亞公司每年對節能減碳與溫室氣體排放量管理,水資源與能源回收不斷努力,且積極採取對環境友善的各項前瞻做法，從綠色工廠到綠色採購供應鏈產品設計,去毒化及去物質化的創新思維，降低對環境的衝擊危害。'
            },
        },
        Company: {
            description: '南亞塑膠公司仁武二廠主要生產各類軟質以及半硬質膠布，包含PVC防水材及各類雜加工等用途的塑膠製品；樹林二廠則主要生產銅箔、玻纖布及環氧樹脂等產品，含PU防水材及隔熱漆等防水製品，品質優良穩定，客戶遍及國內外。',
            page_Introduce: '南亞塑膠公司創立於1958年，產業跨足塑膠加工、塑膠原料、電子材料及聚酯纖維領域，透過不斷地研發創新，強化國際化及多角化的佈局，達到世界級的規模，提供優良的產品服務社會。 \
                奉行二位創辦人王永慶先生與王永在先生「勤勞樸實、止於至善、永續經營、奉獻社會」精神，致力提升經營效益與品質，也持續投入教育、醫療及關懷弱勢的公益事業，以善盡企業公民的責任。',
            Title_intro: '簡介',
            Title_1_renwu: '仁武廠',
            Title_2_shulin: '樹林廠',
            Title_3: '工廠沿革',
            Intro_Text_1: '南亞仁武二廠建立於西元1979年，主要生產各類軟質以及半硬質膠布及印壓製品，產品可應用於裝潢建材、招牌廣告、泳池防水材料及各類雜加工等用途。',
            Intro_Text_2: '南亞公司樹林二廠建於中華民國74年，中華民國75年8月正式投入生產銅箔基板。雖歷經30年,但其中不斷創新改革及改善為全自動化生產廠， \
                主要產品為銅箔基板及客戶基材玻纖膠片，主要原料為銅箔、玻纖布及環氧樹脂，全部由企業內供應，具有完整垂直整合的優勢，品質優良穩定，客戶遍及國內外，產品廣泛應用於手機、電腦、汽車、通訊器材及各類家電。',
            timeLineCard_Data: {
                renwu: {
                    card_1: '正式通過TAF國家實驗室認證。',
                    card_2: '進行製程優化及設備整改，全面增設品質檢測設備及產能大幅提升。',
                    card_3: '配合政府推動都市發展，原前鎮廠遷廠至仁武，設立印壓課，生產各類印刷壓花製品。',
                    card_4: '合併色餅專案組成立色餅課，生產各類色母、色料及印墨等。',
                    card_5: '增設設膠布生產設備十套，並開始大量投產。',
                    card_6: '於高雄仁武建廠，以各類PVC膠布生產為主。',
                },
                shulin: {
                    card_1: '正式通過TAF國家實驗室認證。',
                    card_2: 'RTPMS系統建置、去蕪存菁精緻化經營。',
                    card_3: '榮獲經濟部節約能源優等獎。',
                    card_4: '正式通過OHSAS-18001認證。',
                    card_5: '1958年於樹林建廠。',
                }
            }
        },
        UseCase: {
            description: '南亞PVC捲材類防水膜實績從國內的工廠、民宅屋頂防水到雪山、蘇花改等隧道防水工程，遍布至美國、加拿大等海外的泳池防水工程等；PU塗料類防水材則有多項屋頂防水及工廠桶槽隔熱防水塗料的工程實績；優良的產品防水實績，值得您的信賴推薦。',
            card_1: {
                title: '屋頂防水膜',
                locationTitle: '長庚大學體育館屋頂',
                locationArea: '桃園市'
            },
            card_2: {
                title: '屋頂防水膜',
                locationTitle: '工廠、民宅屋頂防水',
                locationArea: '台灣'
            },
            card_3: {
                title: '強韌防水膜',
                locationTitle: '六輕地板鋪設',
                locationArea: '雲林縣'
            },
            card_4: {
                title: '隧道防水膜',
                locationTitle: '雪山隧道',
                locationArea: '新北市'
            },
            card_5: {
                title: '隧道防水膜',
                locationTitle: '蘇花改隧道',
                locationArea: '花蓮縣'
            },
            card_6: {
                title: '隧道防水膜',
                locationTitle: '國道四號',
                locationArea: '台中市'
            },
            card_7: {
                title: '泳池裡襯',
                locationTitle: '居家住宅',
                locationArea: '巴西'
            },
            card_8: {
                title: '泳池裡襯',
                locationTitle: '飯店泳池',
                locationArea: '加拿大'
            },
            card_9: {
                title: '泳池裡襯',
                locationTitle: '飯店泳池',
                locationArea: '美國'
            },
            card_10: {
                title: '泳池裡襯',
                locationTitle: '飯店泳池',
                locationArea: '澳洲'
            },
            card_11: {
                title: '紅泥膠布',
                locationTitle: '養豬場',
                locationArea: '嘉義縣'
            },
            card_12: {
                title: '紅泥膠布',
                locationTitle: '養豬場',
                locationArea: '台南市'
            },
            card_13: {
                title: '南亞酷樂漆(淡黃色)',
                locationTitle: '長庚大學體育館屋頂',
                locationArea: '桃園市'
            },
            card_14: {
                title: '南亞酷樂漆(白色)',
                locationTitle: '永豐餘厭氧塔',
                locationArea: '新北市'
            },
            // 2023/7/7 新增
            card_15: {
                title: '南亞酷樂漆(多彩)',
                locationTitle: '居家屋頂',
                locationArea: '苗栗縣'
            },
            card_16: {
                title: '南亞酷樂漆(多彩)',
                locationTitle: '貨櫃屋',
                locationArea: '宜蘭縣'
            },
            card_17: {
                title: '南亞酷樂漆(多彩)',
                locationTitle: '儲槽、彩鋼',
                locationArea: '台灣'
            }
        },
        FAQ: {
            description: '我們針對客戶常詢問南亞PVC防水膜或PU防水材的產品細節、認證及防水施工等問題，彙整了詳細的防水產品問答集，如想了解更多南亞防水材產品資訊，歡迎致電或來信洽詢我司專人。',
            questionList: [
                // ===================捲材類=================== //
                {// 0
                    questionTitle: '如何選擇防水材的款式?',
                    answer: [
                        "(1)屋頂防水膜比強韌防水膜中間多一層人造纖維，較不易收縮，拉伸與撕裂強度較好。",
                        "(2)隧道防水膜為黑白雙色貼合，白色面能讓漏水處一目了然。",
                        "(3)強韌防水膜較HDPE防水膜容易施工，耐候性與耐穿刺皆較好。"
                    ]
                },
                {// 1
                    questionTitle: '南亞公司有提供施工嗎?',
                    answer: [
                        "南亞塑膠為生產廠，無提供施工服務；但可介紹專業廠商諮詢。"
                    ]
                },
                {// 2
                    questionTitle: '是否有現貨能購買?',
                    answer: [
                        "本部門為接單生產，交期約30~45天。但有些常用規格為計劃性生產，詳細請聯繫業務人員。"
                    ]
                },
                {// 3
                    questionTitle: '產品是否有通過認證?',
                    answer: [
                        "(1)強韌防水膜  ",
                        "(2)屋頂防水膜 ",
                        "(3)若有其他檢驗需求，可評估送驗。",
                    ]
                },
                {// 4
                    questionTitle: "是否可提供樣品?",
                    answer: [
                        "可以，請連絡業務專人索樣。",
                    ]
                },
                {// 5
                    questionTitle: "自家屋頂有辦法使用南亞屋頂防水膜DIY嗎?",
                    answer: [
                        "南亞屋頂防水膜雖保證10年之防水功能，但是需要經過專業施作(場地評估、補強及封邊等等)，所以還是讓專業的來吧！省時省力又完美！"
                    ]
                },
                {// 6
                    questionTitle: "泳池裡襯跟一般磁磚游泳池差別在哪裡?",
                    answer: [
                        "泳池裡襯使用高品質聚氯乙烯材質，其富有柔軟度及彈性並可添加不同配方達到不同效果，相較於傳統磁磚除了施工及翻修更簡單，更重要的是不會有割傷或碰傷的情形發生！",
                    ]
                },
                {// 7
                    questionTitle: "使用塑膠材質會不會不環保?我每天在游泳吃下去怎麼辦?",
                    answer: [
                        "其實您身邊有87%↑的東西都是用塑膠製成的，重點是哪一種塑膠跟什麼配方，南亞所使用的材質都是低毒環保配方，且通過多項國際環保認證，不僅沒有毒反而還會保護您的安全！",
                    ]
                },
                {// 8
                    questionTitle: "泳池裡襯有多少種圖案可以選擇呢?",
                    answer: [
                        "南亞塑膠有專業的設計廠商，我們也大量供應海外如北美、南美及澳洲等客戶，有任何需要都可洽專人詢問。",
                    ]
                },
                {// 9
                    questionTitle: "國內有人在使用泳池裡襯蓋游泳池嗎?",
                    answer: [
                        "相較於歐美等各戶家中都有游泳池的盛況，國內目前家用游泳池尚未如此普及，但有許多建商都有提供此一服務，如想嘗試皆可以聯絡我們專人為您接洽！",
                    ]
                },
                {// 10
                    questionTitle: "泳池裡襯可以使用多久呢?",
                    answer: [
                        "我們都有添加特殊耐化學(氯)配方，而且都是經過檢驗對人體無害的，在海外的時機也平均可以使用3~5年以上，其相對便宜的售價非常適合飯店或渡假村使用，可因應不同潮流做不同設計之游泳池！",
                    ]
                },
                {// 11
                    questionTitle: "我是養豬戶我該如何使用紅泥膠布呢?",
                    answer: [
                        "很抱歉。南亞僅提供紅泥膠布(捲材)，如果要製作程沼氣(儲氣)袋需要專業的加工廠商協助，但這一點您不用擔心南亞會幫您接洽好配合的加工廠商，並且提供完整的教學及售後服務！",
                    ]
                },
                // ===================捲材類=================== //
                {// 12
                    questionTitle: "PU防水材需要再添加溶劑稀釋嗎?",
                    answer: [
                        "施工前會再添加。",
                    ]
                },
                {// 13
                    questionTitle: "PU防水材攪拌後可加工時間約幾小時??",
                    answer: [
                        "約1小時。",
                    ]
                },
                {// 14
                    questionTitle: "PU防水材塗佈後表乾及完全硬化時間約多久?",
                    answer: [
                        "塗佈後表乾時間為半小時。完全硬化時間則約2天。",
                    ]
                },
                {// 15
                    questionTitle: "酷樂漆需再使用水來稀釋嗎?",
                    answer: [
                        "不需要。",
                    ]
                },
                {// 16
                    questionTitle: "使用酷樂漆時，要如何選擇正確的底漆呢?",
                    answer: [
                        "依產品說明書中建議，不同底材選則使用不同底漆，或洽拓展人員。",
                    ]
                },
                {// 17
                    questionTitle: "酷樂漆塗厚與降溫效果之關係?",
                    answer: [
                        "塗厚約110μm，超厚對降溫效果並無明顯幫助，過薄則降溫效果降低。",
                    ]
                },
                {// 18
                    questionTitle: "產品是否含有不環保溶劑?",
                    answer: [
                        "南亞原料皆符合綠建材標章採用環保原料，絕不含TDI、MOCA及溶劑。",
                    ]
                },
            ]
        },
        Certification: {
            description: '品質description',
            renwu_Text: '南亞仁武廠',
            shulin_Text: '南亞樹林廠',
            altText_1: '南亞通過各項驗證',
            BSMI_Title: '南亞防水材系列產品 \n 品質暨服務保證',
            page_Introduce: '本公司通過 ISO 14001 環保管理系統、OHSAS 18001職業安全衛生管理系統及臺灣職業安全衛生管理系統 (TOSHMS) 之驗證。\
                            平時除依規定進行環保管理作業外，並適時檢視最新法令要求，確保相關作業符合規定。\
                            每年接受原驗證單位 ( 標準檢驗局、SGS) 定期至廠複查，積極改善，以達到 ISO「符合法規、持續改善」的精神。',
            greenMaterialTitle: '南亞樹林廠 \n 榮獲綠建材建材標章',
            greenMaterialText: '綠建材應於原料取得、生產製造、成品運輸及使用等階段皆不造成環境污染；依「綠建材標章申請審核認可及使用作業要點」\
                            第四點之規定出具一年內未受環保機關處分之證明文件。綠建材之產品功能應符合既定之國家標準；若國內尚無可符合之國家標準時，得另提出適合之國際標準進行評定；\
                            若所沿用之標準或規範中的性能試驗項目中有部份不適用的情形，得提出其它相關之標準或規範進行評定。',
            greenMaterial_altText: '綠建材標章',
            certTitle_1: 'ISO9001證書',
            certTitle_2: 'ISO14001證書',
            certTitle_3: 'OHSAS18001證書',
            certTitle_4: 'ISO17025-TAF證書',
            certTitle_5: '建材建材標章證書',
            certFile_renwu: [
                '仁武廠ISO9001證書_tw.pdf',
                '仁武廠ISO14001證書_tw.pdf',
                '仁武廠OHSAS18001證書_tw.pdf',
                '仁武廠ISO17025-TAF證書_tw.pdf'
            ],
            certFile_shulin: [
                '樹林廠ISO9001證書_tw.pdf',
                '樹林廠ISO14001證書_tw.pdf',
                '樹林廠OHSAS18001證書_tw.pdf',
                '樹林廠ISO17025-TAF證書_tw.pdf'
            ],
        },
        Contact: {
            description: '如果您對南亞防水產品有任何的建議，非常歡迎您來信指教，或致電客服專線，我們將用心聆聽，並以最快的速度回應給您，謝謝。',
            header_Title: '資料填寫',
            header_Text: '如果您對南亞公司有任何的建議，我們非常歡迎您來信指教，或致電客服專線，我們將用心聆聽，並以最快的速度回應給您，謝謝。',
            name: '姓名',
            phone: '電話',
            mail: '信箱',
            products_type: '產品類別',
            message: '留言內容',
            verify_Code: '驗證碼',
            contactCard_Title: '聯絡資訊',
            contactCard: {
                card_1: {
                    title: '服務專人: 孫啟峰 副處長',
                    phone: '(02) 2712-2211 Ext.8200',
                    mail: 'jackshan@npc.com.tw'
                },
                card_2: {
                    title: '服務專人: 潘界宇 高管師',
                    phone: '(02) 2712-2211 Ext.6222',
                    mail: 'panlin2033@hotmail.com.tw'
                }
            },
            warnningText: {
                name: {
                    warn_1: '請輸入姓名',
                },
                phone: {
                    warn_1: '請輸入電話',
                },
                mail: {
                    warn_1: '請輸入信箱',
                    warn_2: '信箱格式錯誤！請重新輸入',
                },
                selectClass: {
                    warn_1: '請選擇類別',
                },
                message: {
                    warn_1: '請輸入留言',
                },
                code: {
                    warn_1: '請輸入驗證碼',
                    warn_2: '驗證碼錯誤！請重新輸入',
                },
                send: {
                    warn_1: '未完成表單，請全部填寫後再送出資料',
                    warn_2: '完成',
                    warn_3: '錯誤'
                }
            },
            mailFormat_Data: {
                contact_Title: '聯絡資料',
                sender: '南亞塑膠-防水材',
                subject: '已收到您的聯絡資料',
                endText1: '感謝您的來信',
                endText2: '如有任何疑問可直接來信'
            }
        }
    },
    __PRODUCTS: {
        products_Page: {
            description: '產品description',
            page_Introduce: '防水為日常生活中不可或缺的重要條件，如何使用適當的材料才是防水成功的第一步。\
                根據施作場地的不同，以及防水強度和年限的需要，南亞致力於研發各類防水材料，從大型土木工程、商辦及工廠到頂樓DIY，南亞優質的防水材料值得您的信賴。',
            coil_Introduce: 'PVC塑膠布使用大型壓延式機台生產，成品以捲材方式呈現，可依客戶需求客製生產任意規格，\
                並依其用途添加不同配方組成，視為最經濟實惠且最多樣性的塑膠加工製品。其優越的加工性能使它不管是要再背膠、印刷、壓花或貼合都非常容易，\
                又因其材質疏水特性，天生自帶防水功能，可謂最適合也是最天然的防水材料。',
            coating_Introduce: '南亞PU防水材及酷樂漆為無溶劑環保材質，符合綠建材標章。PU防水材可用於浴廁及屋頂等場所，\
                屋頂防水搭配酷樂漆使用，可形成良好的防水隔熱系統，延長產品壽命，保用5年。產品施工簡易，有效改善居家生活品質。',
            green_Energy: {
                title: '綠色能源產品專區',
                Block_Text: '南亞公司致力研發環境友善及綠色能源相關的防水材料，同時進行資源上下垂直整合，佈局未來綠能市場。 積極採取對環境友善的各項前瞻做法，\
                    從綠色工廠到綠色採購供應鏈產品設計,去毒化及去物質化的創新思維，降低對環境的衝擊危害。',
                card1_Text: '二液型無溶劑PU材質，其物性符合國家標準CNS 6986。其使用之原料均符合環保需求，並通過綠建材檢驗標準。',
                card2_Text: '日光反射率高達90%以上，有效降低塗層內部之溫度，節能減碳。低VOC且無有害重金屬，通過綠建材認證。',
                card3_Text: '管路下方之地面鋪設南亞強韌防水膜可防止危害物質滲入地面，可以有效保護環境以達到永續發展。',
                card4_Text: '南亞與專業施工廠商合作，使鋪設屋頂防水膜之彩鋼屋頂可與新型太陽能板一體成型，可減少碳排放量外，保護地球。'
            },
            search_Title1: '我要找產品',
            search_Title2: '建議項目',
            search_optionList: [
                '建築用',
                '彩鋼用',
                '工業用',
                '農業用',
                '交通工程',
                '土木工程',
                '休閒生活',
                '其他'
            ],
        },
        __TEMPLATE: {
            feature_Title: '產品特性',
            advantage_title: '產品優勢',
            application_Title: '產品應用',
            media_Title: '相關影片',
            specification: '規格表',
            file_Title: '證書及相關文件',
            coil_Form_header: {
                thickness: '厚度',
                width: '寬度',
                rollpackaging: '卷裝',
                color: '色水'
            },
            coating_Form_header: {
                cooler_T1: '酷樂漆面漆',
                cooler_T2: '防蝕底漆',
                cooler_T3: '桶裝',
                cooler_T4: '接著底漆',
                PU_T1: '打底材',
                PU_T2: '填縫材',
                PU_T3: '地板材',
                PU_T4: '屋頂防水材',
                PU_T5: '防垂流壁板材',
                PU_T6: 'PU防水材',
                PU_T7: '表面處理材',
            }
        },
        tough_Waterproof: {
            title: '強韌防水膜',
            home_CardText: '高強度、防水防潮耐用且耐穿刺之聚氯乙烯材質，適用於各大土木環境工程使用。',
            Page: {
                description: '南亞精心研究開發土木工程用防水膜，具備抗震、耐老化、耐穿刺、耐酸鹼極佳之特性，適用於人工湖、蓄水池等各類防水工程。',
                introText: '南亞精心研究開發土木工程用防水膜，具備抗震、耐老化、耐穿刺、耐水、耐酸鹼極佳之特性，適用於各類防水工程 。',
                featureList: [
                    "物性強度佳，包含耐候，抗揮發性等。",
                    "適合熱風焊接，高週波縫接、接著劑黏著加工。",
                    "產品符合PGI標準(PVC Geomembrane lnstitute)。",
                    "穩定的物性與外觀品質。",
                    "依客戶需求調整軟度。"
                ],
                advantageText: '南亞強韌防水膜係本公司精心研究開發之抗震、耐老化、耐穿刺、耐水、耐酸鹼極佳之土木工程用膜，經實驗證明可長期耐酸鹼侵蝕後，仍可維持良好之尺寸穩定性強度。',
                applicationText: '一般地下室防水、垃圾掩埋場、污水處理池、蓄水池、人工湖、隧道、化學桶槽防溢堤等防水工程。',
                application_Img_alt: [
                    "強韌防水膜產品應用於一般地下室防水",
                    "強韌防水膜產品應用於垃圾掩埋場",
                    "強韌防水膜產品應用於化學桶槽防溢",
                    "強韌防水膜產品應用於隧道防水"
                ],
                mediaList: [
                    {
                        mediaTitle: '南亞PVC防水膠布',
                        mediaSrc: 'https://www.youtube.com/embed/bmPVtNaXJ3k'
                    }
                ],
                fileList: [
                    {
                        fileTitle: '防水膜樣卡 (.pdf)',
                        fileLink: 'normal/防水膜樣卡.pdf'
                    }
                ],
                formData: {
                    text1: '雙層單色(全黑)'
                }
            }
        },
        roof_Waterproof: {
            title: '屋頂防水膜',
            home_CardText: '使用加筋材質增加韌性，添加抗UV配方且通過QUV測試，可適用於各類屋頂防水使用。',
            Page: {
                description: '南亞屋頂防水膜為PVC耐候防水膜，添加強力聚酯纖維網布貼合，可防止過度拉伸及變形，適用於室外嚴苛環境中。如RC屋頂及彩鋼屋頂維修等防水工程。',
                introText: '新型加筋防水膜添加強力聚酯纖維網布貼合，可防止防水膜過度拉伸及變形，適用於長期室外及嚴苛環境中。如RC屋頂防水及彩鋼屋頂維修等防水工程。',
                featureList: [
                    '添加強力聚酯纖維網布貼合的高分子加筋防水膜，可防止過度拉伸及變形，用在長期室外及嚴苛環境中。',
                    '加筋夾網可以避免經時收縮，增加耐用度，使用可達10年。',
                    'CNS 10145(2016) 合成聚合物薄片防水膠布(複合薄片-補強複合型)搭接抗拉強度及物性皆符合要求。',
                    '耐化學性在酸鹼環境測試24小時外觀無明顯變化(ASTM) D543-14。',
                    '耐厚試驗1,000小時外觀無異狀(ASTM G154-16 Cycle 1 & ASTM D2616-12)。'
                ],
                advantageText: '抗拉強度及耐用度高，可用於長期室外及嚴苛環境中，如RC屋頂防水及彩鋼屋頂維修等防水工程。',
                applicationText: '適用於各式建築的屋頂，如輕質混泥土面、磁磚面、斜屋頂等。',
                application_Img_alt: [
                    '屋頂防水膜產品應用於各式建築的屋頂',
                    '屋頂防水膜產品應用於輕質混泥土屋頂',
                    '屋頂防水膜產品應用於磁磚面屋頂',
                    '屋頂防水膜產品應用於斜屋頂'
                ],
                mediaList: [
                    {
                        mediaTitle: '長庚大學屋頂防水工程',
                        mediaSrc: 'https://www.youtube.com/embed/xrg7dokp_Ps',
                    }
                ],
                fileList: [
                    {
                        fileTitle: '防水膜樣卡 (.pdf)',
                        fileLink: 'normal/防水膜樣卡.pdf'
                    },
                    {
                        fileTitle: 'SGS加筋防水膜-綜合板 (.pdf)',
                        fileLink: 'tunnel/SGS加筋防水膜-綜合板.pdf'
                    }
                ],
                formData: {
                    text1: '雙色(黑白)'
                }
            }
        },
        tunnel_Waterproof: {
            title: '隧道防水膜',
            home_CardText: '使用雙層黑白兩色貼合合成，通過DIN16726測試，白色面能使漏水處顯而易見，即時查看並防補裂縫處。',
            Page: {
                description: '南亞隧道防水膜使用雙層高強度聚氯乙烯高分子材質貼合合成，具備高強度物性並通過DIN16726測試，為各大交通工程以及隧道防水工程中最適用的材料。',
                introText: '南亞隧道防水膜使用雙層高強度聚氯乙烯高分子材質貼合合成，具備高強度物性並通過DIN16726測試，為各大交通工程以及隧道防水工程中最適用的材料。',
                featureList: [
                    '2.0mm以上厚度具備優異的機械強度以及抗老化性。',
                    '抗水壓、耐酸鹼及耐火性等均符合DIN以及ASTM測試標準。',
                    '施工簡單使用熱風加工可直接熔接貼合，風壓測試合格。',
                    '使用黑白雙層對比色設計，方便辨識破損可及時修補。'
                ],
                advantageText: '通過DIN16726測試標準符合公共工程規範，縫接強度大於13.5N/mm2符合其施工需求，耐酸鹼抗拉及伸長變化率均符合客戶要求，國內外各大隧道均已使用多年無異狀。',
                applicationText: '適用於各大公共隧道工程，如高鐵、鐵路、捷運及高速公路等。',
                application_Img_alt: [
                    '隧道防水膜產品應用於高鐵隧道工程',
                    '隧道防水膜產品應用於鐵路隧道工程',
                    '隧道防水膜產品應用於捷運隧道工程',
                    '隧道防水膜產品應用於高速公路隧道工程'
                ],
                // mediaList: null,
                fileList: [
                    {
                        fileTitle: '防水膜樣卡 (.pdf)',
                        fileLink: 'normal/防水膜樣卡.pdf'
                    },
                    {
                        fileTitle: 'SGS隧道防水膜-綜合板 (.pdf)',
                        fileLink: 'tunnel/SGS隧道防水膜-綜合板.pdf'
                    }
                ],
                formData: {
                    text1: '雙色(黑/白)'
                }
            }
        },
        red_Tape: {
            title: '紅泥膠布',
            home_CardText: '南亞公司研發「紅泥膠布」具有極佳抗老化之耐候性。配合永續特殊環保配方，可抗化學品及抗酸鹼。',
            Page: {
                description: '南亞公司研發「紅泥膠布」具有極佳抗老化之耐候性，克服一般塑膠材料耐候性欠佳，經時久曝易產生老化龜裂的缺點。',
                introText: '南亞公司研發「紅泥膠布」具有極佳抗老化之耐候性，耐候性佳，經時久曝易產生老化龜裂的缺點，廣泛使用在畜牧業之沼氣槽及紅泥沼氣袋。',
                featureList: [
                    '具備極佳之抗老化耐候性，烈日曝曬仍可維持強韌物性。',
                    '使用熱熔接合方式施工，施工方便且品質穩定。',
                    '配合永續特殊環保配方，可抗化學品及抗酸鹼。',
                    '用途廣泛包含建築、土木、農業、水利及工業用等。'
                ],
                advantageText: '耐候性佳久經曝曬符合ASTM-D2565測試方法，具備耐化學品性特性、高強度物性及熱熔接合加工等優勢。配合政府沼氣發電政策現廣泛使用在畜牧業之沼氣槽。',
                applicationText: '可應用於沼氣槽、紅泥沼氣袋、氣密式稻穀儲倉、養菇寮。',
                application_Img_alt: [
                    '紅泥膠布產品應用於沼氣槽',
                    '紅泥膠布產品應用於紅泥沼氣袋',
                    '紅泥膠布產品應用於養菇寮',
                    '紅泥膠布產品應用於氣密式稻穀儲倉'
                ],
                // mediaList: null,
                fileList: [
                    {
                        fileTitle: "紅泥膠布DM (.pdf)",
                        fileLink: "red_mud/紅泥膠布DM.pdf"
                    }
                ]
            }
        },
        pool: {
            title: '泳池裡襯',
            home_CardText: '使用高分子結構取代傳統磁磚，可附合強韌網布為優質又耐用的游泳池裡襯。為國內信賴生產廠商，並可通過防霉、抗菌及耐化學品測試。',
            Page: {
                description: '南亞率先與國際接軌，使用高分子材質取代傳統磁磚，開發全新、優質又耐用的泳池裡襯。現為領導國內生產廠商，並可通過防霉、抗菌及耐化學品等測試。',
                introText: '南亞率先與國際接軌，使用高分子材質取代傳統磁磚，開發全新、優質又耐用的泳池裡襯。現為國內領導生產廠商，並可通過防霉、抗菌及耐化學品等測試。',
                featureList: [
                    '國產高品質聚氯乙烯材質，可配合客製各種規格 。',
                    '搭配各類深壓紋，使用在泳池防滑階梯及浴室地墊。',
                    '可添加強韌網布製作複合材質，大幅降低收縮率通過EN1107-2測試。',
                    '使用高週波或熱風加工成游泳池，施工簡單且翻新容易。',
                    '使用特殊配方耐氯通過EN15836測試，經時無色澤變化。',
                    '專業設計團隊可OEM或ODM配合，任何花色都可客製開發。'
                ],
                advantageText: '耐候性佳低溫可達-37.2°Ｃ，並具備防霉抗菌配方、耐氯及耐化學品性佳、高週波縫接加工製程等優勢。現已大量銷往世界各地包括美洲、澳洲及歐洲等，符合國際標準及品質認證。',
                applicationText: '一般私人用或公共游泳池。室內各類兒童、水療池及比賽用等游泳池。浴室地墊及防滑泳池階梯。',
                application_Img_alt: [
                    '泳池裡襯產品應用於公共游泳池',
                    '泳池裡襯產品應用於室內各類兒童池',
                    '泳池裡襯產品應用於水療池',
                    '泳池裡襯產品應用於比賽用等游泳池'
                ],
                // mediaList: null,
                fileList: [
                    {
                        fileTitle: "游泳池裡襯DM (.pdf)",
                        fileLink: "pool/游泳池裡襯DM_en.pdf"
                    }
                ],
                formData: {
                    thickness_text1: '地上型0.280~0.762 mm',
                    thickness_text2: '地下型0.495~0.902 mm',
                    thickness_text3: '複合型0.9~1.5 mm',
                    roll_text1: '一般型150～300碼/每支',
                    roll_text2: '複合型30～50碼/每支',
                }
            }
        },
        Cooler_Paint: {
            title: '酷樂漆',
            home_CardText: '獲專利認證，使用壽命長達5~8年，較市售品高出2倍以上。可有效降低室內溫度，節能效果高達31.8%，兼具防水功效。',
            Page: {
                description: '南亞酷樂漆是環保防水漆，且具有專利，使用壽命可達5~8年，較一般市售品高出2倍以上。有效降低室內溫度，節能效果高達31.8%，且兼具防水隔熱功效。',
                introText: '南亞酷樂漆隔熱塗料具有專利，使用壽命可達5~8年，較一般市售品高出2倍以上。有效降低室內溫度，節能效果高達31.8%，且兼具防水功效。',
                featureList: [
                    '多功能水性塗層面漆材料，搭配底漆，耐候性極佳。',
                    '低VOC無有害重金屬，通過綠建材認證。',
                    '通過台灣標準CNS 4940規範，品質認證有保障。',
                    '日光反射率高達90%以上，有效降低塗層內部之溫度。',
                    '自潔性強不易髒汙，且具防水功效。'
                ],
                advantageText: '南亞精心產製隔熱漆，耐候性佳、使用壽命可達5~8年，有效降低工廠儲槽VOC排放30%，為環保節能減碳貢獻心力!',
                applicationText: '可應用於屋頂、外牆、彩鋼、桶槽等隔熱工程。',
                application_Img_alt: [
                    '隔熱漆產品應用於屋頂防水',
                    '隔熱漆產品應用於外牆防水',
                    '隔熱漆產品應用於彩鋼',
                    '隔熱漆產品應用於工廠桶槽'
                ],
                mediaList: [
                    {
                        mediaTitle: "PU防水材施工",
                        mediaSrc: "https://www.youtube.com/embed/liHga4tRbrg",
                    },
                    {
                        mediaTitle: "長效耐候型",
                        mediaSrc: "https://www.youtube.com/embed/1xAfH8kA1os",
                    },
                    {
                        mediaTitle: "龍潭湖施作",
                        mediaSrc: "https://www.youtube.com/embed/H85DNxel9pQ",
                    },
                    {
                        mediaTitle: "浸泡試驗",
                        mediaSrc: "https://www.youtube.com/embed/xTVa5vQqLrw",
                    },
                    {
                        mediaTitle: "美的in台灣",
                        mediaSrc: "https://www.youtube.com/embed/JBjuhjybJx8",
                    },
                    {
                        mediaTitle: "隔熱塗料施作",
                        mediaSrc: "https://www.youtube.com/embed/SzXl9bDUCuY",
                    },
                ],
                fileList: [
                    {
                        fileTitle: "酷樂漆DM_tw (.pdf)",
                        fileLink: "cooler/酷樂漆DM_tw.pdf"
                    },
                    {
                        fileTitle: "酷樂漆耐燃測試報告(.pdf)",
                        fileLink: "cooler/酷樂漆耐燃測試報告.pdf"
                    },
                    {
                        fileTitle: "酷樂漆氙弧燈2500hr測試報告(.pdf)",
                        fileLink: "cooler/酷樂漆氙弧燈2500hr測試報告.pdf"
                    },
                    {
                        fileTitle: "酷樂漆SGS耐鹽霧4200hr測試報告(.pdf)",
                        fileLink: "cooler/酷樂漆SGS耐鹽霧4200hr測試報告.pdf"
                    },
                    {
                        fileTitle: "南亞CSL01系統CX認證報告",
                        fileLink: "cooler/南亞CSL01系統CX認證報告.pdf"
                    },
                    {
                        fileTitle: "南亞水性隔熱漆綠建材標章",
                        fileLink: "cooler/南亞水性隔熱漆綠建材標章.pdf"
                    },
                    {
                        fileTitle: "酷樂漆QUV 1000小時測試報告",
                        fileLink: "cooler/酷樂漆QUV 1000小時測試報告.pdf"
                    },
                    {
                        fileTitle: "酷樂漆SGS VOC測試報告",
                        fileLink: "cooler/酷樂漆SGS VOC測試報告.pdf"
                    },
                    {
                        fileTitle: "酷樂漆日光反射指數測試報告",
                        fileLink: "cooler/酷樂漆日光反射指數測試報告.pdf"
                    },
                    {
                        fileTitle: "酷樂漆專利證書台灣發明I626215證書",
                        fileLink: "cooler/酷樂漆專利證書台灣發明I626215證書.pdf"
                    },
                    {
                        fileTitle: "酷樂漆塗料公會VOC測試報告",
                        fileLink: "cooler/酷樂漆塗料公會VOC測試報告.pdf"
                    },
                    {
                        fileTitle: "酷樂漆綠建材標章證書",
                        fileLink: "cooler/酷樂漆綠建材標章證書.pdf"
                    },
                    {
                        fileTitle: "防蝕漆日本專利證書",
                        fileLink: "cooler/防蝕漆日本專利證書.pdf"
                    },
                    {
                        fileTitle: "防蝕漆專利證書台灣發明I766589證書",
                        fileLink: "cooler/防蝕漆專利證書台灣發明I766589證書.pdf"
                    }
                ],
                formData: {
                    text1: `白色、淺黃色、淺沙色等 \n 共24色`,
                    text2: `主劑：墨綠色、灰色 \n 硬化劑：棕色`,
                    text3: 'RC、桶槽、彩鋼',
                    text4: `主劑：19kg / 8kg \n 硬化劑：7kg / 3kg`,
                }
            }
        },
        PU_Waterproof: {
            title: 'PU防水材',
            home_CardText: '二液型無溶劑PU材質，符合國家標準CNS 6986，通過綠建材檢驗標準，主要用途為一般屋頂防水及其他場所防水。',
            Page: {
                description: 'PU防水材為二液型無溶劑PU材質，符合國家標準CNS 6986。使用之原料均符合環保需求，通過綠建材檢驗標準，不含毒性化學物質。主要用途為一般屋頂防水及其他易積水及潮濕區域防水。',
                introText: '防水材為二液型無溶劑PU材質，其物性符合國家標準CNS 6986。其使用之原料均符合環保需求，通過綠建材檢驗標準，不含毒性化學物質如P類可塑劑、MOCA、八大重金屬及環境甲醛含量零檢出，主要用途為一般屋頂防水及其他場所防水。',
                featureList: [
                    '物性強度佳，耐候佳(冷、熱、酸、鹼)，使用壽命長。',
                    '低VOC，100%固形分。',
                    '通過CNS 6986、CNS 8644國家標準，具正字標記認證。',
                    '符合綠建材標章，採用環保原料，不含TDI、MOCA及溶劑。',
                    '業界老字號產品，擁有無數實績。'
                ],
                advantageText: '南亞PU防水材係本公司精心研究開發之產品。其抗震、耐老化、耐水、耐酸鹼性均佳，適用於RC面各類施工場地。',
                applicationText: '適用於一般屋頂、地下室、浴室、內外牆等易積水及潮濕區域防水。',
                application_Img_alt: [
                    'PU防水材產品應用於一般屋頂防水',
                    'PU防水材產品應用於地下室防水',
                    'PU防水材產品應用於浴室防水',
                    'PU防水材產品應用於內外牆積水'
                ],
                mediaList: [
                    {
                        mediaTitle: "PU防水材施工",
                        mediaSrc: "https://www.youtube.com/embed/liHga4tRbrg",
                    },
                    {
                        mediaTitle: "美的in台灣",
                        mediaSrc: "https://www.youtube.com/embed/JBjuhjybJx8",
                    },
                ],
                fileList: [
                    {
                        fileTitle: "南亞PU防水材US-10(黑色)綠建材標章證書(.pdf)",
                        fileLink: "PU_Waterproof/南亞PU防水材US-10(黑色)綠建材標章證書.pdf"
                    },
                    {
                        fileTitle: "南亞PU防水材US-20(黑色)綠建材標章證書(.pdf)",
                        fileLink: "PU_Waterproof/南亞PU防水材US-20(黑色)綠建材標章證書.pdf"
                    },
                    {
                        fileTitle: "南亞PU建材正字標記證書(.pdf)",
                        fileLink: "PU_Waterproof/南亞PU建材正字標記證書.pdf"
                    },
                ],
                formData: {
                    text1: `主劑：20kg \n 硬化劑：10kg`,
                    text2: `主劑：10kg \n 硬化劑：5kg`,
                    text3: `主劑：16kg \n 硬化劑：4kg`,
                    text4: `主劑：10kg \n 硬化劑：10kg`,
                    text5: `主劑：9kg \n 硬化劑：3kg`
                }
            }
        }
    }
}