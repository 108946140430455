<template lang="pug">
main#PU_Waterproof
    pageTop(:pageTitle="$t('__PRODUCTS.PU_Waterproof.title')" imgSrc="Products/PU_Waterproof/PU page top img_desktop.jpg" imgSrc_Mobile="Products/PU_Waterproof/PU page top img_mobile.jpg")
    Product_Template(colorType="coating" :importProduct_Data="PU_Waterproof_Data")
        template(v-slot:specification_Form)
            //- .slot_Area
            //-     .title
            //-         h2.productTemplate_H2 規格表
            .formatTable
                //- 打底材
                ul
                    li {{$t('__PRODUCTS.__TEMPLATE.coating_Form_header.PU_T1')}}
                    li PRIMER
                    li 15kg
                //- PU防水材
                ul
                    li {{$t('__PRODUCTS.__TEMPLATE.coating_Form_header.PU_T6')}}
                    li US-10 / US-20
                    li {{$t('__PRODUCTS.PU_Waterproof.Page.formData.text1')}}
                //- 表面處理材
                ul
                    li {{$t('__PRODUCTS.__TEMPLATE.coating_Form_header.PU_T7')}}
                    li MAT-FINISH
                    li {{$t('__PRODUCTS.PU_Waterproof.Page.formData.text2')}}

        template(v-slot:specification_Form2)
            .formatTable
                //- 防垂流壁板材
                ul
                    li {{$t('__PRODUCTS.__TEMPLATE.coating_Form_header.PU_T5')}}
                    li US-12
                    li {{$t('__PRODUCTS.PU_Waterproof.Page.formData.text3')}}
                //- 地板材
                ul
                    li {{$t('__PRODUCTS.__TEMPLATE.coating_Form_header.PU_T3')}}
                    li A-TYPE
                    li {{$t('__PRODUCTS.PU_Waterproof.Page.formData.text4')}}
                //- 填縫材
                ul
                    li {{$t('__PRODUCTS.__TEMPLATE.coating_Form_header.PU_T2')}}
                    li SEAL-500
                    li {{$t('__PRODUCTS.PU_Waterproof.Page.formData.text5')}}
</template>

<script>
import pageTop from "@/components/pageTop.vue"
import Product_Template from "@/components/Product_Template.vue"
// import Button_Style from "@/components/Button_Style.vue"

export default {
    metaInfo() {
        return{
            title: `${this.$t('__PRODUCTS.PU_Waterproof.title')}-`,
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: this.$t('__PRODUCTS.PU_Waterproof.Page.description')
                },
                // Open Graph / Facebook
                { property: 'og:url', content: `${this.$t('__MAIN.domainName_URL')}/Products-${this.$route.params.type}/PU_Waterproof` },
                { property: 'og:description', content: this.$t('__PRODUCTS.PU_Waterproof.Page.description') },

                // Twitter
                { property: 'twitter:url', content: `${this.$t('__MAIN.domainName_URL')}/Products-${this.$route.params.type}/PU_Waterproof` },
                { property: 'twitter:description', content: this.$t('__PRODUCTS.PU_Waterproof.Page.description') },
            ]
        }
    },

    components: {
        pageTop,
        Product_Template
        // Button_Style
    },

    data() {
        return{
            PU_Waterproof_Data: {
                introduce: {
                    title: this.$t('__PRODUCTS.PU_Waterproof.title'),
                    introText: this.$t('__PRODUCTS.PU_Waterproof.Page.introText'),
                    swiper_Img: [
                        "Products/PU_Waterproof/products discription_pu1.jpg",
                        "Products/PU_Waterproof/products discription_pu2.jpg",
                        "Products/PU_Waterproof/products discription_pu3.jpg",
                    ],
                    featureList: this.$t('__PRODUCTS.PU_Waterproof.Page.featureList')
                },
                advantageText: this.$t('__PRODUCTS.PU_Waterproof.Page.advantageText'),
                productApp: {
                    appText: this.$t('__PRODUCTS.PU_Waterproof.Page.applicationText'),
                    circle_Img:[
                        {
                            imgSrc: "Products/PU_Waterproof/products application_pu1.jpg",
                            altText: this.$t('__PRODUCTS.PU_Waterproof.Page.application_Img_alt[0]')
                        },
                        {
                            imgSrc: "Products/PU_Waterproof/products application_pu2.jpg",
                            altText: this.$t('__PRODUCTS.PU_Waterproof.Page.application_Img_alt[1]')
                        },
                        {
                            imgSrc: "Products/PU_Waterproof/products application_pu3.jpg",
                            altText: this.$t('__PRODUCTS.PU_Waterproof.Page.application_Img_alt[2]')
                        },
                        {
                            imgSrc: "Products/PU_Waterproof/products application_pu4.jpg",
                            altText: this.$t('__PRODUCTS.PU_Waterproof.Page.application_Img_alt[3]')
                        }
                    ]
                },
                mediaList: this.$t('__PRODUCTS.PU_Waterproof.Page.mediaList'),
                fileList: this.$t('__PRODUCTS.PU_Waterproof.Page.fileList')
            }
        }
    },

    methods: {
        getImgUrl(pic) {
            return require('@/assets/' + pic)
        },
    },
}
</script>

<style lang="sass">
@import "@/css/my_Style.sass"
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap');

.tw #PU_Waterproof
    // .slot_Area
    //     *
    //         // border: 1px solid #000
    //     .title
    //         border-bottom: 1px solid #707070
    //         padding-bottom: 18px
    li
        white-space: pre-line

    .formatTable
        *
            // border: 1px solid #000
        +size(100%, 200px)
        min-width: 700px
        display: flex
        border: solid 1px #767777
        ul
            flex: 1
            li
                +text_Setting(calc(14px + 0.5vw), 1.33, 1.2px, #707070, 300, "Noto Sans TC", center)
                +flex_Center_Setting
                border: solid 1px #b5baba
                padding: 10px
                height: 35%
                @include _Upper_1920_width()
                    font-size: 24px
                &:nth-child(1)
                    background-color: #2d1655
                    color: #fafafa
                    height: 30%

    // .formatTable:nth-child(1)
    //     li
    //         height: 35%

    .formatTable:nth-child(2)
        margin-top: 50px

// ==== 英文版CSS ====
.en 
    // 繼承tw css
    @extend .tw
// .en #PU_Waterproof
//     .formatTable
//         min-width: 900px
//         height: 160px

</style>        