<template lang="pug">
main#Contact
    pageTop(:pageTitle="$t('__MAIN_TITLE.Contact')" imgSrc="Contact/contact_page top img_desktop.jpg" imgSrc_Mobile="Contact/contact_page top img_mobile.jpg")
    .Contact_Container
        .formGroup
            .title
                h2.contact_H2 {{$t('__SUB_TITLE.Contact.header_Title')}}
                p.contact_Text {{$t('__SUB_TITLE.Contact.header_Text')}}
            form.contactForm
                //- 姓名
                .form_input
                    p.from_inputTitle {{$t('__SUB_TITLE.Contact.name')}}
                    .inputBlock
                        input(type="text" v-model="inputStatus.name")
                        span.warnningStatus {{warnningStatus.name_Warnning}}
                //- 電話
                .form_input
                    p.from_inputTitle {{$t('__SUB_TITLE.Contact.phone')}}
                    .inputBlock
                        input(type="text" v-model="inputStatus.phone")
                        span.warnningStatus {{warnningStatus.phone_Warnning}}
                //- 信箱
                .form_input
                    p.from_inputTitle {{$t('__SUB_TITLE.Contact.mail')}}
                    .inputBlock
                        input(type="text" v-model="inputStatus.mail")
                        span.warnningStatus {{warnningStatus.mail_Warnning}}
                //- 產品類別
                .form_input
                    p.from_inputTitle {{$t('__SUB_TITLE.Contact.products_type')}}
                    .inputBlock#contact_SelectClass
                        select(name="contact_SelectClass" v-model="inputStatus.selectClass")
                            option(selected disabled)
                            option(v-for="option of optionList" :value="option") {{option}}
                        b-icon.select_icon(icon="caret-down-fill")
                        span.warnningStatus {{warnningStatus.selectClass_Warnning}}
                //- 留言內容
                .form_input
                    p.from_inputTitle {{$t('__SUB_TITLE.Contact.message')}}
                    .inputBlock.messageArea
                        textarea.message_Text(v-model="inputStatus.message")
                        span.warnningStatus {{warnningStatus.message_Warnning}}
                //- 驗證碼
                .form_input.codeForm
                    p.from_inputTitle {{$t('__SUB_TITLE.Contact.verify_Code')}}
                    .code_inputGroup
                        .inputBlock.code_inputBlock
                            input.code_input(type="text" v-model="inputStatus.code")
                            span.warnningStatus {{warnningStatus.code_Warnning}}
                        .code
                            img(src='@/assets/code.jpg', :alt="$t('__SUB_TITLE.Contact.verify_Code')")
                            p.codeText {{codeText}}
                            b-icon.codeRefresh_icon(icon='arrow-repeat' @click='codeVerification_Refresh')
            //- 送出資料
            Button.button_send(@click="sendMail_function") {{$t('__BUTTON_TEXT.sendData')}}
        
        .contact_Card_Group
            .title
                h2.contact_H2 {{$t('__SUB_TITLE.Contact.contactCard_Title')}}
                //- 南亞塑膠工業股份有限公司 塑膠第一事業部
                h3.contact_H3 {{`${$t('__MAIN_TITLE.Company_Name')} \n ${$t('__MAIN_TITLE.Division')}`}}
            .card_Group
                //- 捲材類
                .card
                    .contactInformation
                        .icon
                            img(src="@/assets/banner/icon01.png", alt="捲材icon")
                            h4.contact_H4 {{$t('__TYPE.coil')}}
                        .information
                            h5.contact_H5 {{$t('__SUB_TITLE.Contact.contactCard.card_1.title')}}
                            h5.contact_H5 {{`${$t('__SUB_TITLE.Contact.phone')}: ${$t('__SUB_TITLE.Contact.contactCard.card_1.phone')} `}}
                            h5.contact_H5 {{`${$t('__SUB_TITLE.Contact.mail')}: ${$t('__SUB_TITLE.Contact.contactCard.card_1.mail')} `}}
                //- 塗料類
                .card
                    .contactInformation
                        .icon
                            img(src="@/assets/banner/icon02.png", alt="塗料icon")
                            h4.contact_H4 {{$t('__TYPE.coating')}}
                        .information
                            h5.contact_H5 {{$t('__SUB_TITLE.Contact.contactCard.card_2.title')}}
                            h5.contact_H5 {{`${$t('__SUB_TITLE.Contact.phone')}: ${$t('__SUB_TITLE.Contact.contactCard.card_2.phone')} `}}
                            h5.contact_H5 {{`${$t('__SUB_TITLE.Contact.mail')}: ${$t('__SUB_TITLE.Contact.contactCard.card_2.mail')} `}}

        Button_Style.button_home(:btnTitle="$t('__BUTTON_TEXT.backHome')" linkType="routerName" link="Home" arrow="right")

        mailLoadingAnimation(:isMailing="isMailing_Display")
</template>

<script>
import pageTop from "@/components/pageTop.vue"
import Button_Style from "@/components/Button_Style.vue"
import $ from 'jquery'
import mailLoadingAnimation from "@/components/mailLoadingAnimation.vue"

export default {
    metaInfo() {
        return{
            title: `${this.$t('__MAIN_TITLE.Contact')}-`,
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: this.$t('__SUB_TITLE.Contact.description')
                },
                // Open Graph / Facebook
                { property: 'og:url', content: `${this.$t('__MAIN.domainName_URL')}/Contact` },
                { property: 'og:description', content: this.$t('__SUB_TITLE.Contact.description') },

                // Twitter
                { property: 'twitter:url', content: `${this.$t('__MAIN.domainName_URL')}/Contact` },
                { property: 'twitter:description', content: this.$t('__SUB_TITLE.Contact.description') },
            ]
        }
    },
    
    components: {
        pageTop,
        Button_Style,
        mailLoadingAnimation
    },

    data() {
        return{
            codeText: '',

            // 輸入的表單資料
            inputStatus: {
                name: '',
                phone: '',
                mail: '',
                selectClass: '',
                message: '',
                code: '',
            },

            warnningStatus: {
                name_Warnning: '',
                phone_Warnning: '',
                mail_Warnning: '',
                selectClass_Warnning: '',
                message_Warnning: '',
                code_Warnning: ''
            },

            isMailing_Display: false
        }
    },

    computed: {
        optionList() {
            return this.$t('__PRODUCTS.products_Page.search_optionList')
        },

        warnningText() {
            return {
                name: this.$t('__SUB_TITLE.Contact.warnningText.name'),
                phone: this.$t('__SUB_TITLE.Contact.warnningText.phone'),
                mail: this.$t('__SUB_TITLE.Contact.warnningText.mail'),
                selectClass: this.$t('__SUB_TITLE.Contact.warnningText.selectClass'),
                message: this.$t('__SUB_TITLE.Contact.warnningText.message'),
                code: this.$t('__SUB_TITLE.Contact.warnningText.code'),
                send: this.$t('__SUB_TITLE.Contact.warnningText.send'),
            }
        },

        sendData: {
            get() {
                return {
                    ...this.inputStatus,
                    // contact_Title: "聯絡資料",
                    // sender: "南亞塑膠-防水材",
                    // subject: "已收到您的聯絡資料",
                    // name_Title: "姓名",
                    // phone_Title: "電話",
                    // mail_Title: "信箱",
                    // class_Title: "產品類別",
                    // message_Title: "留言內容",
                    // endText1: "感謝您的來信",
                    // endText2: "如有任何疑問可直接來信：customerfirst@npc.com.tw",
                    // Company_Title: "南亞塑膠工業股份有限公司"
                    contact_Title: this.$t('__SUB_TITLE.Contact.mailFormat_Data.contact_Title'),
                    sender: this.$t('__SUB_TITLE.Contact.mailFormat_Data.sender'),
                    subject: this.$t('__SUB_TITLE.Contact.mailFormat_Data.subject'),
                    name_Title: this.$t('__SUB_TITLE.Contact.name'),
                    phone_Title: this.$t('__SUB_TITLE.Contact.phone'),
                    mail_Title: this.$t('__SUB_TITLE.Contact.mail'),
                    class_Title: this.$t('__SUB_TITLE.Contact.products_type'),
                    message_Title: this.$t('__SUB_TITLE.Contact.message'),
                    endText1: this.$t('__SUB_TITLE.Contact.mailFormat_Data.endText1'),
                    endText2: `${this.$t('__SUB_TITLE.Contact.mailFormat_Data.endText2')}：customerfirst@npc.com.tw`,
                    Company_Title: this.$t('__MAIN_TITLE.Company_Name'),
                }
            }
        }
    },

    methods: {
        getImgUrl(pic) {
            return require('@/assets/' + pic)
        },

        // 亂數產生驗證碼
        codeVerification_Refresh() {
            let codeArray = [0,1,2,3,4,5,6,7,8,9,
                'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J',
				'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T',
                'U', 'V', 'W', 'X', 'Y', 'Z']
            let newcode = []
            let index = Math.floor(Math.random() * codeArray.length)

            for(let i=0; i < 6; i++){
                newcode.push(codeArray[index])
                index = Math.floor(Math.random() * codeArray.length)
            }
            this.codeText = newcode.join('')
        },

        // 檢查每個input是否有空白，有填入東西則 completeCount + 1，這裡全部完成計數總共6
        check_input_noBlankCount() {
            let count = 0
            Object.keys(this.inputStatus).forEach((key)=>{
                if(this.inputStatus[key] === ''){
                    this.warnningStatus[`${key}_Warnning`] = this.warnningText[key].warn_1
                }
                else{
                    this.warnningStatus[`${key}_Warnning`] = ''
                    count++
                }
            })
            return count
        },

        // 檢查mail格式
        check_mail_isCorrect() {
            const emailRule =  /[a-zA-Z][a-zA-Z0-9_]*(\.[a-zA-Z0-9_]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.([a-zA-Z]{2,4})$/
            const mail = this.inputStatus.mail
            // return emailRule.test(mail) ? true : false
            if(!emailRule.test(mail) && mail !== ''){
                this.warnningStatus.mail_Warnning = this.warnningText.mail.warn_2
            }
            return emailRule.test(mail) ? true : false
            // return true
        },

        // 檢查驗證碼，全部正確才return true
        check_code_isCorrect() {
            const code = this.inputStatus.code
            if(code !== this.codeText){
                if(code !== ''){
                    this.warnningStatus.code_Warnning = this.warnningText.code.warn_2
                }
                return false
            }
            return true
        },

        // 確認表單是否完成填入
        check_Form_isCompleted() {
            // const _this = this
            let completeCount = 0

            // 檢查每個input是否有空白，計數6
            completeCount += this.check_input_noBlankCount()

            // 檢查mail格式，計數1
            this.check_mail_isCorrect() ? completeCount++ : false
            
            // 檢查驗證碼，計數1
            this.check_code_isCorrect() ? completeCount++ : false
            // console.log(completeCount)
            
            // 全部表單檢查計數完成，回傳true
            return completeCount === 8 ? true : false
        },

        sendMail_function() {
            if(!this.check_Form_isCompleted()){
                // 未完成表單
                return alert(this.warnningText.send.warn_1)
            }
            
            this.isMailing_Display = true;
            setTimeout(() => {
                $.ajax({
                    type: 'post',
                    url: '/email',
                    data: this.sendData,
                    // async: false,
                    success: () => {
                        // 完成
                        alert(this.warnningText.send.warn_2)
                        // let data = this.inputStatus
                        Object.keys(this.inputStatus).forEach(key => {this.inputStatus[key] = '' })
                        this.isMailing_Display = false
                        this.codeVerification_Refresh()
                    },
                    error: () => {
                        // 錯誤
                        alert(this.warnningText.send.warn_3)
                        this.isMailing_Display = false
                    },
                })
            }, 500)
        }
    },

    created() {
        // 載入更新一次驗證碼
        this.codeVerification_Refresh()
    }
}
</script>

<style lang="sass">
@import "@/css/my_Style.sass"
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap');

.tw #Contact
    *
        // border: 1px solid #000
    h2.contact_H2
        +text_Setting(calc(18px + 1.2vw), 1, 2.1px, #292929, 500, "Noto Sans TC", center)
        @include _Upper_1920_width()
            font-size: 42px
    h3.contact_H3
        +text_Setting(calc(14px + 1vw), 1, 1.6px, #4e4e4e, 400, "Noto Sans TC", center)
        @include _Upper_1920_width()
            font-size: 32px
    h4.contact_H4
        +text_Setting(calc(16px + 0.4vw), 1, 0.24px, #000, 400, "Noto Sans TC", center)
        @include _Upper_1920_width()
            font-size: 24px
    h5.contact_H5
        +text_Setting(calc(14px + 0.4vw), 1.55, 1.1px, #000, 400, "Noto Sans TC", left)
        @include _Upper_1920_width()
            font-size: 22px
    p.contact_Text
        +text_Setting(calc(14px + 0.5vw), 1.33, 0.24px, #706c6c, 300, "Noto Sans TC", center)
        @include _Upper_1920_width()
            font-size: 24px
    p.from_inputTitle
        +text_Setting(calc(16px + 0.5vw), 1, 1.2px, #535252, 400, "Noto Sans TC", left)
        +flex_Center_Setting(flex-start)
        @include _Upper_1920_width()
            font-size: 24px
    p.codeText
        +text_Setting(font(10), 1.7, 4px, #1a4766, 400, 'Noto Sans TC', center)
        font-style: italic
    input, select, textarea
        +text_Setting(calc(16px + 0.5vw), 1.7, 1.2px, #000, 300, "Noto Sans TC", left)
        padding: 0 25px
        @include _Upper_1920_width()
            font-size: 24px
    span.warnningStatus
        +text_Setting(font(2), 1.7, 2px, red, 400, "Noto Sans TC", left)

    select
        +size(100%)
        appearance: none    // select預設下拉的icon取消
        &::-ms-expand   // select預設下拉的icon取消(for IE)
            display: none
    textarea
        padding-top: 15px
        min-height: 223px
    
    .Contact_Container
        +size(90%, auto)
        max-width: $max_Container_Width + 26px
        margin: 70px auto 95px
        .formGroup
            max-width: 1100px
            margin: 0 auto
            .title
                p.contact_Text
                    margin-top: 30px
            form.contactForm
                margin-top: 50px
                .form_input
                    display: flex
                    justify-content: space-between
                    margin-bottom: 50px
                    &:last-child
                        // margin-bottom: 0
                    p.from_inputTitle
                        width: 15%
                        margin-right: 15px
                    .inputBlock
                        +size(85%, 60px)
                        position: relative
                        input, #contact_SelectClass, textarea.message_Text
                            +size(100%)
                        &#contact_SelectClass
                            position: relative
                            .select_icon
                                +size(20px)
                                color: #707070
                                position: absolute
                                top: 50%
                                right: 20px
                                transform: translateY(-50%)
                        &.messageArea
                            height: auto
                        .warnningStatus
                            position: absolute
                            left: 0
                            top: 100%

                .form_input.codeForm
                    justify-content: flex-start
                    margin-bottom: 0
                    .code_inputGroup
                        +size(85%, 60px)
                        display: flex
                        .inputBlock
                            width: 300px
                            margin-right: 30px
                            .code_input
                                +size(100%)
                        .code
                            +size(200px, 60px)
                            position: relative
                            img
                                +size(100%)
                            p.codeText
                                position: absolute
                                left: 50%
                                top: 50%
                                transform: translate(-50%, -50%) skew(-39deg, 7deg) scale(1, 1.3) rotate(-0.2rad)
                                pointer-events: none
                                user-select: none // 禁止區塊選取
                            .codeRefresh_icon
                                +size(40px)
                                position: absolute
                                left: calc(100% + 10px)
                                top: 10px
                                cursor: pointer
                                transition: transform 0.3s
                                &:hover
                                    transform: rotate(60deg)
                @include _under_768_width()
                    .form_input
                        flex-direction: column
                        // margin-bottom: 20px
                        p.from_inputTitle, .inputBlock
                            width: 100%
                        p.from_inputTitle
                            margin-right: 0
                            line-height: 2
                    .form_input.codeForm
                        flex-direction: column
                        .code_inputGroup
                            width: 100%
                            justify-content: space-between
                            padding-right: 50px
                @include _under_576_width()
                    .form_input
                        margin-bottom: 20px
                        .inputBlock
                            .warnningStatus
                                line-height: 2
                                left: auto
                                right: 0
                                top: 0
                                transform: translateY(-100%)
                    .form_input.codeForm > .code_inputGroup
                        height: auto
                        flex-direction: column
                        padding-right: 0
                        .code_inputBlock
                            width: 100%
                            margin-right: 0
                @include _under_375_width()
                    .form_input .inputBlock
                        height: 40px
            .button_send
                +size(240px, 69px)
                +text_Setting(font(3), normal, 2.8px, white, 400, 'Noto Sans TC', center)
                border-radius: 35px
                background-color: #292929
                margin: 80px auto 0
                display: block
                @include _under_576_width()
                    +size(200px, 60px)
                    font-size: 16px
                @include _under_375_width()
                    +size(185px, 55px)
                    font-size: 14px

        .contact_Card_Group
            margin-top: 135px
            .title
                +flex_Center_Setting
                flex-direction: column
                h3.contact_H3
                    margin-top: 31px
                    border-bottom: 3px solid #a5a4a4
                    padding-bottom: 16px
            .card_Group
                display: flex
                justify-content: space-between
                margin-top: 50px
                .card
                    +size(49%, auto)
                    border-radius: 16px
                    padding: 15px
                    .contactInformation
                        +size(100%)
                        display: flex
                        justify-content: space-between
                        padding: 6% 9%
                        background-color: #fff
                        border-radius: 16px
                        .icon
                            width: 25%
                            max-width: 80px
                            display: flex
                            flex-direction: column
                            justify-content: space-between
                            align-self: center
                            position: relative
                            img
                                +size(100%, auto)
                            h4.contact_H4
                                width: 100%
                                margin-top: 23px
                        .information
                            width: 70%
                            display: flex
                            flex-direction: column
                            justify-content: space-between
                .card:nth-child(1)
                    background-image: linear-gradient(289deg, #5ab8eb -13%, #865fcd 112%)
                    h4.contact_H4, h5.contact_H5
                        color: $title1_color
                .card:nth-child(2)
                    background-image: linear-gradient(109deg, #5ab8eb -13%, #865fcd 112%)
                    h4.contact_H4, h5.contact_H5
                        color: $title2_color
            @include _under_992_width()
                .title h3.contact_H3
                    white-space: pre-line
                    line-height: 2
                    border-bottom: 0px solid transparent
                    margin-top: 20px
                    padding-bottom: 0
                .card_Group > .card .information 
                    .contact_H5
                        margin-bottom: 20px
                        &:last-child
                            margin-bottom: 0
                .card_Group
                    flex-direction: column
                    .card
                        width: 100%
                        padding: 10px
                    .card:last-child
                        margin-top: 40px
            @include _under_768_width()
                .card_Group
                    .card .contactInformation
                        .icon
                            img
                                padding: 0 10px
            @include _under_375_width()
                margin-top: 100px
                .card_Group .card
                    padding: 5px
                    .contactInformation
                        flex-direction: column
                        padding: 20px 10px
                        .icon
                            width: 100%
                        .information
                            width: 100%
                            margin-top: 20px
        .button_home > a
            background-image: linear-gradient(110deg, #865fcd -5%, #5ab8eb 91%)
            margin: 113px auto 0

// ==== 英文版CSS ====
.en 
    // 繼承tw css
    @extend .tw
.en #Contact
    *
        // border: 1px solid #000
    h2.contact_H2
        font-size: 32px
        letter-spacing: 1px
        @include _under_992_width()
            font-size: 26px
        @include _under_768_width()
            font-size: 22px
        @include _under_576_width()
            font-size: 18px
    h3.contact_H3
        font-size: 28px
        letter-spacing: 1px
        @include _under_992_width()
            font-size: 24px
        @include _under_768_width()
            font-size: 20px
        @include _under_576_width()
            font-size: 16px
    h4.contact_H4, h5.contact_H5
        font-size: 18px
        letter-spacing: 1px
        line-height: 1.3
        @include _under_768_width()
            font-size: 16px
        @include _under_576_width()
            font-size: 14px
    p.contact_Text
        font-size: 22px
        @include _under_992_width()
            font-size: 18px
        @include _under_768_width()
            font-size: 16px
        @include _under_576_width()
            font-size: 14px
    p.from_inputTitle
        font-size: 20px
        line-height: 1.2
        @include _under_992_width()
            font-size: 18px
        @include _under_768_width()
            font-size: 16px
        @include _under_576_width()
            font-size: 14px
    input, select, textarea
        font-size: 20px
        @include _under_992_width()
            font-size: 18px
        @include _under_768_width()
            font-size: 16px
        @include _under_576_width()
            font-size: 14px
    span.warnningStatus
        letter-spacing: normal

    .Contact_Container
        .formGroup
            form.contactForm .form_input .inputBlock
                @include _under_768_width()
                    height: 50px
        .contact_Card_Group
            @include _under_768_width()
                margin-top: 80px
</style>        