import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '../store'
import { locale as tw } from '@/languages/i18n/tw.js'
import { locale as en } from '@/languages/i18n/en.js'
Vue.use(VueI18n)
let messages = {}
messages = { ...messages, tw, en}

// 判斷瀏覽器語言，作為預設語言，如果全部都不符合，預設繁體中文
const browserLanguageCode = window.navigator.language.toLowerCase()
const browser_DefaultLanguage = () => {
    switch(browserLanguageCode){
    case "en":
    case "en-us":
        localStorage.setItem('language', 'en')
        store.commit('changeLanguage_mutationsVuex', 'en')
        return "en"
    default:
        localStorage.setItem('language', 'tw')
        store.commit('changeLanguage_mutationsVuex', 'tw')
        return "tw"
    }
}

const localLanguage = () => {
    switch(localStorage.getItem('language')){
        case "en":
            store.commit('changeLanguage_mutationsVuex', 'en')
            return "en"
        case "tw":
            store.commit('changeLanguage_mutationsVuex', 'tw')
            return "tw"
        default:
            browser_DefaultLanguage()
    }
}

// 從localStorage本地端抓取language的資料，若null 則抓瀏覽器預設語言，再沒有則為繁中版
const i18n = new VueI18n({
    locale: localLanguage(),
    messages,
    silentTranslationWarn: false   //清除有關vue-i18n的警告
})
export default i18n