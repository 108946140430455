<template lang="pug">
#mailLoadingAnimation(:class="{ isDisplay: isMailing }")
  .mailLoading
    .letter-image
      .animated-mail
        .back-fold
        .letter
          .letter-border
          .letter-title
          .letter-context
          .letter-stamp
            .letter-stamp-inner
        .top-fold
        .body
        .left-fold
</template>

<script>
export default {
  props: {
    isMailing: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="sass">
@import "@/css/my_Style.sass"
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap')

$mainColor: $theme_color

#mailLoadingAnimation
  +size(100%)
  position: fixed
  left: 0
  top: 0
  transition: opacity 0.3s
  opacity: 0
  z-index: -1
  &.isDisplay
    transition: opacity 0.3s
    opacity: 1
    z-index: 1031

// 寄信時的動畫
.mailLoading
  +size(100%)
  +flex_Center_Setting()
  background-color: rgba(white, 0.6)
  .letter-image
    +size(200px)

    .animated-mail
      +size(200px, 150px)
      position: absolute
      transition: 0.4s
      animation: mail1 1s 0s infinite linear alternate
      @keyframes mail1
        from
          transform: translateY(0px)
        to
          transform: translateY(50px)
      

      .back-fold
        position: absolute
        bottom: 0
        width: 200px
        height: 100px
        background: $mainColor
        z-index: 0
      .letter
        @keyframes mail3
          from
            height: 100px
          to
            height: 180px
        left: 20px
        bottom: 0px
        position: absolute
        width: 160px
        height: 60px
        background: white
        z-index: 1
        overflow: hidden
        transition: .4s .2s
        animation: mail3 1s 1s infinite linear alternate

        .letter-border
          +size(100%, 10px)
          background: repeating-linear-gradient(-45deg,$mainColor,$mainColor 8px,transparent 8px,transparent 18px)
        .letter-title
          margin-top: 10px
          margin-left: 5px
          height: 10px
          width: 40%
          background: $mainColor
        .letter-context
          margin-top: 10px
          margin-left: 5px
          height: 10px
          width: 20%
          background: $mainColor
        .letter-stamp
          margin-top: 30px
          margin-left: 120px
          border-radius: 100%
          height: 30px
          width: 30px
          background: $mainColor
          opacity: 0.3

      .top-fold
        position: absolute
        top: 50px
        width: 0
        height: 0
        border-style: solid
        border-width: 50px 100px 0 100px
        transform-origin: 50% 0%
        transition: transform .4s .4s, z-index .2s .4s
        border-color: rgba($mainColor, 0.6) transparent transparent transparent
        z-index: 2
      .body
        position: absolute
        width: 0
        bottom: 0
        border-style: solid
        border-width: 0 0 100px 200px
        border-color: transparent transparent $mainColor transparent
        z-index: 2
      .left-fold
        position: absolute
        bottom: 0
        width: 0
        height: 0
        border-style: solid
        border-width: 50px 0 50px 100px
        border-color: transparent transparent transparent $mainColor
        z-index: 2
</style>