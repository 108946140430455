<template lang="pug">
main#Products
    pageTop(:pageTitle="$t('__MAIN_TITLE.Products')" imgSrc="Products/products_pageTop_desktop.jpg" imgSrc_Mobile="Products/products_page top img_mobile.jpg")
    .introduce1_Container
        p {{$t('__PRODUCTS.products_Page.page_Introduce')}}
    .products_Container
        .topBlock
            .buttonGroup
                .button_backGround(:class="coating_isActive ? 'bg_coating' : 'bg_coil'")
                    button(:class="{button_isDisplay: coil_isActive}" @click="changeButton('捲材類')") {{$t('__TYPE.coil')}}
                    button(:class="{button_isDisplay: coating_isActive}" @click="changeButton('塗料類')") {{$t('__TYPE.coating')}}
            .titleGroup
                //- 捲材類
                .title.coilTitle(v-show="coil_isActive")
                    .iconCircle
                        .innerCircle
                            img.icon(src="@/assets/icon01.svg")
                    p {{$t('__PRODUCTS.products_Page.coil_Introduce')}}
                //- 塗料類
                .title.coatingTitle(v-show="coating_isActive")
                    .iconCircle
                        .innerCircle
                            img.icon(src="@/assets/icon02.svg")
                    p {{$t('__PRODUCTS.products_Page.coating_Introduce')}}
    
    .card_Container(:class="{coil: coil_isActive, coating: coating_isActive}")
        .cardGroup.coil_cardGroup(v-show="coil_isActive")
            .leftColumn
                //- 強韌防水膜
                router-link.card(:to="{name: products_List_Link[0].routerName, params: {type: 'coil'}}")
                    h2.cardTitle {{products_List_Link[0].productName}}
                    img.cardImg.imgDesktop(src="@/assets/Products/products_normal.jpg")
                    img.cardImg.imgMobile(src="@/assets/Products/products_normal_mobile.jpg")
                    b-icon.icon(icon="chevron-right")
            .rightColumn
                .column
                    //- 泳池裡襯
                    router-link.card(:to="{name: products_List_Link[4].routerName, params: {type: 'coil'}}")
                        h2.cardTitle {{products_List_Link[4].productName}}
                        img.cardImg.imgDesktop(src="@/assets/Products/products_pool.jpg")
                        img.cardImg.imgMobile(src="@/assets/Products/products_pool_mobile.jpg")
                        b-icon.icon(icon="chevron-right")
                    //- 紅泥膠布
                    router-link.card(:to="{name: products_List_Link[3].routerName, params: {type: 'coil'}}")
                        h2.cardTitle {{products_List_Link[3].productName}}
                        img.cardImg.imgDesktop(src="@/assets/Products/products_redmud.jpg")
                        img.cardImg.imgMobile(src="@/assets/Products/products_redmud_mobile.jpg")
                        b-icon.icon(icon="chevron-right")
                .column
                    //- 隧道防水膜
                    router-link.card(:to="{name: products_List_Link[2].routerName, params: {type: 'coil'}}")
                        h2.cardTitle {{products_List_Link[2].productName}}
                        img.cardImg.imgDesktop(src="@/assets/Products/products_tunnel.jpg")
                        img.cardImg.imgMobile(src="@/assets/Products/products_tunnel_mobile.jpg")
                        b-icon.icon(icon="chevron-right")
                    //- 屋頂防水膜
                    router-link.card(:to="{name: products_List_Link[1].routerName, params: {type: 'coil'}}")
                        h2.cardTitle {{products_List_Link[1].productName}}
                        img.cardImg.imgDesktop(src="@/assets/Products/products_roof.jpg")
                        img.cardImg.imgMobile(src="@/assets/Products/products_roof_mobile.jpg")
                        b-icon.icon(icon="chevron-right")
        .cardGroup.coating_cardGroup(v-show="coating_isActive")
            //- PU防水材
            router-link.card(:to="{name: products_List_Link[5].routerName, params: {type: 'coating'}}")
                h2.cardTitle {{products_List_Link[5].productName}}
                img.cardImg.imgDesktop(src="@/assets/Products/products_pu.jpg")
                img.cardImg.imgMobile(src="@/assets/Products/products_pu_mobile.jpg")
                b-icon.icon(icon="chevron-right")
            //- 酷熱漆
            router-link.card(:to="{name: products_List_Link[6].routerName, params: {type: 'coating'}}")
                h2.cardTitle {{products_List_Link[6].productName}}
                img.cardImg.imgDesktop(src="@/assets/Products/products_cooler.jpg")
                img.cardImg.imgMobile(src="@/assets/Products/products_cooler_mobile.jpg")
                b-icon.icon(icon="chevron-right")
    
    //- 綠色能源產品專區
    .green_Container
        img.greenBackground(src="@/assets/Products/products_green_enegry.jpg")
        .greenBody
            .title
                h1 {{$t('__PRODUCTS.products_Page.green_Energy.title')}}
                p {{$t('__PRODUCTS.products_Page.green_Energy.Block_Text')}}
            swiper.greenCardSwiper(:options="greenCardSwiper")
                //- PU防水材
                swiper-slide.greenCardSlider
                    router-link.cardBody(:to="{name: products_List_Link[5].routerName, params: {type: 'coating'}}")
                        .top
                            img.cardImg(src="@/assets/Products/green products card_01.jpg")
                        .bottom
                            h2 {{products_List_Link[5].productName}}
                            p {{$t('__PRODUCTS.products_Page.green_Energy.card1_Text')}}
                //- 酷熱漆
                swiper-slide.greenCardSlider
                    router-link.cardBody(:to="{name: products_List_Link[6].routerName, params: {type: 'coating'}}")
                        .top
                            img.cardImg(src="@/assets/Products/green products card_02.jpg")
                        .bottom
                            h2 {{products_List_Link[6].productName}}
                            p {{$t('__PRODUCTS.products_Page.green_Energy.card2_Text')}}
                //- 強韌防水膜
                swiper-slide.greenCardSlider
                    router-link.cardBody(:to="{name: products_List_Link[0].routerName, params: {type: 'coil'}}")
                        .top
                            img.cardImg(src="@/assets/Products/green products card_03.jpg")
                        .bottom
                            h2 {{products_List_Link[0].productName}}
                            p {{$t('__PRODUCTS.products_Page.green_Energy.card3_Text')}}
                //- 屋頂防水膜
                swiper-slide.greenCardSlider
                    router-link.cardBody(:to="{name: products_List_Link[1].routerName, params: {type: 'coil'}}")
                        .top
                            img.cardImg(src="@/assets/Products/green products card_04.jpg")
                        .bottom
                            h2 {{products_List_Link[1].productName}}
                            p {{$t('__PRODUCTS.products_Page.green_Energy.card4_Text')}}
                .greenSwiper-pagination(slot="pagination")
                .swiper-button-prev.greenPrev(slot='button-prev')
                    b-icon.icon(icon="chevron-left")
                .swiper-button-next.greenNext(slot='button-next')
                    b-icon.icon(icon="chevron-right")

    .search_Container
        .top
            h1 {{$t('__PRODUCTS.products_Page.search_Title1')}}
            #search_Products
                select(name="find_Products" v-model="productID_isActive_index_computed")
                    option(v-for="(search, search_index) of search_Products_Data" 
                            :value="search_index") {{search.selectName}}
                b-icon.select_icon(icon="caret-down-fill")
        .bottom
            h3 {{$t('__PRODUCTS.products_Page.search_Title2')}}：
            .suggest_List
                router-link.suggest_Word(v-for="(product, product_index) of products_List_Link" :key="product.productName" 
                            :class=" [product.type, {isDisplay: search_Products_Data[productID_isActive_index].display_suggestProduct_Array.includes(product_index)} ] "
                            :to="{name: product.routerName}" ) {{product.productName}}
                            //- v-show="search_Products_Data[productID_isActive_index].display_suggestProduct_Array.includes(product.productName)"

</template>

<script>
// import Vue from 'vue'
import pageTop from "@/components/pageTop.vue"
// import { mapGetters } from 'vuex'

export default {
    metaInfo() {
        return{
            title: `${this.$t('__MAIN_TITLE.Products')}-`,
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: this.$t('__PRODUCTS.products_Page.description')
                },
                // Open Graph / Facebook
                { property: 'og:url', content: `${this.$t('__MAIN.domainName_URL')}/Products-${this.$route.params.type}` },
                { property: 'og:description', content: this.$t('__PRODUCTS.products_Page.description') },

                // Twitter
                { property: 'twitter:url', content: `${this.$t('__MAIN.domainName_URL')}/Products-${this.$route.params.type}` },
                { property: 'twitter:description', content: this.$t('__PRODUCTS.products_Page.description') },
            ]
        }
    },

    components: {
        pageTop,
    },

    data() {
        return{

            greenCardSwiper: {
                effect: 'slide',
                spaceBetween: 20,
                slidesPerGroup: 4,
                slidesPerView: 4,
                breakpoints: {
                    992: { slidesPerGroup: 3, slidesPerView: 3 },
                    768: { slidesPerGroup: 2, slidesPerView: 2 },
                    576: { slidesPerGroup: 1, slidesPerView: 1 }
                },
                // effect: 'fade',
                // fadeEffect: { crossFade: true }, // 解決淡入淡出重疊問題
                // paginationClickable: false,
                pagination: {
                    el: '.greenSwiper-pagination',
                    type: 'fraction',
                    clickable: true   // 滑鼠可點擊導覽點點
                },
                navigation: {
                    prevEl: '.swiper-button-prev',
                    nextEl: '.swiper-button-next'
                },
                // pagination: '.swiper-pagination',
                // autoplay: {delay: 5000},   // 輪播
                speed: 500,   // 切換速度
                loop: false,
                // slidesPerView: 1,
                // centeredSlides: true,
                // mousewheel: false,
                // grabCursor: true,  //移入後手掌形狀
                // lazyLoading: true,
                // slideShadows : true,
                // slideToClickedSlide: false
                // on: {
                //     slideChange: (() => {
                //         this.img_Carousel_Control_methods()
                //         this.BannerZoomin_Control_methods()
                //     })
                // }
            },

            coil_isActive: true,
            coating_isActive: false,

            products_List_Link: [
                // 0: 強韌防水膜
                {   productName: this.$t('__PRODUCTS.tough_Waterproof.title'), type: "coil", 
                    routerName: `tough_Waterproof${this.$store.getters.computed_routeName_Lang_addBaseline_from_Vuex}` },
                // 1: 屋頂防水膜
                {   productName: this.$t('__PRODUCTS.roof_Waterproof.title'), type: "coil", 
                    routerName: `roof_Waterproof${this.$store.getters.computed_routeName_Lang_addBaseline_from_Vuex}` },
                // 2: 隧道防水膜
                {   productName: this.$t('__PRODUCTS.tunnel_Waterproof.title'), type: "coil", 
                    routerName: `tunnel_Waterproof${this.$store.getters.computed_routeName_Lang_addBaseline_from_Vuex}` },
                // 3: 紅泥膠布
                {   productName: this.$t('__PRODUCTS.red_Tape.title'), type: "coil", 
                    routerName: `red_Tape${this.$store.getters.computed_routeName_Lang_addBaseline_from_Vuex}` },
                // 4: 泳池裡襯
                {   productName: this.$t('__PRODUCTS.pool.title'), type: "coil", 
                    routerName: `pool${this.$store.getters.computed_routeName_Lang_addBaseline_from_Vuex}` },
                // 5: PU防水材
                {   productName: this.$t('__PRODUCTS.PU_Waterproof.title'), type: "coating", 
                    routerName: `PU_Waterproof${this.$store.getters.computed_routeName_Lang_addBaseline_from_Vuex}` },
                // 6: 酷熱漆
                {   productName: this.$t('__PRODUCTS.Cooler_Paint.title'), type: "coating", 
                    routerName: `Cooler_Paint${this.$store.getters.computed_routeName_Lang_addBaseline_from_Vuex}` },
            ],

            search_Products_Data: [
                {   // 建築用
                    selectName: this.$t('__PRODUCTS.products_Page.search_optionList[0]'),
                    display_suggestProduct_Array: [0, 1, 5, 6]  // ["強韌防水膜", "屋頂防水膜", "PU防水材", "酷熱漆"]
                },
                {   // 彩鋼用
                    selectName: this.$t('__PRODUCTS.products_Page.search_optionList[1]'),
                    display_suggestProduct_Array: [6]  // ["酷熱漆"]
                },
                {   // 工業用
                    selectName: this.$t('__PRODUCTS.products_Page.search_optionList[2]'),
                    display_suggestProduct_Array: [0, 6]  // ["強韌防水膜", "酷熱漆"]
                },
                {   // 農業用
                    selectName: this.$t('__PRODUCTS.products_Page.search_optionList[3]'),
                    display_suggestProduct_Array: [0, 3]  // ["強韌防水膜", "紅泥膠布"]
                },
                {   // 交通工程
                    selectName: this.$t('__PRODUCTS.products_Page.search_optionList[4]'),
                    display_suggestProduct_Array: [2]  // ["隧道防水膜"]
                },
                {   // 土木工程
                    selectName: this.$t('__PRODUCTS.products_Page.search_optionList[5]'),
                    display_suggestProduct_Array: [0]  // ["強韌防水膜"]
                },
                {   // 休閒生活
                    selectName: this.$t('__PRODUCTS.products_Page.search_optionList[6]'),
                    display_suggestProduct_Array: [4]  // ["泳池裡襯"]
                },
            ],

            productID_isActive_index: 0,    // v-model選取的id值
        }
    },

    computed: {
        // 從Vuex拿getters
        // ...mapGetters(['computed_routeName_Lang_from_Vuex', 'computed_routeName_Lang_addBaseline_from_Vuex']),
        
        productID_isActive_index_computed: {
            get() {
                return this.productID_isActive_index
            },
            set(input_index) {
                this.productID_isActive_index = input_index
            }
        }
    },

    methods: {
        getImgUrl(pic) {
            return require('@/assets/' + pic)
        },
        changeButton(area) {
            if(area === '捲材類') {
                this.$router.push({params: { type: 'coil' }})
            }
            if(area === '塗料類') {
                this.$router.push({params: { type: 'coating' }})
            }
        },

        changeTo_coil() {
            this.coil_isActive = true
            this.coating_isActive = false
        },
        changeTo_coating() {
            this.coil_isActive = false
            this.coating_isActive = true
        }
    },

    // 偵聽router切換
    watch: {
        $route(to) {
            const productType = to.params.type
            if(productType === 'coil'){
                this.changeTo_coil()
            }
            if(productType === 'coating'){
                this.changeTo_coating()
            }
        },
    },
    
    created() {
        // 進頁面前先判斷當下網址在哪個產品
        const productType = this.$router.currentRoute.params.type
        return productType === 'coating' ? this.changeTo_coating() : this.changeTo_coil()
    }
}
</script>

<style lang="sass">
@import "@/css/my_Style.sass"
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap');

.tw #Products
    *
        // border: 1px solid #000
    .introduce1_Container
        +size(80%, auto)
        max-width: $max_Container_Width
        border-radius: 11px
        margin: 75px auto
        padding: 10px 14px
        background-image: linear-gradient(to left, #5ab8eb -18%, #557fe8 55%, #865fcd 129%)
        opacity: 0.8
        @include _under_576_width()
            padding: 14px
        p
            +text_Setting(calc(14px + 0.4vw), 1.64, 0.22px, #fff, 400, "Noto Sans TC", center)
            padding: 2.8% 5%
            border: 1px solid #fff
            border-radius: 5px
            @include _Upper_1920_width()
                font-size: 22px

    .products_Container
        +size(80%, auto)
        max-width: $max_Container_Width
        margin: 0 auto
        .topBlock
            .buttonGroup
                .button_backGround
                    +flex_Center_Setting
                    width: 100%
                    max-width: 800px
                    margin: 0 auto
                    border-radius: 84px
                    background-color: #E4E4E4
                    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16)
                    position: relative
                    z-index: 1
                    &::before
                        +pseudoBlock(53%, 100%, yellow)
                        top: 0
                        border-radius: 84px
                        transition: 0.3s
                        z-index: -1
                .bg_coil::before
                    left: 0
                    background-color: $title1_color
                .bg_coating::before
                    left: 47%
                    background-color: $title2_color

                button
                    +size(47%, auto)
                    +text_Setting(calc(16px + 0.7vw), 1, 3.2px, #A1A3A4, 500, "Noto Sans TC", center)
                    padding: 25px 0
                    border-radius: 84px
                    transition: 0.5s
                    @include _Upper_1920_width()
                        font-size: 32px
                .button_isDisplay
                    width: 53%
                    color: #fff
                    pointer-events: none

            .titleGroup
                *
                    // border: 1px solid #000
                .title
                    +flex_Center_Setting
                    margin-top: 58px
                    animation: fadeIn 0.4s
                    .iconCircle
                        +size(180px)
                        +flex_Center_Setting
                        min-width: 180px
                        border-radius: 50%
                        margin-right: 33px
                        // flex: 2
                        .innerCircle
                            +size(90%)
                            // padding: 40px
                            padding: 22%
                            border-radius: 50%
                            img
                                +size(100%)
                    p
                        +size(75%, auto)
                        +text_Setting(calc(14px + 0.4vw), 1.8, 0.2px, #000, 400, "Noto Sans TC", left)
                        // flex: 8
                        @include _Upper_1920_width()
                            font-size: 20px
                .coilTitle
                    .iconCircle
                        background-color: rgba(#84CEEB, 0.53)
                        .innerCircle
                            background-color: $title1_color
                    p
                        color: #2B89BC
                .coatingTitle
                    .iconCircle
                        background-color: rgba(#C1C8E4, 0.53)
                        .innerCircle
                            background-color: $title2_color
                    p
                        color: #865FCD
        @include _under_768_width()
            .topBlock
                .titleGroup > .title
                    margin-top: 40px
                    .iconCircle
                        +size(130px)
                        min-width: 130px
        @include _under_576_width()
            .topBlock 
                .buttonGroup .button_backGround
                    button
                        padding: 20px 0
                .titleGroup > .title
                    flex-direction: column
                    .iconCircle
                        margin: 0
                    p
                        width: 100%
                        margin-top: 25px

    .card_Container.coil
        background-color: #EDF6FA
    .card_Container.coating
        background-color: #ebe6f5
    .card_Container
        margin-top: 50px
        padding: 100px 0
        .cardGroup
            +size(90%, auto)
            display: flex
            justify-content: space-between
            max-width: 1400px
            margin: 0 auto
            animation: fadeIn 0.4s
            .card
                width: 100%
                border-radius: 12px
                box-shadow: 6px 6px 10px #00000021
                overflow: hidden
                .cardTitle
                    +text_Setting(calc(14px + 0.8vw), 1, 2.2px, #fff, 400, "Noto Sans TC", center)
                    width: 100%
                    position: absolute
                    left: 0
                    top: 50%
                    transform: translateY(-50%)
                    transition: 0.4s
                    @include _Upper_1920_width()
                        font-size: 28px
                .cardImg
                    +size(100%)
                .imgMobile
                    display: none
                .icon
                    +size(35px)
                    position: absolute
                    right: 10px
                    top: 50%
                    transform: translateY(-50%)
                    color: #fff
                &::before
                    +pseudoBlock(100%, 100%, #000)
                    opacity: 0.25
                    transition: 0.2s
                &:hover
                    &::before
                        opacity: 0
                    .cardTitle
                        top: -50%

            .leftColumn
                width: calc(100% / 3 - 1%)
            .rightColumn
                +flex_Center_Setting(space-between)
                width: calc(100% / 3 * 2 - 0.5%)
                .column
                    +flex_Center_Setting(space-between)
                    flex-direction: column
                    width: calc(100% / 2 - 1%)
                    height: 100%
        
        .coating_cardGroup .card
            width: 49%

        @include _under_768_width()
            padding: 70px 0
            .cardGroup
                width: 80%
                flex-direction: column
                .card
                    width: 100%
                    .imgDesktop
                        display: none
                    .imgMobile
                        display: block
                .leftColumn, .rightColumn
                    width: 100%
                    .column > .card
                        margin-top: 33px
                .rightColumn
                    flex-direction: column
                    .column
                        width: 100%
            .coating_cardGroup > .card:nth-child(1) ~ .card
                margin-top: 33px

        @include _under_576_width()
            padding: 50px 0

    .green_Container
        +size(100%, auto)
        +flex_Center_Setting
        position: relative
        padding: 80px 0 45px
        margin-top: 125px
        img.greenBackground
            +size(100%)
            min-width: 1850px
            position: absolute
            left: 0
            top: 0
            z-index: -1
            // opacity: 0.9
        .greenBody
            +size(90%, auto)
            +flex_Center_Setting
            flex-direction: column
            max-width: $max_Container_Width
            .title
                width: 90%
                max-width: 900px
                h1
                    +text_Setting(calc(22px + 0.9vw), 1, 3.8px, #fff, 500, "Noto Sans TC", center)
                    @include _Upper_1920_width()
                        font-size: 38px
                p
                    +text_Setting(calc(14px + 0.3vw), 1.6, 0.2px,#F5F5F5, 400, "Noto Sans TC", center)
                    text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.35)
                    margin-top: 15px
                    @include _Upper_1920_width()
                        font-size: 20px
            .greenCardSwiper
                width: 100%
                padding: 0 60px 75px
                margin-top: 60px
                .greenCardSlider
                    height: auto
                    background-color: #fff
                    border-radius: 12px
                    .top
                        padding: 13px 13px 0
                        .cardImg
                            +size(100%)
                            border-radius: 12px
                    .bottom
                        padding: 0 13px 30px 26px
                        margin-top: 25px
                        h2
                            +text_Setting(calc(16px + 0.3vw), 1, 0.29px,#46444a, 400, "Noto Sans TC", left)
                            @include _Upper_1920_width()
                                font-size: 22px
                        p
                            +text_Setting(calc(14px + 0.2vw), 1.56, 0.29px,#8e8c8c, 400, "Noto Sans TC", left)
                            margin-top: 13px
                            @include _Upper_1920_width()
                                font-size: 18px
                .greenSwiper-pagination
                    font-size: font(5)
                    text-align: center
                    position: absolute
                    bottom: 0
                    color: #fff
                    margin-top: 38px
                .greenPrev, .greenNext
                    +size(50px)
                    margin-top: 0
                    top: calc(50% - 38px)
                    transform: translateY(-50%)
                    color: #fff
                    &::after
                        display: none
                    .icon
                        +size(100%)
                .greenPrev
                    left: 0
                .greenNext
                    right: 0
                @include _under_992_width()
                    .greenPrev, .greenNext
                        display: none

    .search_Container
        // *
            // border: 1px solid #000
        +size(80%, auto)
        +flex_Center_Setting
        flex-direction: column
        max-width: $max_Container_Width
        margin: 120px auto
        .top
            +size(100%, auto)
            max-width: 600px
            h1
                +text_Setting(calc(22px + 0.9vw), 1, 3.8px, #292929, 500, "Noto Sans TC", center)
                @include _Upper_1920_width()
                    font-size: 38px
            #search_Products
                margin-top: 25px
                position: relative
                select
                    +size(100%, auto)
                    +text_Setting(calc(14px + 0.4vw), 1.5, 1.1px,#5A5656, 400, "Noto Sans TC", left)
                    padding: 10px 35px
                    border-radius: 8px
                    appearance: none    // select預設下拉的icon取消
                    &::-ms-expand   // select預設下拉的icon取消(for IE)
                        display: none
                    @include _Upper_1920_width()
                        font-size: 22px
                    option
                        +text_Setting(calc(14px + 0.4vw), 1, 1.1px,#5A5656, 400, "Noto Sans TC", left)
                        @include _Upper_1920_width()
                            font-size: 22px
                .select_icon
                    +size(20px)
                    color: #707070
                    top: 50%
                    right: 20px
                    transform: translateY(-50%)
                    position: absolute
        .bottom
            +size(100%, auto)
            display: flex
            flex-direction: column
            max-width: 600px
            margin-top: 60px
            h3
                +text_Setting(calc(14px + 0.5vw), 1, 1.1px,#4B4B4B, 400, "Noto Sans TC", left)
                @include _Upper_1920_width()
                    font-size: 22px
            .suggest_List
                +size(100%, auto)
                +flex_Center_Setting(flex-start)
                flex-wrap: wrap
                margin-top: 25px
                min-height: 82px

                .coil
                    border: 4px solid $title1_color
                    background-color: $title1_color
                .coating
                    border: 4px solid $title2_color
                    background-color: $title2_color
                .suggest_Word
                    +text_Setting(22px, 1, 1.1px,#fff, 400, "Noto Sans TC", left)
                    border-radius: 36px
                    box-shadow: 3px 3px 6px #0000004A
                    transition: 0.5s
                    overflow: hidden
                    // =====預設不顯示時套用=====
                    +size(0)
                    padding: 0
                    margin-right: 0
                    margin-bottom: 0
                    border-width: 0
                    opacity: 0
                    // =====預設不顯示時套用=====
                    &:hover
                        color: #4B4B4B
                        background-color: transparent
                    @include _under_992_width()
                        font-size: 18px
                    @include _under_576_width()
                        font-size: 14px
                
                // =====顯示時套用=====
                .isDisplay
                    +size(auto)
                    padding: 15px 25px
                    margin-right: 15px
                    margin-bottom: 20px
                    border-width: 4px
                    opacity: 1

        @include _under_576_width()
            margin: 60px auto
            .bottom
                .suggest_List
                    .isDisplay
                        padding: 10px 15px

// ==== 英文版CSS ====
.en 
    // 繼承tw css
    @extend .tw
.en #Products
    // *
    //     border: 1px solid #000
    .card_Container
        .cardGroup
            .card
                .cardTitle
                    font-size: calc(14px + 0.4vw)
                    line-height: 1.5
                    letter-spacing: 1.5px
                    padding: 0 5%
                    @include _Upper_1920_width()
                        font-size: 20px
                .icon
                    display: none
    
    .green_Container
        .greenBody
            .title
                h1, p
                    letter-spacing: normal
            .greenCardSwiper .greenCardSlider
                .top
                    padding: 10px 10px 0
                .bottom
                    padding: 0 13px 20px
                    margin-top: 20px
                    h2
                        letter-spacing: normal
                        font-size: calc(14px + 0.3vw)
                    p
                        letter-spacing: normal
                        line-height: 1.3
    .search_Container
        justify-content: flex-start
        .top
            #search_Products select
        .bottom
            .suggest_List
                flex-wrap: nowrap
                flex-direction: column
                align-items: flex-start
</style>        