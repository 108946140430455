<template lang="pug">
#Product_Template(:class="colorType")
    .intro_Container
        .intro1
            .title
                h1.productTemplate_H1 {{importProduct_Data.introduce.title}}
                p.intro1_Text {{importProduct_Data.introduce.introText}}
            swiper.cardSwiper(:options='cardSwiper')
                swiper-slide.cardSlide(v-for="swiperImg of importProduct_Data.introduce.swiper_Img" :key="swiperImg")
                    img(:src="getImgUrl(swiperImg)", :alt="importProduct_Data.introduce.title")
                .cardSwiper-pagination(slot="pagination")
                .swiper-button-prev(slot='button-prev')
                    b-icon.icon(icon="chevron-left")
                .swiper-button-next(slot='button-next')
                    b-icon.icon(icon="chevron-right")
        //- 產品特性
        .intro2
            .title
                h2.productTemplate_H2 {{$t('__PRODUCTS.__TEMPLATE.feature_Title')}}
            .featureList
                .list(v-for="feature of importProduct_Data.introduce.featureList")
                    b-icon(icon="check2")
                    p.featureText {{feature}}
    //- 產品優勢
    .advantage_Container
        .title
            h2.productTemplate_H2 {{$t('__PRODUCTS.__TEMPLATE.advantage_title')}}
            p.advantageText {{importProduct_Data.advantageText}}
    //- 產品應用
    .productApp_Container
        .productApp
            .title
                h2.productTemplate_H2 {{$t('__PRODUCTS.__TEMPLATE.application_Title')}}
                p.appText {{importProduct_Data.productApp.appText}}
            swiper.circle_Group_Swiper(:options="circle_Group_Swiper")
                swiper-slide.circle(v-for="(circleImg, index) of importProduct_Data.productApp.circle_Img" :key="index")
                    img(:src="getImgUrl(circleImg.imgSrc)", :alt="circleImg.altText")
                .circle_Group_Swiper-pagination(slot="pagination")
            Button_Style.button_case(:btnTitle="$t('__BUTTON_TEXT.case')" linkType="routerName" link="UseCase" arrow="right")
    
    //- 相關影片
    .media_Container(v-if="importProduct_Data.mediaList")
        .mediaTitle_Group
            .title
                h2.productTemplate_H2 {{$t('__PRODUCTS.__TEMPLATE.media_Title')}}
            .mediaList(ref="mediaList" @mousewheel="mediaScroll")
                button.mediaButton(v-for="(media, index) of importProduct_Data.mediaList" 
                                    :class="{media_isActive: index === media_Display_index}"
                                    @click="changeMedia(index)") {{media.mediaTitle}}
        .media_Block
            .media(v-for="(media, index) of importProduct_Data.mediaList")
                .embed-responsive.embed-responsive-16by9(v-if="index === media_Display_index")
                    iframe.embed-responsive-item(:src="media.mediaSrc")

    //- 插入規格表
    h2.productTemplate_H2.specification_Title {{$t('__PRODUCTS.__TEMPLATE.specification')}}
    .slotspecification_Form_Container(ref="scroll_container" @mousewheel="scrollX($event, 'scroll_container')")
        //- 規格表
        slot(name="specification_Form")
    .slotspecification_Form_Container(ref="scroll_container2" @mousewheel="scrollX($event, 'scroll_container2')")
        //- 規格表2
        slot(name="specification_Form2")

    .fileDownload_Container(v-if="importProduct_Data.fileList")
        //- 證書及相關文件
        .title
            h2.productTemplate_H2 {{$t('__PRODUCTS.__TEMPLATE.file_Title')}}
        .fileList
            a.file(v-for="file of importProduct_Data.fileList" :href="`./PDF/${file.fileLink}`", :title="file.fileTitle" target="_blank") {{file.fileTitle}}
                b-icon(icon="download")
    
    Button_Style.button_contact(:btnTitle="$t('__BUTTON_TEXT.getProducts')" linkType="routerName" link="Contact" arrow="right")
</template>

<script>
import pageTop from "@/components/pageTop.vue"
import Button_Style from "@/components/Button_Style.vue"

export default {
    components: {
        pageTop,
        Button_Style
    },

    props: {
        colorType: {
            type: String,
            default: "coil"
        },
        importProduct_Data: Object
    },

    data() {
        return{
            cardSwiper: {
                spaceBetween: 10,
                effect: 'slide',
                pagination: {
                    el: '.cardSwiper-pagination',
                    // type: 'fraction',
                    clickable: true   // 滑鼠可點擊導覽點點
                },
                navigation: {
                    prevEl: '.swiper-button-prev',
                    nextEl: '.swiper-button-next'
                },
                // pagination: '.swiper-pagination',
                // autoplay: {delay: 5000},   // 輪播
                speed: 300,   // 切換速度
                loop: false,
                autoHeight: true
            },

            circle_Group_Swiper: {
                effect: 'slide',
                spaceBetween: 20,
                slidesPerGroup: 4,
                slidesPerView: 4,
                pagination: {
                    el: '.circle_Group_Swiper-pagination',
                    clickable: true   // 滑鼠可點擊導覽點點
                },
                breakpoints: {
                    // 992: { slidesPerGroup: 3, slidesPerView: 3 },
                    768: { slidesPerGroup: 1, slidesPerView: 2 },
                    576: { slidesPerGroup: 1, slidesPerView: 1 }
                },
            },

            media_Display_index: 0
        }
    },

    methods: {
        getImgUrl(pic) {
            return require('@/assets/' + pic)
        },
        scrollX(event, target) {
            event.preventDefault(); // 滑鼠游標停在上面後，垂直的捲動條不作動
            this.$refs[target].scrollLeft += event.deltaY;
        },
        changeMedia(media_ID) {
            // this.importProduct_Data.mediaList.map( (value, index) => {
            //     media_ID === index ? value.isDisplay = true : value.isDisplay = false
            // })
            this.media_Display_index = media_ID
        },
        mediaScroll(event){
            
            // console.log(event);
            event.preventDefault(); // 滑鼠游標停在上面後，垂直的捲動條不作動
            this.$refs["mediaList"].scrollLeft += event.deltaY
        }
    },
}
</script>

<style lang="sass">
@import "@/css/my_Style.sass"
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap');

// .tw #Product_Template.coil
//     .intro_Container
//         .intro1
//             span
//                 background-color: #5ab8eb
//         .intro2
//             .list
//                 .b-icon
//                     color: #6ec0ed
//                     border: 3px solid #6ec0ed
//     .advantage_Container
//         background-color: #5ab8eb

.tw #Product_Template
    h1.productTemplate_H1
        +text_Setting(calc(22px + 1.2vw), 1, 2.35px, #555456, 500, "Noto Sans TC", left)
        @include _Upper_1920_width()
            font-size: 47px
        @include _under_768_width()
            text-align: center
    h2.productTemplate_H2
        +text_Setting(calc(16px + 1.2vw), 1, 1.9px, #000, 500, "Noto Sans TC", left)
        @include _Upper_1920_width()
            font-size: 38px
        @include _under_768_width()
            text-align: center
    p.intro1_Text
        +text_Setting(calc(14px + 0.5vw), 1.67, 1.2px, #555456, 400, "Noto Sans TC", left)
        margin-top: 20px
        @include _Upper_1920_width()
            font-size: 20px
        @include _under_768_width()
            text-align: center
    p.featureText
        +text_Setting(calc(14px + 0.5vw), 1.6, 0.2px, #515354, 400, "Noto Sans TC", left)
        margin-left: 20px
        @include _Upper_1920_width()
            font-size: 24px
    p.advantageText
        +text_Setting(calc(16px + 0.6vw), 1.6, 0.2px, #fff, 400, "Noto Sans TC", left)
        @include _Upper_1920_width()
            font-size: 28px
        @include _under_768_width()
            text-align: center
    p.appText
        +text_Setting(calc(14px + 0.5vw), 1.33, 1.2px, #707070, 400, "Noto Sans TC", center)
        margin-top: 20px
        @include _Upper_1920_width()
            font-size: 20px
    button.mediaButton
        +text_Setting(calc(14px + 0.3vw), 1.33, 1.68px, #8a8787, 500, "Noto Sans TC", center)
        @include _Upper_1920_width()
            font-size: 20px
    
    a.file
        +text_Setting(calc(14px + 0.4vw), 1.35, 1px, #4e4d4d, 300, "Noto Sans TC", left)
        @include _Upper_1920_width()
            font-size: 20px

    .intro_Container
        +size(80%, auto)
        max-width: $max_Container_Width
        margin: 66px auto 100px
        .intro1
            +flex_Center_Setting(space-between)
            .title
                width: 48%
            .cardSwiper
                width: 48%
                margin: 0
                .cardSlide
                    +size(100%, auto)
                    img
                        +size(100%)
                .cardSwiper-pagination
                    +flex_Center_Setting
                    margin-top: 20px
                    span
                        +size(calc(12px + 0.3vw))
                        @include _Upper_1920_width()
                            +size(18px)
                .swiper-button-prev, .swiper-button-next
                    +size(40px, calc(100% - 20px - 18px))   // 高度100%減掉margin-top和pagination點點的height
                    display: flex
                    top: 0
                    color: #fff
                    margin-top: 0
                    &::after
                        display: none
                    .icon
                        +size(100%, auto)
                .swiper-button-prev
                    left: 0
                .swiper-button-next
                    right: 0
        .intro2
            margin-top: 120px
            .title
                border-bottom: 1px solid #707070
                padding-bottom: 18px
            .featureList
                +flex_Center_Setting(space-between)
                flex-wrap: wrap
                margin-top: 30px
                .list 
                    +flex_Center_Setting(flex-start)
                    width: 45%
                    padding: 20px 0
                    .bi-check2
                        +size(45px)
                        border-radius: 50%
                        padding: 5px
        @include _under_768_width()
            margin: 80px auto 80px
            .intro1
                +flex_Center_Setting(space-between)
                flex-direction: column-reverse
                .title
                    width: 100%
                    margin-top: 40px
                .cardSwiper
                    width: 100%
                    margin: 0
            .intro2
                margin-top: 80px
                // .title
                //     border-bottom: 1px solid #707070
                //     padding-bottom: 18px
                .featureList
                    align-items: flex-start
                    flex-wrap: nowrap
                    flex-direction: column
                    .list
                        width: 100%
        @include _under_576_width()
            .intro1 .cardSwiper
                .swiper-button-prev, .swiper-button-next
                    display: none

    .advantage_Container
        .title
            +size(80%, auto)
            +flex_Center_Setting(space-between)
            max-width: $max_Container_Width
            margin: 0 auto
            padding: 80px 0
            h2.productTemplate_H2
                width: auto
                color: #fff
            p.advantageText
                width: 80%
                border-right: 10px solid rgba(#fff, 0.7)
                border-left: 10px solid rgba(#fff, 0.7)
                padding: 0 65px
        @include _under_768_width()
            .title
                flex-direction: column
                padding: 40px 0
                h2.productTemplate_H2
                    width: auto
                p.advantageText
                    width: 100%
                    padding: 30px 15px
                    border-width: 0 5px

    .productApp_Container
        padding: 74px 0
        background-size: cover
        background-repeat: no-repeat
        .productApp
            +size(90%, auto)
            max-width: $max_Container_Width
            margin: 0 auto
            .title
                h2
                    text-align: center
            .circle_Group_Swiper
                +flex_Center_Setting(space-between)
                margin-top: 56px
                .circle
                    +flex_Center_Setting
                    padding: 9px
                    border-radius: 50%
                    background-color: #fff
                    position: relative
                    z-index: 2
                    &::before
                        +pseudoBlock(100%)
                        left: 0
                        top: 0
                        border-radius: 50%
                        z-index: -1
                        transition: 0.3s
                        opacity: 0
                    &:hover
                        &::before
                            opacity: 1
                    img
                        +size(100%)
                        border-radius: 50%
                .circle_Group_Swiper-pagination
                    display: none
            .button_case > a
                box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16)
                margin: 75px auto 0
        @include _under_768_width()
            .productApp > .circle_Group_Swiper
                // padding: 71px 20% 45px
                padding-bottom: 45px
                .circle_Group_Swiper-pagination
                    +flex_Center_Setting
                    position: absolute
                    bottom: 0
                    & > .swiper-pagination-bullet
                        +size(12px)
        @include _under_576_width()
            padding-top: 0
            .productApp
                width: 100%
                .title
                    padding: 62px 5% 30px
                    background-color: #fff
                .circle_Group_Swiper
                    padding: 71px 20% 45px
                    margin-top: 0
                    .circle
                        pointer-events: none
                        opacity: 0.3
                    .circle.swiper-slide-active
                        opacity: 1
                        &::before
                            opacity: 1
                    // .circle_Group_Swiper-pagination
                    //     +flex_Center_Setting
                    //     position: absolute
                    //     bottom: 0
                    //     & > .swiper-pagination-bullet
                    //         +size(12px)
                .button_case > a
                    margin-top: 50px

    .media_Container
        // *
            // border: 1px solid #000
        margin-top: 99px
        .mediaTitle_Group
            +size(90%, auto)
            max-width: $max_Container_Width
            margin: 0 auto
            .title
                border-bottom: 1px solid #707070
                padding-bottom: 18px
            .mediaList
                +flex_Center_Setting(flex-start)
                margin-top: 48px
                max-width: $max_Container_Width
                overflow-x: scroll
                padding-bottom: 15px
                &::-webkit-scrollbar
                    display: none
                //     height: 12px
                //     background-color: #F5F5F5
                // &::-webkit-scrollbar-thumb
                //     border-radius: 20px
                //     -webkit-box-shadow: inset 0 0 6px rgba(0,0,0, 0.6)
                //     background-color: $_title_Color
                .mediaButton
                    min-width: 180px
                    padding: 15px 1%
                    border-radius: 35px
                    border: 2px solid #8f9192
                    margin-right: 23px
                    &:last-child
                        margin-right: 0

        .media_Block
            margin-top: 40px
            padding: 86px 0
            border-width: 9px
            border-style: solid none solid none
            border-left: 0  // Safari取消左右邊框
            border-right: 0  // // Safari取消左右邊框
            border-image: linear-gradient(to right, #5ab8eb -4%, #557fe8 43%, #865fcd 90%) 1 stretch
            .media
                +size(90%, auto)
                max-width: 890px
                margin: 0 auto

    .specification_Title, .slotspecification_Form_Container
        +size(90%, auto)
        max-width: $max_Container_Width
    .specification_Title
        border-bottom: 1px solid #707070
        padding-bottom: 18px
        margin: 114px auto 0
    .slotspecification_Form_Container
        margin: 40px auto 0
        overflow: auto 
        // overflow-x: auto
        box-shadow: 5px 0px 35px -8px rgba(0,0,0,0.2)
        // scrollbar樣式修改(僅限webkit瀏覽器)
        &::-webkit-scrollbar
            // width: 6px
            height: 7px
            background-color: #F5F5F5
        &::-webkit-scrollbar-thumb
            border-radius: 20px
            background-color: #fff
            background-image: $webkit-linear-gradient
            // -webkit-box-shadow: inset 0 0 10px rgba(#fe8017, 0.5)
            // background-color: #555

    .fileDownload_Container
        +size(90%, auto)
        max-width: $max_Container_Width
        margin: 94px auto 0
        .title
            border-bottom: 1px solid #707070
            padding-bottom: 18px
        .fileList
            +flex_Center_Setting
            flex-direction: column
            margin-top: 52px
            a.file
                +flex_Center_Setting(space-between)
                width: 100%
                border-radius: 7px
                border: 1px solid #707070
                padding: 18px 35px
                margin-bottom: 30px
                &:last-child
                    margin-bottom: 0
                .bi-download
                    +size(33px)
        @include _under_576_width()
            .fileList a.file
                padding: 12px 20px
                .bi-download
                    +size(25px)

    .button_contact > a
        background-image: linear-gradient(109deg, #865fcd -5%, #5ab8eb 91%)
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16)
        margin: 114px auto 80px


// -----------------------捲材類顏色更換區--------------------
.tw #Product_Template.coil
    h2.productTemplate_H2
        color: #054567
    .intro_Container
        .intro1
            span
                background-color: $title1_color
        .intro2
            .list
                .bi-check2
                    color: $title1_color
                    border: 3px solid $title1_color
    .advantage_Container
        background-color: rgba(#5ab8eb, 0.8)
    .productApp_Container
        background-image: url('../assets/Products/products background img_bllue.jpg')
        .circle_Group_Swiper
            .circle::before
                background-image: linear-gradient(131deg, #5ab8eb 15%, #865fcd 115%)
        .button_case > a
            border: 2px solid $title1_color
            color: $title1_color
            background-color: #fff
            .arrowRight::before, .arrowRight::after
                background-color: $title1_color
    .media_Container
        .mediaButton.media_isActive
            border: 2px solid $title1_color
            color: #7dc7ef
            pointer-events: none
    .fileDownload_Container
        .bi-download
            color: $title1_color
    @include _under_768_width()
        .productApp_Container
            .circle_Group_Swiper > .circle_Group_Swiper-pagination
                .swiper-pagination-bullet-active
                    background-color: $title1_color

// -----------------------塗料類顏色更換區--------------------
.tw #Product_Template.coating
    h2.productTemplate_H2
        color: #2d1655
    .intro_Container
        .intro1
            span
                background-color: $title2_color
        .intro2
            .list
                .bi-check2
                    color: $title2_color
                    border: 3px solid $title2_color
    .advantage_Container
        background-color: rgba(#674c9a, 0.8)
    .productApp_Container
        background-image: url('../assets/Products/products background img_purple.jpg')
        .circle_Group_Swiper
            .circle::before
                background-image: linear-gradient(131deg, #5ab8eb 15%, #865fcd 115%)
        .button_case > a
            border: 2px solid $title2_color
            color: $title2_color
            background-color: #fff
            .arrowRight::before, .arrowRight::after
                background-color: $title2_color
    .media_Container
        .mediaButton.media_isActive
            border: 2px solid $title2_color
            color: $title2_color
    .fileDownload_Container
        .bi-download
            color: $title2_color
    @include _under_768_width()
        .productApp_Container
            .circle_Group_Swiper > .circle_Group_Swiper-pagination
                .swiper-pagination-bullet-active
                    background-color: $title2_color
// ==== 英文版CSS ====
.en 
    // 繼承tw css
    @extend .tw
.en #Product_Template
    // *
    //     border: 1px solid #000
    h1.productTemplate_H1, h2.productTemplate_H2
        letter-spacing: 1.5px
        line-height: 1.3
    h1.productTemplate_H1
        font-size: 38px
        @include _under_992_width()
            font-size: 28px
        @include _under_768_width()
            font-size: 24px
        @include _under_576_width()
            font-size: 20px
    h2.productTemplate_H2
        font-size: 28px
        // @include _under_992_width()
        //     font-size: 28px
        @include _under_768_width()
            font-size: 22px
        @include _under_576_width()
            font-size: 16px
    p.advantageText, p.appText, button.mediaButton
        font-size: 20px
        letter-spacing: normal
        @include _under_992_width()
            font-size: 18px
        @include _under_768_width()
            font-size: 16px
        @include _under_576_width()
            font-size: 14px
    #Button_Style a
        letter-spacing: normal
        padding-left: 20px
        padding-right: 20px
        &:hover
            padding-right: 10px
    .advantage_Container
        .title
            h2.productTemplate_H2
                width: auto
            p.advantageText
                margin-left: 15px
                padding: 0 40px
        @include _under_768_width()
            .title
                p.advantageText
                    margin-left: 0
                    padding: 30px 15px
    // .productApp_Container
    //     .productApp
    //         .button_case a
    //             padding-left: 20px
    //             padding-right: 20px
    //             &:hover
    //                 padding-right: 10px
</style>        