<template lang="pug">
section#banner_Carousel
    swiper.bannerSwiper(:options='banner_Swiper')
        swiper-slide.bannerSlide.bannerSlide_1
            .bannerImg
                img.imgDesktop(v-if="current_screenWidth > 768" :src="getImgUrl(bannerData[0].imgDesktop)", 
                    :alt="bannerData[0].altText")
                img.imgMobile(v-if="current_screenWidth <= 768" :src="getImgUrl(bannerData[0].imgMobile)", 
                    :alt="bannerData[0].altText")
            .item_Group
                .title
                    h1 {{bannerData[0].title1}}
                    h2 {{bannerData[0].title2}}
                .circle_Group
                    router-link.circle(:to="{name: `Products${computed_routeName_Lang_addBaseline_from_Vuex}`, params: {type: 'coil'}}")
                        .innerCircle
                            img(src="@/assets/banner/icon01.png")
                            h3 {{bannerData[0].title3_1}}
                    router-link.circle(:to="{name: `Products${computed_routeName_Lang_addBaseline_from_Vuex}`, params: {type: 'coating'}}")
                        .innerCircle
                            img(src="@/assets/banner/icon02.png")
                            h3 {{bannerData[0].title3_2}}
        swiper-slide(v-for="banner of bannerData.slice(1)" :key="banner.id").bannerSlide
            .bannerImg
                img.imgDesktop(v-if="current_screenWidth > 768" :src="getImgUrl(banner.imgDesktop)", :alt="banner.altText")
                img.imgMobile(v-if="current_screenWidth <= 768" :src="getImgUrl(banner.imgMobile)", :alt="banner.altText")
            .item_Group
                .title
                    h1 {{banner.title1}}
                    h2 {{banner.title2}}
                Button_Style.button_more(:btnTitle="$t('__BUTTON_TEXT.more')" linkType="routerName" :link="banner.link" :link_Params="{type: banner.productsType}" arrow="right")
        .banner_Carousel-pagination(slot="pagination")
</template>

<script>
// import $ from "jquery"
import Button_Style from "@/components/Button_Style.vue"
import { mapGetters } from 'vuex'

export default {

    components: {
        Button_Style
    },

    data() {
        return{
            current_screenWidth: this.$store.state.windows_Width,

            banner_Swiper: {
                spaceBetween: 0,
                // effect: 'slide',
                effect: 'fade',
                // fadeEffect: { crossFade: true }, // 解決淡入淡出重疊問題
                // paginationClickable: false,
                pagination: {
                    el: '.banner_Carousel-pagination',
                    clickable: true   // 滑鼠可點擊導覽點點
                },
                // pagination: '.swiper-pagination',
                autoplay: {delay: 5000},   // 輪播
                speed: 1200,   // 切換速度
                loop: false,
                // slidesPerView: 1,
                // centeredSlides: true,
                // mousewheel: false,
                // grabCursor: true,  //移入後手掌形狀
                // lazyLoading: true,
                // slideShadows : true,
                // slideToClickedSlide: false
                // on: {
                //     slideChange: (() => {
                //         this.img_Carousel_Control_methods()
                //         this.BannerZoomin_Control_methods()
                //     })
                // }
            }
        }
    },

    computed: {
        // 從Vuex拿getters
        ...mapGetters(['computed_routeName_Lang_from_Vuex', 'computed_routeName_Lang_addBaseline_from_Vuex']),
        
        bannerData: {
            get() {
                return [
                    {
                        id: 0,
                        imgDesktop: 'banner/banner_desktop0.jpg',
                        imgMobile: 'banner/banner_mobile0.jpg',
                        altText: this.$t('__BANNER.__BANNER_0.img_alt'),
                        // title1: '南亞優質防水材料  頂級防護漏水OUT！',
                        // title2: '"從隧道到山坡地、地下室到屋頂、蓄水池到游泳池，提供您全方位的守護。"',
                        // title3_1: '捲材類',
                        // title3_2: '塗料類',
                        title1: this.$t('__BANNER.__BANNER_0.__TITLE1'),
                        title2: this.$t('__BANNER.__BANNER_0.__TITLE2'),
                        title3_1: this.$t('__TYPE.coil'),
                        title3_2: this.$t('__TYPE.coating')
                    },
                    {
                        id: 1,
                        imgDesktop: 'banner/banner_desktop1.jpg',
                        imgMobile: 'banner/banner_mobile1.jpg',
                        altText: this.$t('__BANNER.__BANNER_1.img_alt'),
                        title1: this.$t('__BANNER.__BANNER_1.__TITLE1'),
                        title2: this.$t('__BANNER.__BANNER_1.__TITLE2'),
                        productsType: 'coil',
                        link: `tough_Waterproof`
                    },
                    {
                        id: 2,
                        imgDesktop: 'banner/banner_desktop2.jpg',
                        imgMobile: 'banner/banner_mobile2.jpg',
                        altText: this.$t('__BANNER.__BANNER_2.img_alt'),
                        title1: this.$t('__BANNER.__BANNER_2.__TITLE1'),
                        title2: this.$t('__BANNER.__BANNER_2.__TITLE2'),
                        productsType: 'coil',
                        link: `pool`
                    },
                    {
                        id: 3,
                        imgDesktop: 'banner/banner_desktop3.jpg',
                        imgMobile: 'banner/banner_mobile3.jpg',
                        altText: this.$t('__BANNER.__BANNER_3.img_alt'),
                        title1: this.$t('__BANNER.__BANNER_3.__TITLE1'),
                        title2: this.$t('__BANNER.__BANNER_3.__TITLE2'),
                        productsType: 'coil',
                        link: `tunnel_Waterproof`
                    },
                    {
                        id: 4,
                        imgDesktop: 'banner/banner_desktop4.jpg',
                        imgMobile: 'banner/banner_mobile4.jpg',
                        altText: this.$t('__BANNER.__BANNER_4.img_alt'),
                        title1: this.$t('__BANNER.__BANNER_4.__TITLE1'),
                        title2: this.$t('__BANNER.__BANNER_4.__TITLE2'),
                        productsType: 'coil',
                        link: `roof_Waterproof`
                    },
                    {
                        id: 5,
                        imgDesktop: 'banner/banner_desktop5.jpg',
                        imgMobile: 'banner/banner_mobile5.jpg',
                        altText: this.$t('__BANNER.__BANNER_5.img_alt'),
                        title1: this.$t('__BANNER.__BANNER_5.__TITLE1'),
                        title2: this.$t('__BANNER.__BANNER_5.__TITLE2'),
                        productsType: 'coil',
                        link: `red_Tape`
                    },
                    {
                        id: 6,
                        imgDesktop: 'banner/banner_desktop6.jpg',
                        imgMobile: 'banner/banner_mobile6.jpg',
                        altText: this.$t('__BANNER.__BANNER_6.img_alt'),
                        title1: this.$t('__BANNER.__BANNER_6.__TITLE1'),
                        title2: this.$t('__BANNER.__BANNER_6.__TITLE2'),
                        productsType: 'coating',
                        link: `PU_Waterproof`
                    },
                    {
                        id: 7,
                        imgDesktop: 'banner/banner_desktop7.jpg',
                        imgMobile: 'banner/banner_mobile7.jpg',
                        altText: this.$t('__BANNER.__BANNER_7.img_alt'),
                        title1: this.$t('__BANNER.__BANNER_7.__TITLE1'),
                        title2: this.$t('__BANNER.__BANNER_7.__TITLE2'),
                        productsType: 'coating',
                        link: `Cooler_Paint`
                    },
                ]
            }
        }
    },

    methods: {
        getImgUrl(pic) {
            return require('@/assets/' + pic)
        },

        // img_Carousel_Control_methods() {
        //     clearTimeout(this.Img_Animation_Timer1)
        //     clearTimeout(this.Img_Animation_Timer2)
            
        //     this.Img_Animation_isActive = false
        //     this.Img_Animation_Timer1 = setTimeout(()=>{
        //         this.Img_Animation_isActive = true
        //         // console.log('Timer1')
        //     },10)

        //     this.Img_Animation_Timer2 = setTimeout(()=>{
        //         this.Img_Animation_isActive = false
        //         // console.log('Timer2')
        //     },13500)
        // },

        // BannerZoomin_Control_methods() {
        //     this.BannerZoomin_Animation_isActive = false
        //     setTimeout(()=>{
        //         this.BannerZoomin_Animation_isActive = true
        //     },100)
        // },

        // on_SlideChange() {
        //     this.img_Carousel_Control_methods()
        //     this.BannerZoomin_Control_methods()
        // },
    },

    // mounted() {},

    created() {
        this.Img_Animation_isActive = true
        this.BannerZoomin_Animation_isActive = true
    },

    watch: {
        "$store.state.windows_Width": function() {
            this.current_screenWidth = this.$store.state.windows_Width
        }
    }
}
</script>

<style lang="sass">
@import "@/css/my_Style.sass"
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap');

.tw #banner_Carousel
    +size(100%, auto)
    max-height: calc(100vh - 85px)
    // +size(100%, auto)
    // max-height: calc(100vh - 100px)
    // min-width: 1400px
    // min-height: 800px
    position: relative
    overflow: hidden
    *
        // border: 1px solid #000

    .bannerSwiper
        // +size(auto, 100%)
        +size(100%)
        position: static
        // max-width: 1920px
        // min-width: 900px
        // max-height: 1080px
        .bannerSlide
            // +size(auto, 100%)
            +size(100%)
            // min-height: 800px
            // max-height: calc(1080px - 100px)
            // overflow: hidden
            position: relative
            .bannerImg
                float: right
                img.imgDesktop, img.imgMobile
                    +size(100%)
                    min-width: 1400px

                    // height: 80vh
            .item_Group
                width: 90%
                position: absolute
                left: 5%
                top: 21%
                // @include _under_576_width()
                //     width: 70%
                // @include _under_375_width()
                //     width: 90%
                .title
                    h1
                        +text_Setting(calc(16px + 2.45vw), normal, 5.04px, #fafafa, 500, "Noto Sans TC", left)
                        white-space: pre-line   // 可辨識JS中的換行符號
                        text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16)
                        @include _Upper_1920_width()
                            font-size: 63px
                    h2
                        +text_Setting(calc(14px + 0.5vw), normal, 1.2px, #fafafa, 500, "Noto Sans TC", left)
                        margin-top: 20px
                        white-space: pre-line   // 可辨識JS中的換行符號
                        @include _Upper_1920_width()
                            font-size: 24px
                .button_more > a
                    background-color: rgba($title1_color, 0.6)
                    border-width: 2px
                    margin-top: 60px
                    @include _under_375_width()
                        margin-top: 35px
            &:nth-child(7), &:nth-child(8)
                .button_more > a
                    background-color: rgba($title2_color, 0.6)
        
        // 第一張輪播圖文字區單獨設定
        .bannerSlide_1 > .item_Group
            +size(90%, auto)
            position: absolute
            left: 0
            right: 0
            // top: 50%
            // transform: translateY(-50%)
            margin: 0 auto
            // @include _under_576_width()
            //     width: 80%
            .title
                h1
                    +size(100%, auto)
                    +text_Setting(calc(14px + 2.5vw), normal, 1.7px, #fafafa, 500, "Noto Sans TC", center)
                    text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16)
                    @include _Upper_1920_width()
                        font-size: 72px
                h2
                    +size(100%, auto)
                    +text_Setting(calc(12px + 0.8vw), normal, 2.9px, #fafafa, 400, "Noto Sans TC", center)
                    margin-top: 36px
                    @include _Upper_1920_width()
                        font-size: 28px
            .circle_Group
                +flex_Center_Setting
                margin-top: 80px
                .circle
                    +size(180px)
                    +flex_Center_Setting
                    background-color: rgba(#fff, 0.5)
                    border-radius: 50%
                    position: relative
                    .innerCircle
                        +size(90%)
                        +flex_Center_Setting
                        flex-direction: column
                        border-radius: 50%
                        background-color: #fff
                        z-index: 2
                        img
                            +size(42%)
                        h3
                            +text_Setting(calc(10px + 0.8vw), normal, 2.9px, $title1_color, 500, "Noto Sans TC", center)
                            margin-top: 10px
                            @include _Upper_1920_width()
                                font-size: 30px
                    &::before
                        +pseudoBlock(100%)
                        left: 0
                        top: 0
                        border-radius: 50%
                        // background: conic-gradient(#42fff6 0%, transparent 40%)
                        // animation: rotate_half_RightCircle 3s ease-in-out infinite
                        // z-index: 3
                        @keyframes rotate_LeftCircle_Animation
                            0%
                                transform: rotate(-90deg)
                            100%
                                transform: rotate(270deg)
                        @keyframes rotate_RightCircle_Animation
                            100%
                                transform: rotate(-360deg)
                .circle:nth-child(1)
                    margin-right: 20px
                    &::before
                        // background: linear-gradient(36deg, transparent 55%, #2058d8 75%)
                        background: linear-gradient(131deg, #5ab8eb 15%, #865fcd 115%)
                        animation: rotate_LeftCircle_Animation 2s linear infinite
                        transition: 0.3s
                        opacity: 0
                    &:hover::before
                        opacity: 1
                .circle:nth-child(2)
                    margin-left: 20px
                    &::before
                        // background: linear-gradient(36deg, transparent 55%, #8c2de6 75%)
                        background: linear-gradient(131deg, #5ab8eb 15%, #865fcd 115%)
                        animation: rotate_RightCircle_Animation 2s linear infinite
                        transition: 0.3s
                        opacity: 0
                    &:hover::before
                        opacity: 1
                    h3
                        color: $title2_color

    .banner_Carousel-pagination
        +flex_Center_Setting
        position: absolute
        left: 0
        bottom: 0
        height: auto
        padding: 10px 0
        z-index: 10
        .swiper-pagination-bullet
            +size(16px)
            outline: none
            border-radius: 50%
            background: #fff
            opacity: 0.4
            margin: 8px
            @include _under_576_width()
                +size(12px)
                margin: 4px
            &:first-child
                margin-left: 0
            &:last-child
                margin-right: 0
        .swiper-pagination-bullet-active
            opacity: 1

    @include _under_768_width()
        max-height: calc(100vh - 70px)
        // height: calc(100vh - 70px)
        // height: auto
        .bannerSwiper
            .bannerSlide > .bannerImg
                +size(auto, 100%)
                // min-width: auto
                img.imgMobile
                    min-width: 576px
            // 第一張輪播圖文字區單獨設定
            .bannerSlide_1 > .item_Group > .circle_Group
                justify-content: space-around
                margin-top: 60px
                .circle
                    +size(160px)
                .circle:nth-child(1)
                    margin-right: 0
                .circle:nth-child(2)
                    margin-left: 0
                .circle:nth-child(1), .circle:nth-child(2)
                    &::before
                        opacity: 1

    @include _under_576_width()
        // 第一張輪播圖文字區單獨設定
        .bannerSwiper
            .bannerSlide_1 > .item_Group
                .circle_Group > .circle
                    +size(130px)

    @include _under_375_width()
        .bannerSwiper
            .bannerSlide_1 > .item_Group > .circle_Group
                margin-top: 30px
                .circle
                    +size(110px)

// ==== 英文版CSS ====
.en 
    // 繼承tw css
    @extend .tw
.en #banner_Carousel
    // *
    //     border: 1px solid #000
    .bannerSwiper
        .bannerSlide
            .item_Group
                top: 50%
                transform: translateY(-50%)
                .title 
                    h1, h2
                        letter-spacing: 2px
        // 第一張輪播圖文字區單獨設定
        .bannerSlide_1 > .item_Group
            .title
                h2
                    margin-top: 20px
            .circle_Group
                margin-top: 20px
                .circle .innerCircle
                    h3
                        letter-spacing: normal
    
    @include _under_576_width()
        .bannerSwiper
            .bannerSlide
                &::before
                    +pseudoBlock(100%, 100%, rgba(#000, 0.25))
                    left: 0
                    top: 0
</style>