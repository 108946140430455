<template lang="pug">
main#Pool
    pageTop(:pageTitle="$t('__PRODUCTS.pool.title')" imgSrc="Products/pool/pool page top img_desktop.jpg" imgSrc_Mobile="Products/pool/pool page top img_mobile.jpg")
    Product_Template(colorType="coil" :importProduct_Data="poolData")
        template(v-slot:specification_Form)
            //- .slot_Area
            //-     .title
            //-         h2.productTemplate_H2 規格表
            .formatTable
                //- 厚度
                ul
                    li {{$t('__PRODUCTS.__TEMPLATE.coil_Form_header.thickness')}}
                    li {{$t('__PRODUCTS.pool.Page.formData.thickness_text1')}}
                    li {{$t('__PRODUCTS.pool.Page.formData.thickness_text2')}}
                    li {{$t('__PRODUCTS.pool.Page.formData.thickness_text3')}}
                //- 寬度
                ul
                    li {{$t('__PRODUCTS.__TEMPLATE.coil_Form_header.width')}}
                    li 36~72 inch
                //- 卷裝
                ul
                    li {{$t('__PRODUCTS.__TEMPLATE.coil_Form_header.rollpackaging')}}
                    li {{$t('__PRODUCTS.pool.Page.formData.roll_text1')}}
                    li {{$t('__PRODUCTS.pool.Page.formData.roll_text2')}}

                //- b-table.formatTable(striped hover :items="formateTable_Data")
</template>

<script>
import pageTop from "@/components/pageTop.vue"
import Product_Template from "@/components/Product_Template.vue"
// import Button_Style from "@/components/Button_Style.vue"

export default {
    metaInfo() {
        return{
            title: `${this.$t('__PRODUCTS.pool.title')}-`,
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: this.$t('__PRODUCTS.pool.Page.description')
                },
                // Open Graph / Facebook
                { property: 'og:url', content: `${this.$t('__MAIN.domainName_URL')}/Products-${this.$route.params.type}/pool` },
                { property: 'og:description', content: this.$t('__PRODUCTS.pool.Page.description') },

                // Twitter
                { property: 'twitter:url', content: `${this.$t('__MAIN.domainName_URL')}/Products-${this.$route.params.type}/pool` },
                { property: 'twitter:description', content: this.$t('__PRODUCTS.pool.Page.description') },
            ]
        }
    },

    components: {
        pageTop,
        Product_Template
        // Button_Style
    },

    data() {
        return{
            poolData: {
                introduce: {
                    title: this.$t('__PRODUCTS.pool.title'),
                    introText: this.$t('__PRODUCTS.pool.Page.introText'),
                    swiper_Img: [
                        "Products/pool/products discription_pool1.jpg",
                        "Products/pool/products discription_pool2.jpg",
                        "Products/pool/products discription_pool3.jpg",
                        "Products/pool/products discription_pool4.jpg",
                    ],
                    featureList: this.$t('__PRODUCTS.pool.Page.featureList')
                },
                advantageText: this.$t('__PRODUCTS.pool.Page.advantageText'),
                productApp: {
                    appText: this.$t('__PRODUCTS.pool.Page.applicationText'),
                    circle_Img: [
                        {
                            imgSrc: "Products/pool/products application_pool1.jpg",
                            altText: this.$t('__PRODUCTS.pool.Page.application_Img_alt[0]')
                        },
                        {
                            imgSrc: "Products/pool/products application_pool2.jpg",
                            altText: this.$t('__PRODUCTS.pool.Page.application_Img_alt[1]')
                        },
                        {
                            imgSrc: "Products/pool/products application_pool3.jpg",
                            altText: this.$t('__PRODUCTS.pool.Page.application_Img_alt[2]')
                        },
                        {
                            imgSrc: "Products/pool/products application_pool4.jpg",
                            altText: this.$t('__PRODUCTS.pool.Page.application_Img_alt[3]')
                        }
                    ]
                },
                // mediaList: this.$t('__PRODUCTS.pool.Page.mediaList'),
                fileList: this.$t('__PRODUCTS.pool.Page.fileList')
            },

            // formateTable_Data: [
            //     {"厚度": "地上型 0.280~0.762 mm", "寬度": "36~72 inch", "卷裝": "一般型150～300碼/每支"},
            //     {"厚度": "地下型 0.495~0.902 mm"},
            //     {"厚度": "複合型 0.9~1.5 mm", "卷裝": "複合型30～50碼/每支"},
            // ]
        }
    },

    methods: {
        getImgUrl(pic) {
            return require('@/assets/' + pic)
        },
    },
}
</script>

<style lang="sass">
@import "@/css/my_Style.sass"
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap');

.tw #Pool
    // .slot_Area
    //     *
    //         // border: 1px solid #000
    //     .title
    //         border-bottom: 1px solid #707070
    //         padding-bottom: 18px

    .formatTable
        +size(100%, 286px)
        min-width: 476px
        display: flex
        border: solid 1px #767777
        ul
            flex: 1
            li
                +text_Setting(calc(14px + 0.5vw), 1.33, 1.2px, #707070, 300, "Noto Sans TC", center)
                +flex_Center_Setting
                border: solid 1px #b5baba
                padding: 10px
                @include _Upper_1920_width()
                    font-size: 24px
            li:nth-child(1)
                background-color: #0e3347
                color: #fafafa
                height: 16%
                // & ~ li
                    // height: 28%
        ul:nth-child(1) > li:nth-child(1) ~ li
            height: 28%
        ul:nth-child(2) > li:nth-child(2)
            height: 84%
        ul:nth-child(3) > li:nth-child(2)
            height: 56%
        ul:nth-child(3) > li:nth-child(3)
            height: 28%

// ==== 英文版CSS ====
.en 
    // 繼承tw css
    @extend .tw
.en #Pool
    

</style>        