import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper /* { default options with global component } */)

import VueMeta from 'vue-meta'
// Vue.use(VueMeta)

// options 可以作額外設定
Vue.use(VueMeta,
  {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true
})

import animated from 'animate.css' // npm install animate.css --save安裝後引入
Vue.use(animated)

import WOW from 'wow.js'
  new WOW({ 
    boxClass: 'wow',
    animateClass: 'animated',
    offset: 0,
    mobile: true,
    live: true
}).init()

import cssVars from 'css-vars-ponyfill'
Vue.use(cssVars)

var VueScrollTo = require('vue-scrollto')
// Vue.use(VueScrollTo)
// You can also pass in the default options
Vue.use(VueScrollTo, {
  container: "body",
  duration: 1000,
  easing: "ease",
  offset: -100,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

// Vue用Google Analytics, 2023/6/19停用
// import VueAnalytics from 'vue-analytics'
// Vue.use(VueAnalytics, {
//   id: 'UA-149397356-3',
//   router,
//   autoTracking: {
//     pageviewOnLoad: false
//   }
// })

import VueGtag from "vue-gtag";
Vue.use(VueGtag, {
  config: { id: "G-0QFVQPT6SM" },
  // config: { id: "UA-149397356-2" }
});

// import vueDebounce from 'vue-debounce'
// Vue.use(vueDebounce, {
//   defaultTime: '300ms'
// })

Vue.config.productionTip = false

// import多國語言
import i18n from './languages/vue-i18n.js'
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
