import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import i18n from '../languages/vue-i18n'
Vue.use(VueRouter)

import Home from '@/views/home.vue'
import Company from '@/views/Company.vue'
import Products from '@/views/Products.vue'
import UseCase from '@/views/UseCase.vue'
import QA_Page from '@/views/QA_Page.vue'
import Certification from '@/views/Certification.vue'
import Contact from '@/views/Contact.vue'

// 捲材類
import tough_Waterproof from '@/views/coil/tough_Waterproof.vue'
import pool from '@/views/coil/pool.vue'
import tunnel_Waterproof from '@/views/coil/tunnel_Waterproof.vue'
import red_Tape from '@/views/coil/red_Tape.vue'
import roof_Waterproof from '@/views/coil/roof_Waterproof.vue'

// 塗料類
import PU_Waterproof from '@/views/coating/PU_Waterproof.vue'
import Cooler_Paint from '@/views/coating/Cooler_Paint.vue'

// const websiteLanguage = this.$store.state.currentLanguage

let routes = [
    {path: '*', redirect: '/' },
    // {path: '/', name: 'Home', component: Home},
    // {path: '/Company', name: 'Company', component: Company},
    // {path: '/Products-:type', name: 'Products', component: Products},
    // {path: '/UseCase', name: 'UseCase', component: UseCase},
    // {path: '/QA_Page', name: 'QA_Page', component: QA_Page},
    // {path: '/Certification', name: 'Certification', component: Certification},
    // {path: '/Contact', name: 'Contact', component: Contact},

    // // 捲材類path
    // {path: '/Products-:type/pool', name: 'pool', component: pool},
    // {path: '/Products-:type/tough_Waterproof', name: 'tough_Waterproof', component: tough_Waterproof},
    // {path: '/Products-:type/tunnel_Waterproof', name: 'tunnel_Waterproof', component: tunnel_Waterproof},
    // {path: '/Products-:type/red_Tape', name: 'red_Tape', component: red_Tape},
    // {path: '/Products-:type/roof_Waterproof', name: 'roof_Waterproof', component: roof_Waterproof},

    // // 塗料類path
    // {path: '/Products-:type/PU_Waterproof', name: 'PU_Waterproof', component: PU_Waterproof},
    // {path: '/Products-:type/Cooler_Paint', name: 'Cooler_Paint', component: Cooler_Paint},

    // 2021/5/20改寫巢狀的Nested Routes
    {
        path: '/',
        component: { render: h => h('router-view') },   //  每一個子節點都要return回自己的router-view
        beforeEnter: (to, from, next) => {
            store.commit('changeLanguage_mutationsVuex', 'tw')
            i18n.locale = "tw"
            return next()
        },
        children: [
            {path: '', name: 'Home', component: Home},
            {path: 'Company', name: 'Company', component: Company},
            {path: 'UseCase', name: 'UseCase', component: UseCase},
            {path: 'QA_Page', name: 'QA_Page', component: QA_Page},
            {path: 'Certification', name: 'Certification', component: Certification},
            {path: 'Contact', name: 'Contact', component: Contact},
            
            {
                path: '/Products', component: { render: h => h('router-view') },    //  每一個子節點都要return回自己的router-view
                children: [

                    {path: '/Products-:type', name: 'Products', component: Products},
                    // 捲材類path
                    {path: '/Products-:type/pool', name: 'pool', component: pool},
                    {path: '/Products-:type/tough_Waterproof', name: 'tough_Waterproof', component: tough_Waterproof},
                    {path: '/Products-:type/tunnel_Waterproof', name: 'tunnel_Waterproof', component: tunnel_Waterproof},
                    {path: '/Products-:type/red_Tape', name: 'red_Tape', component: red_Tape},
                    {path: '/Products-:type/roof_Waterproof', name: 'roof_Waterproof', component: roof_Waterproof},

                    // 塗料類path
                    {path: '/Products-:type/PU_Waterproof', name: 'PU_Waterproof', component: PU_Waterproof},
                    {path: '/Products-:type/Cooler_Paint', name: 'Cooler_Paint', component: Cooler_Paint},
                ]
            },
        ],
    },

    {
        path: '/:lang',
        component: { render: h => h('router-view') },   //  每一個子節點都要return回自己的router-view
        beforeEnter: (to, from, next) => {
            const langUrl = to.params.lang
            // 如果 URL 非 en 則導至瀏覽器預設語言
            if (!['en'].includes(langUrl)) {
                router.push({ path: '/en'}) // if中間URL語系是其他非預定地語系，預設導引到英文版首頁
                return next(i18n.locale)
            }
        
            // 如果 URL 有語系，則置換 i18n 語言
            if (i18n.locale !== langUrl) {
                store.commit('changeLanguage_mutationsVuex', langUrl)
                i18n.locale = langUrl
            }
            return next()
        },
        children: [
            {path: "/", name: 'Home_lang', component: Home},
            {path: 'Company', name: 'Company_lang', component: Company},
            {path: 'UseCase', name: 'UseCase_lang', component: UseCase},
            {path: 'QA_Page', name: 'QA_Page_lang', component: QA_Page},
            {path: 'Certification', name: 'Certification_lang', component: Certification},
            {path: 'Contact', name: 'Contact_lang', component: Contact},
            
            {
                path: '/:lang/Products', component: { render: h => h('router-view') },    //  每一個子節點都要return回自己的router-view
                children: [

                    {path: '/:lang/Products-:type', name: 'Products_lang', component: Products},
                    // 捲材類path
                    {path: '/:lang/Products-:type/pool', name: 'pool_lang', component: pool},
                    {path: '/:lang/Products-:type/tough_Waterproof', name: 'tough_Waterproof_lang', component: tough_Waterproof},
                    {path: '/:lang/Products-:type/tunnel_Waterproof', name: 'tunnel_Waterproof_lang', component: tunnel_Waterproof},
                    {path: '/:lang/Products-:type/red_Tape', name: 'red_Tape_lang', component: red_Tape},
                    {path: '/:lang/Products-:type/roof_Waterproof', name: 'roof_Waterproof_lang', component: roof_Waterproof},

                    // 塗料類path
                    {path: '/:lang/Products-:type/PU_Waterproof', name: 'PU_Waterproof_lang', component: PU_Waterproof},
                    {path: '/:lang/Products-:type/Cooler_Paint', name: 'Cooler_Paint_lang', component: Cooler_Paint},
                ]
            },
        ],
    }
]

const router = new VueRouter({
    mode: 'history',
    routes,

    // Router切換，滾動到畫面最上方
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        }

        // 同一頁的話，不捲動
        else if(to.name === from.name){
            return false
        }

        else if(to.hash){
            return window.scrollTo({ 
                selector: to.hash, 
                behavior: 'smooth'
            })
        }

        else {
            // return { x: 0, y: 0 }
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve({ x: 0, y: 0 })
                }, 0)
            })
        }
        
    },

    // beforeEnter (to, from, next) {
    //     console.log(to)
    //     console.log(from)
    //     next()
    // }
})

// router.beforeEach( (to, from, next) => {
//     console.log(to)
//     console.log(from)
//     // router.push({ name: to.name, params: {lang: 'en' } })
//     next()
// })


export default router