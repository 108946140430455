<template lang="pug">
main#home
    banner_Carousel
    //- 最新消息
    #news_Container
        .newsBlock
            h1.newsTitle {{$t('__MAIN_TITLE.News')}}
            swiper.newsSwiper(:options='newsSwiper')
                swiper-slide.newsSwiperSlide(v-for="news of newsList" :key="news.newsTitle")
                    router-link.news(v-if="news.RouteName" :to="{name: news.RouteName, params: news.params}")
                        p.newsText {{news.newsTitle}}
                        .arrowRight
                    a.news(v-if="news.link" :href="news.link" target="_blank")
                        p.newsText {{news.newsTitle}}
                        .arrowRight
    //- 選擇南亞防水材料
    #homeIntroduce_Container
        .title
            h1.title {{$t('__SUB_TITLE.Home.introduce.Block_Title')}}
            p.titleText {{$t('__SUB_TITLE.Home.introduce.Block_Text')}}
        .content_Group
            .block
                h2 {{$t('__SUB_TITLE.Home.introduce.Title_1')}}
                .text
                    .number {{number1}}
                    .end {{$t('__SUB_TITLE.Home.introduce.Word_1')}}
                p {{$t('__SUB_TITLE.Home.introduce.Text_1')}}
            .block
                h2 {{$t('__SUB_TITLE.Home.introduce.Title_2')}}
                .text
                    .front {{$t('__SUB_TITLE.Home.introduce.Word_2')}}
                    .number {{number2}}
                    .end {{$t('__SUB_TITLE.Home.introduce.Word_3')}}
                p {{$t('__SUB_TITLE.Home.introduce.Text_2')}}
            .block
                h2 {{$t('__SUB_TITLE.Home.introduce.Title_3')}}
                .text
                    .number {{number3}}
                    .end {{$t('__SUB_TITLE.Home.introduce.Word_4')}}
                p {{$t('__SUB_TITLE.Home.introduce.Text_3')}}
    
    //- 綠色能源產品
    #home_green_Container
        //- img(src="@/assets/home/home_green-enegry.jpg")
        .itemGroup
            .title
                h1 {{$t('__SUB_TITLE.Home.greenIntroduce.Block_Title')}}
                p {{$t('__SUB_TITLE.Home.greenIntroduce.Block_Text')}}
            router-link.linkIcon(:to="{name: `Products${computed_routeName_Lang_addBaseline_from_Vuex}`, params: {type: 'coil'}, hash: '.green_Container' }")
    home_products

    //- 聯絡我們
    #home_Contact_Container
        img.backgroundImg1(src="@/assets/home/backgroundImg_1.png")
        img.backgroundImg2(src="@/assets/home/backgroundImg_1.png")
        .title
            h1 {{$t('__MAIN_TITLE.Contact')}}
            p {{$t('__MAIN_TITLE_TEXT.ContactText')}}
            Button_Style.button_more(:btnTitle="$t('__BUTTON_TEXT.Contact')" linkType="routerName" link="Contact" arrow="right")
</template>

<script>
import banner_Carousel from "@/section_components/banner_Carousel.vue"
import home_products from "@/section_components/home_products.vue"
import Button_Style from "@/components/Button_Style.vue"
import { mapGetters } from 'vuex'
// import VueScrollTo from 'vue-scrollto'

export default {

    metaInfo() {
        return{
            title: ``,
            // titleTemplate: null,
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: this.$t('__SUB_TITLE.Home.description')
                },
                // Open Graph / Facebook
                { property: 'og:url', content: `${this.$t('__MAIN.domainName_URL')}` },
                { property: 'og:description', content: this.$t('__SUB_TITLE.Home.description') },

                // Twitter
                { property: 'twitter:url', content: `${this.$t('__MAIN.domainName_URL')}` },
                { property: 'twitter:description', content: this.$t('__SUB_TITLE.Home.description') },
            ]
        }
    },

    components: {
        banner_Carousel,
        home_products,
        Button_Style
    },

    data() {
        return{
            number1: 0,
            number2: 0,
            number3: "0",
            pageOffsetY: 0,

            newsSwiper: {
                direction: 'vertical',
                spaceBetween: 0,
                // pagination: {
                //     el: '.newsCarousel_Page_number',
                //     type: 'fraction'
                // },
                // navigation: {
                //     nextEl: '.swiper-button-next',
                //     prevEl: '.swiper-button-prev'
                // },
                autoplay: {delay: 5000},   // 輪播
                speed: 1200,   // 切換速度
                loop: false,
            },

            // newsBlock_Title: '最新消息',
            // newsList: [
            //     {
            //         newsTitle: '樹林一廠及仁武二廠於2020年12月均全數取得TAF合格實驗室證書。',
            //         RouteName: 'Products',  // <link> 和 <RouteName、params(選)> 擇一出現(外連與內連)
            //         params: {type: 'coating'}
            //     },
            //     {
            //         newsTitle: '樹林一廠於2020年6月榮獲綠建材建材標章。',
            //         link: './PDF/Certification/greenbuliding_Certification.jpg'  // <link> 和 <RouteName、params(選)> 擇一出現(外連與內連)
            //     }
            // ]
        }
    },

    computed: {
        // 從Vuex拿getters
        ...mapGetters(['computed_routeName_Lang_from_Vuex', 'computed_routeName_Lang_addBaseline_from_Vuex']),

        // 最新消息清單
        newsList: {
            get() {
                return [
                    {
                        newsTitle: this.$t('__NEWS_LIST[0]'),
                        // <link> 和 <RouteName、params(選)> 擇一出現(外連與內連)
                        RouteName: 'Products', 
                        params: {type: 'coating'},
                        link: null
                    },
                    {
                        newsTitle: this.$t('__NEWS_LIST[1]'),
                        // <link> 和 <RouteName、params(選)> 擇一出現(外連與內連)
                        RouteName: null,
                        params: null,
                        link: './PDF/Certification/greenbuliding_Certification.jpg'
                    }
                ]
            }
        }
    },

    methods: {
        timer1(delay) {
            const timer1 = setInterval(()=>{
                const plusNum = Math.ceil(Math.random()*3)
                if(this.number1 + plusNum >= 30){
                    this.number1 = 30
                    clearInterval(timer1)
                }
                else{
                    this.number1 += plusNum
                }
            }, delay)
        },
        timer2(delay) {
            const timer2 = setInterval(()=>{
                const plusNum = Math.ceil(Math.random()*3)
                if(this.number2 + plusNum >= 30){
                    this.number2 = 30
                    clearInterval(timer2)
                }
                else{
                    this.number2 += plusNum
                }
            }, delay)
        },
        timer3(delay) {
            const timer3 = setInterval(()=>{
                const plusNum = Math.ceil(Math.random()*80)  // 相加的幅度取亂數
                let toNumber = parseInt(this.number3.replace(',',''), 10)   // string轉number型態，有千分位符號先replace掉，再轉成number型態
                if(toNumber + plusNum >= 5000){
                    toNumber = 5000
                    this.number3 = new Intl.NumberFormat().format(toNumber)  // number型態轉成帶有千分位符號的string型態，ex: 1000 => 1,000，接著再寫回this.number3
                    clearInterval(timer3)
                }
                else{
                    toNumber += plusNum
                    this.number3 = new Intl.NumberFormat().format(toNumber)  // number型態轉成帶有千分位符號的string型態，ex: 1000 => 1,000，接著再寫回this.number3
                }
            }, delay)
        },

        numberRise_start() {
            this.timer1(100)
            setTimeout(()=>{ 
                this.timer2(80) 
            }, 1000)
            setTimeout(()=>{ 
                this.timer3(20) 
            }, 600)
        },

        numberRise_Control() {
            let pos = window.pageYOffset
            if(pos >= 600 && pos <= 1500){
                this.numberRise_start()
                this.numberRise_Control_remove()  // 只執行一次，removeListener自己
            }
        },

        numberRise_Control_remove() {
            window.removeEventListener('scroll', this.numberRise_Control)  // 只執行一次，removeListener自己
        }
    },

    mounted() {
        let pos = window.pageYOffset
        if(pos >= 600 && pos <= 1500) {
            this.numberRise_start()
        }
        else{
            window.addEventListener('scroll', this.numberRise_Control)
        }
    },

    destroyed() {
        this.numberRise_Control_remove()
    }
}
</script>

<style lang="sass">
@import "@/css/my_Style.sass"
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap');

    
.tw #home
    +size(100%, auto)
    h1.title
        +text_Setting(calc(22px + 1vw), normal, 1.9px, #373a3d, 500, "Noto Sans TC", center)
        @include _Upper_1920_width()
            font-size: 38px
    h1.newsTitle
        +text_Setting(calc(13px + 0.5vw), 1.5, 1.9px, #fafafa, 400, "Noto Sans TC", center)
        @include _Upper_1920_width()
            font-size: 24px
    p.titleText
        +text_Setting(calc(16px + 0.3vw), normal, 0.2px, #373a3d, 400, "Noto Sans TC", center)
        margin-top: 17px
        @include _Upper_1920_width()
            font-size: 20px
    p.newsText
        +text_Setting(calc(13px + 0.3vw), normal, 0.3px, #fafafa, 300, "Noto Sans TC", left)
    // *
    //     border: 1px solid #000

    #news_Container
        *
            // border: 1px solid #fff
        +size(100%, 70px)
        +flex_Center_Setting
        padding: 12px 0
        background-color: #1b2739
        .newsBlock
            +size(95%, 100%)
            +flex_Center_Setting(space-between)
            max-width: 1000px
            h1.newsTitle
                +size(auto, 100%)
                +flex_Center_Setting(flex-end)
                flex: 1
                padding-right: 2.5%
                border-right: 1px solid #fafafa
            .newsSwiper
                +size(100%)
                flex: 7
                margin: 0
                .newsSwiperSlide
                    .news
                        +size(100%)
                        +flex_Center_Setting(space-between)
                        border-left: 1px solid #fafafa
                        p
                            +size(90%, 100%)
                            +flex_Center_Setting(flex-start)
                            padding-left: 2.5%
                        +arrowRight(45px, 11px, #f3d171)
        @include _under_576_width()
            .newsBlock
                h1.newsTitle
                    width: 15%
                    justify-content: center
                    // padding-right: 0
                .newsSwiper
                    width: 85%
                    .newsSwiperSlide
                        .news
                            p
                                height: auto
                                overflow: hidden
                                text-overflow: ellipsis
                                display: -webkit-box
                                -webkit-box-orient: vertical
                                -webkit-line-clamp: 2
                            .arrowRight
                                +size(33px, 9px)
    
    #homeIntroduce_Container
        *
            // border: 1px solid #000
        +size(65%, auto)
        +flex_Center_Setting
        flex-direction: column
        max-width: $max_Container_Width
        margin: 0 auto
        padding: 84px 0 109px 0
        @include _under_1200_width()
            width: 80%
        @include _under_576_width()
            padding: 60px 0 80px 0
        .title
            +size(100%, auto)
        .content_Group
            +size(100%, auto)
            +flex_Center_Setting(space-between, flex-start)
            margin-top: 70px
            .block
                width: 31%
                h2
                    font-size: calc(18px + 0.4vw)
                    font-weight: 500
                    text-align: center
                    @include _Upper_1920_width()
                        font-size: 24px
                .text
                    +flex_Center_Setting(center, flex-end)
                    margin-top: 30px
                    .front, .end, .number
                        font-weight: 600
                        text-align: center
                        line-height: 1
                    .front, .end
                        font-size: calc(20px + 0.6vw)
                        @include _Upper_1920_width()
                            font-size: 28px
                    .front
                        padding-right: 15px
                    .end
                        padding-left: 15px
                    .number
                        font-size: calc(40px + 2vw)
                        text-shadow: 3px 4px 6px rgba(0, 0, 0, 0.26)
                        @include _Upper_1920_width()
                            font-size: 83px
                p
                    +text_Setting(calc(14px + 0.2vw), 1.67, 0.9px, #828384, 400, "Noto Sans TC", center)
                    margin-top: 30px
                    @include _Upper_1920_width()
                        font-size: 18px
            .block
                &:nth-child(1) > h2, &:nth-child(1) > .text > .front, &:nth-child(1) > .text > .number, &:nth-child(1) > .text > .end
                    color: $title1_color
                &:nth-child(2) > h2, &:nth-child(2) > .text > .front, &:nth-child(2) > .text > .number, &:nth-child(2) > .text > .end
                    color: #557fe8
                &:nth-child(3) > h2, &:nth-child(3) > .text > .front, &:nth-child(3) > .text > .number, &:nth-child(3) > .text > .end
                    color: $title2_color
        @include _under_992_width()
            .content_Group
                +size(100%, auto)
                max-width: 400px
                flex-direction: column
                align-items: center
                margin-top: 50px
                .block
                    width: 100%
                    &.block:nth-child(1) ~ .block
                        margin-top: 50px
        // @include _under_768_width()
        //     .content_Group
        //         +size(90%, auto)
        // @include _under_576_width()
        //     .content_Group
        //         +size(100%, auto)
        //         .block
        //             width: 90%

    #home_green_Container
        background: url("~@/assets/home/home_green-enegry.jpg") no-repeat center center / cover
        // position: relative
        // +size(100%, auto)
        // img
        //     +size(100%)
        //     min-width: 1920px
        .itemGroup
            +flex_Center_Setting(space-between)
            width: 100%
            padding: 90px 10%
            // position: absolute
            // left: 0
            // top: 50%
            // transform: translateY(-50%)
            .title
                width: calc(100% - 60px - 40px)
                position: relative
                padding-left: 40px
                &::before
                    +pseudoBlock(8px, 100%, #fff)
                    left: 0
                    top: 0
                    opacity: 0.6
                h1
                    +text_Setting(calc(22px + 1vw), normal, 1.9px, #f5f5f5, 500, "Noto Sans TC", left)
                    @include _Upper_1920_width()
                        font-size: 38px
                p
                    +text_Setting(calc(14px + 0.2vw), 1.78, 1.8px, #fafafa, 400, "Noto Sans TC", left)
                    margin-top: 30px
                    @include _Upper_1920_width()
                        font-size: 18px
                    @include _under_576_width()
                        margin-top: 15px
            .linkIcon
                +size(60px)
                border: 2px solid #f3d171
                border-radius: 50%
                position: relative
                // margin-left: 40px
                &::before
                    +pseudoBlock(32px, 2px, #f3d171)
                    left: 50%
                    top: 53%
                    transform: translate(-50%, -50%)
                    border-radius: 0 10px 10px 0
                &::after
                    +pseudoBlock(16px, 2px, #f3d171)
                    left: calc(50%)
                    top: 53%
                    transform: translateY(-50%) rotate(40deg)
                    transform-origin: right
                    border-radius: 0 10px 0 0
        // @include _under_992_width()
        //     .itemGroup > .linkIcon
        //         +size(60px)
        @include _under_576_width()
            .itemGroup
                flex-direction: column
                justify-content: center
                top: 0
                transform: none
                height: 100%
                .title
                    width: 100%
                    padding-left: 0
                    &::before
                        display: none
                .linkIcon
                    +size(50px)
                    margin: 20px 0 0


    #home_Contact_Container
        +size(100%, auto)
        +flex_Center_Setting
        padding: 100px 0
        background-image: linear-gradient(to left, #5ab8eb -18%, #557fe8 34%, #865fcd 129%)
        position: relative
        overflow: hidden
        @include _under_1200_width()
            padding: 60px 0
        img
            +size(40%, auto)
            min-width: 600px
            position: absolute
        img.backgroundImg1
            left: -250px
            top: -370px
        img.backgroundImg2
            right: -400px
            bottom: -300px
        
        .title
            +size(85%, auto)
            max-width: $max_Container_Width
            // @include _under_1200_width()
            //     +size(70%, auto)
            h1
                +text_Setting(calc(22px + 1.1vw), 1, 2.25px, #fafafa, 500, "Noto Sans TC", center)
                @include _Upper_1920_width()
                    font-size: 45px
            p
                +text_Setting(calc(14px + 0.4vw), 1.62, 1.9px, #fafafa, 300, "Noto Sans TC", center)
                margin-top: 20px
                @include _Upper_1920_width()
                    font-size: 22px
            & > .button_more > a
                margin: 50px auto 0 auto
                @include _under_1200_width()
                    margin: 35px auto 0 auto

// ==== 英文版CSS ====
.en 
    // 繼承tw css
    @extend .tw
.en #home
    #home_Contact_Container
        #Button_Style a
            width: auto
            max-width: 210px
            padding-left: 25px
            padding-right: 20px
            // @include _under_576_width()
            //     max-width: 220px

</style>