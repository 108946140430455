<template lang="pug">
section#home_products
    .products_Container
        //- 產品資訊
        .title
            h1.title {{$t('__MAIN_TITLE.Products_T2')}}
            p.titleText {{$t('__SUB_TITLE.Home.productsBlock_Text')}}
        .products_Group
            .row1
                .column
                    //- 強韌防水膜
                    router-link.card_Block(:to="{name: `tough_Waterproof${computed_routeName_Lang_addBaseline_from_Vuex}`, params: {type: 'coil'}}")
                        img.deskTop_Img(src="@/assets/home/home_productcard_normal.jpg", :alt="$t('__PRODUCTS.tough_Waterproof.title')")
                        img.mobile_Img(src="@/assets/home/home_productcard_normal_mobile.jpg", :alt="$t('__PRODUCTS.tough_Waterproof.title')")
                        .cardTitle
                            .top
                                h2.title {{$t('__PRODUCTS.tough_Waterproof.title')}}
                                .icon.coil {{$t('__TYPE.coil')}}
                            p.cardText {{$t('__PRODUCTS.tough_Waterproof.home_CardText')}}
                .column
                    //- 隧道防水膜
                    router-link.card_Block(:to="{name: `tunnel_Waterproof${computed_routeName_Lang_addBaseline_from_Vuex}`, params: {type: 'coil'}}")
                        img.deskTop_Img(src="@/assets/home/home_productcard_tunnel.jpg", :alt="$t('__PRODUCTS.tunnel_Waterproof.title')")
                        img.mobile_Img(src="@/assets/home/home_productcard_tunnel_mobile.jpg", :alt="$t('__PRODUCTS.tunnel_Waterproof.title')")
                        .cardTitle
                            .top
                                h2.title {{$t('__PRODUCTS.tunnel_Waterproof.title')}}
                                .icon.coil {{$t('__TYPE.coil')}}
                            p.cardText {{$t('__PRODUCTS.tunnel_Waterproof.home_CardText')}}
                    //- 屋頂防水膜
                    router-link.card_Block(:to="{name: `roof_Waterproof${computed_routeName_Lang_addBaseline_from_Vuex}`, params: {type: 'coil'}}")
                        img.deskTop_Img(src="@/assets/home/home_productcard_roof.jpg", :alt="$t('__PRODUCTS.roof_Waterproof.title')")
                        img.mobile_Img(src="@/assets/home/home_productcard_roof_mobile.jpg", :alt="$t('__PRODUCTS.roof_Waterproof.title')")
                        .cardTitle
                            .top
                                h2.title {{$t('__PRODUCTS.roof_Waterproof.title')}}
                                .icon.coil {{$t('__TYPE.coil')}}
                            p.cardText {{$t('__PRODUCTS.roof_Waterproof.home_CardText')}}
            .row2
                //- 泳池裡襯(複合)
                router-link.card_Block(:to="{name: `pool${computed_routeName_Lang_addBaseline_from_Vuex}`, params: {type: 'coil'}}")
                    img.deskTop_Img(src="@/assets/home/home_productcard_pool.jpg", :alt="$t('__PRODUCTS.pool.title')")
                    img.mobile_Img(src="@/assets/home/home_productcard_pool_mobile.jpg", :alt="$t('__PRODUCTS.pool.title')")
                    .cardTitle
                        .top
                            h2.title {{$t('__PRODUCTS.pool.title')}}
                            .icon.coil {{$t('__TYPE.coil')}}
                        p.cardText {{$t('__PRODUCTS.pool.home_CardText')}}
            .row3
                //- 紅泥膠布
                router-link.card_Block(:to="{name: `red_Tape${computed_routeName_Lang_addBaseline_from_Vuex}`, params: {type: 'coil'}}")
                    img.deskTop_Img(src="@/assets/home/home_productcard_redmud.jpg", :alt="$t('__PRODUCTS.red_Tape.title')")
                    img.mobile_Img(src="@/assets/home/home_productcard_redmud_mobile.jpg", :alt="$t('__PRODUCTS.red_Tape.title')")
                    .cardTitle
                        .top
                            h2.title {{$t('__PRODUCTS.red_Tape.title')}}
                            .icon.coil {{$t('__TYPE.coil')}}
                        p.cardText {{$t('__PRODUCTS.red_Tape.home_CardText')}}
                //- PU防水材
                router-link.card_Block(:to="{name: `PU_Waterproof${computed_routeName_Lang_addBaseline_from_Vuex}`, params: {type: 'coating'}}")
                    img.deskTop_Img(src="@/assets/home/home_productcard_pu.jpg", :alt="$t('__PRODUCTS.PU_Waterproof.title')")
                    img.mobile_Img(src="@/assets/home/home_productcard_pu_mobile.jpg", :alt="$t('__PRODUCTS.PU_Waterproof.title')")
                    .cardTitle
                        .top
                            h2.title {{$t('__PRODUCTS.PU_Waterproof.title')}}
                            .icon.coating {{$t('__TYPE.coating')}}
                        p.cardText {{$t('__PRODUCTS.PU_Waterproof.home_CardText')}}
            .row4
                //- 隔熱漆
                router-link.card_Block(:to="{name: `Cooler_Paint${computed_routeName_Lang_addBaseline_from_Vuex}`, params: {type: 'coating'}}")
                    img.deskTop_Img(src="@/assets/home/home_productcard_cooler.jpg", :alt="$t('__PRODUCTS.Cooler_Paint.title')")
                    img.mobile_Img(src="@/assets/home/home_productcard_cooler_mobile.jpg", :alt="$t('__PRODUCTS.Cooler_Paint.title')")
                    .cardTitle
                        .top
                            h2.title {{$t('__PRODUCTS.Cooler_Paint.title')}}
                            .icon.coating {{$t('__TYPE.coating')}}
                        p.cardText {{$t('__PRODUCTS.Cooler_Paint.home_CardText')}}
        Button_Style.button_more(:btnTitle="$t('__BUTTON_TEXT.more')" linkType="routerName" link="Products" :link_Params="{type: 'coil'}" arrow="right")
</template>

<script>
import Button_Style from "@/components/Button_Style.vue"
import { mapGetters } from 'vuex'

export default {
    components: {
        Button_Style
    },

    // data() {
    //     return{}
    // }
    computed: {
        // 從Vuex拿getters
        ...mapGetters(['computed_routeName_Lang_from_Vuex', 'computed_routeName_Lang_addBaseline_from_Vuex']),
    }
}
</script>

<style lang="sass">
@import "@/css/my_Style.sass"
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap');

    
.tw #home_products
    padding: 108px 0 152px 0
    @include _under_1200_width()
        padding: 90px 0 120px 0
    *
        // border: 1px solid #000
    .products_Container
        +size(80%, auto)
        max-width: $max_Container_Width - 100px
        margin: 0 auto
        @include _under_1200_width()
            width: 80%

        // card共同CSS--------------------------------
        .products_Group
            margin-top: 56px
            .card_Block
                position: relative
                // box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.39)
                display: block
                // cursor: pointer
                img
                    +size(100%)
                    border-radius: 10px
                .mobile_Img
                    display: none
                .cardTitle
                    position: absolute
                    left: 48px
                    bottom: 40px
                    .top
                        +flex_Center_Setting(flex-start, center)
                        h2.title
                            +text_Setting(calc(16px + 0.9vw), 1, 1.6px, #fafafa, 500, "Noto Sans TC", left)
                            text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16)
                            @include _Upper_1920_width()
                                font-size: 32px
                        .icon
                            +size(100px, 37px)
                            +flex_Center_Setting
                            +text_Setting(calc(13px + 0.2vw), 1, normal, #fafafa, 200, "Noto Sans TC", center)
                            border-radius: 24px
                            margin-left: 28px
                            @include _Upper_1920_width()
                                font-size: 16px
                            @include _under_1200_width()
                                +size(85px, 35px)
                                margin-left: 15px
                        // 捲材類icon的class
                        .icon.coil
                            background-color: rgba(#5ab8eb, 0.8)
                        // 塗料類icon的class
                        .icon.coating
                            background-color: rgba(#865fcd, 0.8)
                    p.cardText
                        width: 75%
                        +text_Setting(calc(12px + 0.4vw), 1.55, normal, #fafafa, 300, "Noto Sans TC", left)
                        margin-top: 20px
                        @include _Upper_1920_width()
                            font-size: 20px
                        @include _under_1200_width()
                            width: 80%
                            margin-top: 15px
                &::before
                    +pseudoBlock(100%, 100%)
                    background: linear-gradient(to top, rgba(#053F6A, 0.7) 10%, transparent)
                    transition: 0.5s
                    border-radius: 10px
                &:hover
                    &::before
                        opacity: 0
            
            // row1之後的row間隔24px
            .row1 ~ div
                margin-top: 24px
            
            // 個別card的欄列設定
            .row1
                display: flex
                justify-content: space-between
                width: 100%
                .column:nth-child(1)
                    width: calc(45% - 12px)
                .column:nth-child(2)
                    width: calc(55% - 12px)
                    display: flex
                    flex-direction: column
                    justify-content: space-between
                    .card_Block
                        height: calc(50% - 13px)
            .row3
                display: flex
                justify-content: space-between
                .card_Block
                    width: calc(50% - 12px)
            
            .row3 > .card_Block:nth-child(2)::before, .row4 > .card_Block::before
                background: linear-gradient(to top, rgba(#11075B, 0.7) 10%, transparent)
        // card共同CSS--------------------------------
        
        .button_more > a
            background-image: linear-gradient(97deg, #865fcd -23%, #5ab8eb 147%)
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16)
            margin: 100px auto 0 auto

    @include _under_992_width
        .products_Container
            // card共同CSS
            .products_Group 
                .card_Block
                    .cardTitle
                        left: 20px
                        bottom: 20px
                // row1之後的row間隔12px
                .row1 ~ div
                    margin-top: 12px
                
                // 個別card的欄列設定
                .row1
                    .column:nth-child(1)
                        width: calc(45% - 6px)
                    .column:nth-child(2)
                        width: calc(55% - 6px)
                        .card_Block
                            height: calc(50% - 6px)
                .row3
                    .card_Block
                        width: calc(50% - 6px)

    @include _under_768_width
        .products_Container
            // +size(75%, auto)
            // card共同CSS
            .products_Group
                .row1 ~ div
                    margin-top: 0
                .card_Block
                    margin-top: 35px
                    .deskTop_Img
                        display: none
                    .mobile_Img
                        display: block
                .row1, .row3
                    flex-direction: column
                    .column, .card_Block
                        width: 100%!important
                .row1 > .column:nth-child(1) > .card_Block:nth-child(1)
                    margin-top: 0

    @include _under_576_width
        padding-top: 40px
        padding-bottom: 60px
        .products_Container
            // width: 85%
            .products_Group .card_Block
                margin-top: 20px
                // &::before
                //     opacity: 1
                .cardTitle
                    .top .icon
                        +size(80px, 28px)
                    p.cardText
                        width: calc(100% - 20px)
                        margin-top: 10px
            .button_more > a
                margin: 60px auto 0 auto

// ==== 英文版CSS ====
.en 
    // 繼承tw css
    @extend .tw
.en #home_products
    // *
    //     border: 1px solid #000
    .products_Container
        // card共同CSS--------------------------------
        .products_Group .card_Block
            .cardTitle
                bottom: 30px
                left: 30px
                width: calc(100% - 30px*2)
                .top 
                    h2.title
                        font-size: 26px
                        letter-spacing: 1px
                    .icon
                        margin-left: 10px
                p.cardText
                    width: 100%
        // card共同CSS--------------------------------
    
    @include _under_992_width
        .products_Container
            // +size(75%, auto)
            // card共同CSS--------------------------------
            .products_Group
                .row1 ~ div
                    margin-top: 0
                .card_Block
                    margin-top: 35px
                    .deskTop_Img
                        display: none
                    .mobile_Img
                        display: block
                .row1, .row3
                    flex-direction: column
                    .column, .card_Block
                        width: 100%!important
                .row1 > .column:nth-child(1) > .card_Block:nth-child(1)
                    margin-top: 0
            // card共同CSS--------------------------------

    @include _under_576_width
        .products_Container
            .products_Group .card_Block
                .cardTitle
                    .top
                        justify-content: space-between
                        h2.title
                            font-size: 18px
                        .icon
                            margin-left: 0
                    p.cardText
                        font-size: 14px
                        line-height: 1.2

    @include _under_375_width()
        .products_Container
            .products_Group .card_Block
                .cardTitle
                    bottom: 15px
                    left: 15px
                    width: calc(100% - 15px*2)
                    .top 
                        h2.title
                            font-size: 16px
                    p.cardText
                        font-size: 12px
</style>