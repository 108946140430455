<template lang="pug">
main#Company
    pageTop(:pageTitle="$t('__MAIN_TITLE.Company')" imgSrc="company/company_pageTop_desktop.jpg" imgSrc_Mobile="company/home_page top img_mobile.jpg")
    .CompanyPage
        .introduce1_Container
            p {{$t('__SUB_TITLE.Company.page_Introduce')}}
        .introduce2_Container
            //- 仁武廠簡介(捲材類)
            .comapny_Introduce.company1
                .title
                    h2 {{$t('__SUB_TITLE.Company.Title_1_renwu') + $t('__SUB_TITLE.Company.Title_intro')}} ({{$t('__TYPE.coil')}})
                    p {{$t('__SUB_TITLE.Company.Intro_Text_1')}}
                img(src="@/assets/company/company_introduce_1.jpg", :alt="$t('__SUB_TITLE.Company.Title_1_renwu')")
            //- 樹林廠簡介(塗料類)
            .comapny_Introduce.company2
                .title
                    h2 {{$t('__SUB_TITLE.Company.Title_2_shulin') + $t('__SUB_TITLE.Company.Title_intro')}} ({{$t('__TYPE.coating')}})
                    p {{$t('__SUB_TITLE.Company.Intro_Text_2')}}
                img(src="@/assets/company/company_introduce_2.jpg", :alt="$t('__SUB_TITLE.Company.Title_2_shulin')")
        //- 工廠沿革
        .timeLine_Container
            .button_Body
                h1 {{$t('__SUB_TITLE.Company.Title_3')}}
                .buttonGroup
                    //- 仁武廠
                    button(:class="{renwu: renwuColor_isActive}" @click="changeButton('仁武廠')") {{$t('__SUB_TITLE.Company.Title_1_renwu')}}
                        img.icon(src="@/assets/icon01.svg" v-show="renwuColor_isActive")
                        img.icon(src="@/assets/icon01_black.svg" v-show="!renwuColor_isActive")
                    //- 樹林廠
                    button(:class="{shulin: shulinColor_isActive}" @click="changeButton('樹林廠')") {{$t('__SUB_TITLE.Company.Title_2_shulin')}}
                        img.icon(src="@/assets/icon02.svg" v-show="shulinColor_isActive")
                        img.icon(src="@/assets/icon02_black.svg" v-show="!shulinColor_isActive")
            
            //- 電腦版時間軸區塊
            .timeLine_Body.timeLine_Desktop
                .left_Column
                    .timeCard(v-for="card of timeLineDesktop.leftColumn" :class="className_Control(card.type)")
                        h3.year {{card.year}}
                        .imgBlock
                            img(:src="getImgUrl(card.imgSrc)")
                            p.cardText {{card.cardText}}
                .right_Column
                    .timeCard(v-for="card of timeLineDesktop.rightColumn" :class="className_Control(card.type)")
                        h3.year {{card.year}}
                        .imgBlock
                            img(:src="getImgUrl(card.imgSrc)")
                            p.cardText {{card.cardText}}
            
            //- 手機版時間軸區塊
            .timeLine_Body.timeLine_Mobile
                .timeCard_Group.renwu_CardGroup(v-show="renwuColor_isActive")
                    .timeCard.renwu_Color(v-for="card of timeLineCard_Data.renwuCard")
                        h3.year {{card.year}}
                        .imgBlock
                            img(:src="getImgUrl(card.imgSrc)")
                            p.cardText {{card.cardText}}
                .timeCard_Group.renwu_CardGroup(v-show="shulinColor_isActive")
                    .timeCard.shulin_Color(v-for="card of timeLineCard_Data.shulinCard")
                        h3.year {{card.year}}
                        .imgBlock
                            img(:src="getImgUrl(card.imgSrc)")
                            p.cardText {{card.cardText}}

        Button_Style.button_more(:btnTitle="$t('__BUTTON_TEXT.more_Products')" linkType="routerName" link="Products" :link_Params="{type: 'coil'}" arrow="right")
</template>

<script>
import pageTop from "@/components/pageTop.vue"
import Button_Style from "@/components/Button_Style.vue"

export default {
    metaInfo() {
        return{
            title: `${this.$t('__MAIN_TITLE.Company')}-`,
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: this.$t('__SUB_TITLE.Company.description')
                },
                // Open Graph / Facebook
                { property: 'og:url', content: `${this.$t('__MAIN.domainName_URL')}/Company` },
                { property: 'og:description', content: this.$t('__SUB_TITLE.Company.description') },

                // Twitter
                { property: 'twitter:url', content: `${this.$t('__MAIN.domainName_URL')}/Company` },
                { property: 'twitter:description', content: this.$t('__SUB_TITLE.Company.description') },
            ]
        }
    },

    components: {
        pageTop,
        Button_Style
    },

    data() {
        return{
            renwuColor_isActive: true,
            shulinColor_isActive: false,
        }
    },

    computed: {
        timeLineCard_Data: {
            get() {
                return {
                    renwuCard: [
                        {
                            year: 2020,
                            cardText: this.$t('__SUB_TITLE.Company.timeLineCard_Data.renwu.card_1'),
                            imgSrc: "company/company_history_renwu2020.jpg",
                            type: "renwu"
                        },
                        {
                            year: 2019,
                            cardText: this.$t('__SUB_TITLE.Company.timeLineCard_Data.renwu.card_2'),
                            imgSrc: "company/company_history_renwu2019.jpg",
                            type: "renwu"
                        },
                        {
                            year: 2014,
                            cardText: this.$t('__SUB_TITLE.Company.timeLineCard_Data.renwu.card_3'),
                            imgSrc: "company/company_history_renwu2014.jpg",
                            type: "renwu"
                        },
                        {
                            year: 2004,
                            cardText: this.$t('__SUB_TITLE.Company.timeLineCard_Data.renwu.card_4'),
                            imgSrc: "company/company_history_renwu2004.jpg",
                            type: "renwu"
                        },
                        {
                            year: 1980,
                            cardText: this.$t('__SUB_TITLE.Company.timeLineCard_Data.renwu.card_5'),
                            imgSrc: "company/company_history_renwu1980.jpg",
                            type: "renwu"
                        },
                        {
                            year: 1979,
                            cardText: this.$t('__SUB_TITLE.Company.timeLineCard_Data.renwu.card_6'),
                            imgSrc: "company/company_history_renwu1979.jpg",
                            type: "renwu"
                        },
                    ],
                    shulinCard: [
                        {
                            year: 2020,
                            cardText: this.$t('__SUB_TITLE.Company.timeLineCard_Data.shulin.card_1'),
                            imgSrc: "company/company_history_shulin2020.jpg",
                            type: "shulin"
                        },
                        {
                            year: 2018,
                            cardText: this.$t('__SUB_TITLE.Company.timeLineCard_Data.shulin.card_2'),
                            imgSrc: "company/company_history_shulin2018.jpg",
                            type: "shulin"
                        },
                        {
                            year: 2010,
                            cardText: this.$t('__SUB_TITLE.Company.timeLineCard_Data.shulin.card_3'),
                            imgSrc: "company/company_history_shulin2010.jpg",
                            type: "shulin"
                        },
                        {
                            year: 2006,
                            cardText: this.$t('__SUB_TITLE.Company.timeLineCard_Data.shulin.card_4'),
                            imgSrc: "company/company_history_shulin2006.jpg",
                            type: "shulin"
                        },
                        {
                            year: 1985,
                            cardText: this.$t('__SUB_TITLE.Company.timeLineCard_Data.shulin.card_5'),
                            imgSrc: "company/company_history_shulin1985.jpg",
                            type: "shulin"
                        },
                    ]
                }
            }
        },

        timeLineDesktop: {
            get() {
                return {
                    leftColumn: [
                        this.timeLineCard_Data.renwuCard[0],
                        this.timeLineCard_Data.shulinCard[1],
                        this.timeLineCard_Data.shulinCard[2],
                        this.timeLineCard_Data.renwuCard[3],
                        this.timeLineCard_Data.renwuCard[4],
                    ],
                    rightColumn: [
                        this.timeLineCard_Data.shulinCard[0],
                        this.timeLineCard_Data.renwuCard[1],
                        this.timeLineCard_Data.renwuCard[2],
                        this.timeLineCard_Data.shulinCard[3],
                        this.timeLineCard_Data.shulinCard[4],
                        this.timeLineCard_Data.renwuCard[5],
                    ]
                }
            }
        }
    },

    methods: {
        getImgUrl(pic) {
            return require('@/assets/' + pic)
        },

        changeButton(area) {
            if(area === '仁武廠') {
                this.renwuColor_isActive = true
                this.shulinColor_isActive = false
                // return
            }
            if(area === '樹林廠') {
                this.renwuColor_isActive = false
                this.shulinColor_isActive = true
                // return
            }
        },

        // handle_timeLineDesktop_Data() {
        //     const renwu = this.timeLineCard_Data.renwuCard
        //     const shulin = this.timeLineCard_Data.shulinCard

        //     for(let i in renwu) { renwu[i].type = "renwu" }
        //     for(let i in shulin) { shulin[i].type = "shulin" }
        //     this.timeLineDesktop.leftColumn.push(renwu[0], shulin[1], shulin[2], renwu[3], renwu[4])
        //     this.timeLineDesktop.rightColumn.push(shulin[0], renwu[1], renwu[2], shulin[3], shulin[4], renwu[5])
        // },

        className_Control(typeName) {
            if(typeName === "renwu"){
                return {renwu_Color: this.renwuColor_isActive}
            }
            if(typeName === "shulin"){
                return {shulin_Color: this.shulinColor_isActive}
            }
        }
    },

    // created() {
    //     this.handle_timeLineDesktop_Data()
    // }
}
</script>

<style lang="sass">
@import "@/css/my_Style.sass"
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap');

.tw #Company
    *
        // border: 1px solid #000
    
    .CompanyPage
        +size(80%, auto)
        max-width: $max_Container_Width
        margin: 0 auto
        padding: 60px 0 150px 0
        @include _under_576_width()
            padding-bottom: 100px
        
        .introduce1_Container
            +size(100%, auto)
            border-radius: 11px
            padding: 10px 14px
            background-image: linear-gradient(to left, #5ab8eb -18%, #557fe8 55%, #865fcd 129%)
            opacity: 0.8
            @include _under_576_width()
                padding: 14px
            p
                +text_Setting(calc(14px + 0.4vw), 1.64, 0.22px, #fff, 400, "Noto Sans TC", center)
                // padding: 38px 68px
                padding: 2.8% 5%
                border: 1px solid #fff
                border-radius: 5px
                @include _Upper_1920_width()
                    font-size: 22px

        .introduce2_Container
            margin-top: 106px
            @include _under_576_width()
                margin-top: 70px
            .comapny_Introduce
                +flex_Center_Setting(space-between)
                .title, img
                    +size(48%, auto)
                    h2
                        +text_Setting(calc(16px + 0.8vw), 1, 1.5px, $title1_color, 500, "Noto Sans TC", left)
                        @include _Upper_1920_width()
                            font-size: 30px
                    p
                        +text_Setting(calc(14px + 0.3vw), 1.75, 0.2px, #303536, 400, "Noto Sans TC", left)
                        margin-top: 25px
                        @include _Upper_1920_width()
                            font-size: 20px
            .company2
                flex-direction: row-reverse
                margin-top: 85px
                @include _under_576_width()
                    margin-top: 40px
                .title > h2
                    color: $title2_color
            @include _under_768_width()
                .comapny_Introduce
                    flex-direction: column-reverse
                    .title, img
                        +size(100%)
                    .title
                        margin-top: 27px

        .timeLine_Container
            margin-top: 200px
            @include _under_1200_width()
                margin-top: 120px
            @include _under_576_width()
                margin-top: 80px

            .button_Body
                h1
                    +text_Setting(calc(16px + 1.1vw), 1, 1.9px, #223B51, 500, "Noto Sans TC", center)
                    @include _Upper_1920_width()
                        font-size: 38px
                .buttonGroup
                    +flex_Center_Setting(space-between)
                    width: 100%
                    max-width: 848px
                    margin: 50px auto 0 auto
                    button
                        +text_Setting(calc(14px + 0.8vw), 1, 1.9px, #525054, 400, "Noto Sans TC", center)
                        +flex_Center_Setting
                        width: calc(50% - 24px)
                        flex-direction: row-reverse
                        border: 3px solid #5D5F60
                        border-radius: 45px
                        box-shadow: 3px 3px 6px #00000029
                        padding: 20px 0
                        @include _Upper_1920_width()
                            font-size: 28px
                        img.icon
                            +size(40px)
                            margin-right: 15px
                    .renwu, .shulin
                        border: 3px solid transparent
                        color: #fafafa
                    .renwu
                        background-color: $title1_color
                    .shulin
                        background-color: $title2_color
                    @include _under_768_width()
                        button
                            width: 48%
                            padding: 13px 0
                    @include _under_576_width()
                        button img.icon
                            +size(25px)

            .timeLine_Body
                +size(100%, auto)
                +flex_Center_Setting(space-between, flex-start)
                margin-top: 122px
                position: relative
                @include _under_576_width()
                    margin-top: 70px
                &::before
                    +pseudoBlock(5px, 98%, #9B9999)
                    left: 50%
                    transform: translateX(-50%)

                // -----------------timeCard的CSS同捆包-----------------
                @mixin timeCardCSS
                    .timeCard
                        border-top: 4px solid rgba(#5C5C5E, 0.4)
                        position: relative
                        &::before
                            +pseudoBlock(30px, 30px, #5C5C5E)
                            opacity: 0.4
                            border-radius: 50%
                            top: -2px
                            transform: translateY(-50%)
                            @include _under_992_width()
                                +size(20px)
                        h3.year
                            +text_Setting(calc(16px + 0.8vw), 1, 3.2px, rgba(#5C5C5E, 0.4), 500, "Noto Sans TC", left)
                            position: absolute
                            top: -4px
                            transform: translateY(-50%)
                            background-color: #fff
                            @include _Upper_1920_width()
                                font-size: 32px
                        .imgBlock
                            +size(90%, auto)
                            min-width: 330px
                            max-width: 550px
                            border: 7px solid transparent
                            margin-top: 33px
                            padding: 10px
                            @include _under_992_width()
                                border-width: 5px
                                min-width: 310px
                            @include _under_375_width()
                                border-width: 3px
                                min-width: 270px
                            img
                                +size(100%)
                                opacity: 0.2
                            .cardText
                                +text_Setting(calc(14px + 0.4vw), 1.5, 2.2px, #FAFAFA, 400, "Noto Sans TC", center)
                                width: 100%
                                padding: 40px
                                position: absolute
                                left: 0
                                top: 50%
                                transform: translateY(-50%)
                                opacity: 0
                                @include _Upper_1920_width()
                                    font-size: 22px
                                @include _under_992_width()
                                    padding: 20px
                    
                    // 仁武廠的顏色class、樹林廠的顏色class共同設定，遮罩+hover
                    .renwu_Color, .shulin_Color
                        animation: fadeIn 0.5s
                        .imgBlock
                            position: relative
                            &::before
                                +pseudoBlock(calc(100% - 20px), calc(100% - 20px), #2F3234)
                                left: 10px
                                top: 10px
                                opacity: 0.45
                                transition: 0.5s
                            .cardText
                                opacity: 1
                            &:hover
                                &::before
                                    opacity: 0
                                .cardText
                                    opacity: 0

                    // 仁武廠的顏色class
                    .renwu_Color
                        border-top-color: $title1_color
                        h3.year
                            color: $title1_color
                        &::before
                            background-color: $title1_color
                            opacity: 1
                            z-index: 1
                        .imgBlock
                            border-color: $title1_color
                            position: relative
                            img
                                opacity: 1
                    
                    // 樹林廠的顏色class
                    .shulin_Color
                        border-top-color: $title2_color
                        h3.year
                            color: $title2_color
                        &::before
                            background-color: $title2_color
                            opacity: 1
                            z-index: 1
                        .imgBlock
                            border-color: $title2_color
                            img
                                opacity: 1
                    @content
                // -----------------timeCard的CSS同捆包-----------------

                &.timeLine_Desktop
                    .left_Column, .right_Column
                        width: calc(50% - 2.5px)
                        +timeCardCSS
                    
                    // 卡片距離function
                    @function cardSpace($level: 1)
                        @if $level <= 1
                            $level: 1
                        @return 70px * round($level)
                    .left_Column > .timeCard
                        &::before
                            // right: calc( -15px - 2.5px )
                            right: -2.5px
                            transform: translate(50%, -50%)
                        h3.year
                            left: 0
                            padding-right: 60px
                        &:nth-child(2)
                            // margin-top: 280px
                            margin-top: cardSpace(4)
                        &:nth-child(3)
                            margin-top: cardSpace(3)
                        &:nth-child(4)
                            margin-top: cardSpace(2)
                        &:nth-child(5)
                            margin-top: cardSpace(4)
                    .right_Column > .timeCard
                        display: flex
                        justify-content: flex-end
                        &::before
                            // left: calc( -15px - 2.5px )
                            left: -2.5px
                            transform: translate(-50%, -50%)
                        h3.year
                            right: 0
                            padding-left: 60px
                        &:nth-child(2)
                            margin-top: cardSpace(2)
                        &:nth-child(3)
                            margin-top: cardSpace(2)
                        &:nth-child(4)
                            margin-top: cardSpace(3)
                        &:nth-child(5)
                            margin-top: cardSpace(3)
                        &:nth-child(6)
                            margin-top: cardSpace(1)
            
                &.timeLine_Mobile
                    display: none
                    &::before
                        right: 0
                        left: auto
                        transform: none
                    .timeCard_Group
                        width: 100%
                        +timeCardCSS
                            .timeCard
                                margin-top: 100px
                                h3.year
                                    padding-right: 30px
                                &:nth-child(1)
                                    margin-top: 0
                                &::before
                                    right: 2.5px
                                    transform: translate(50%, -50%)

            @include _under_768_width()
                .timeLine_Body
                    &.timeLine_Desktop
                        display: none
                    &.timeLine_Mobile
                        display: flex
            @include _under_576_width()
                margin-top: 100px
        
        .button_more > a
            background-image: linear-gradient(97deg, #865fcd -23%, #5ab8eb 147%)
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16)
            margin: 100px auto 0 auto
            @include _under_576_width()
                margin: 60px auto 0 auto

// ==== 英文版CSS ====
.en 
    // 繼承tw css
    @extend .tw
.en #Company
    *
        // border: 1px solid #000
    .CompanyPage
        width: 85%
        .introduce2_Container 
            .comapny_Introduce .title
                h2, p
                    letter-spacing: normal
        .timeLine_Container
            .button_Body
                .buttonGroup
                    button
                        padding-left: 15px
                        padding-right: 15px
                        @include _under_576_width()
                            letter-spacing: normal
                            justify-content: center
                            img.icon
                                margin-right: 5px
            .timeLine_Body
                &.timeLine_Desktop
                    .left_Column, .right_Column
                        .timeCard .imgBlock .cardText
                            letter-spacing: normal
                            padding: 15px
                &.timeLine_Mobile
                    .timeCard_Group
                        .timeCard .imgBlock .cardText
                            letter-spacing: normal
                            padding: 15px
                @include _under_992_width()
                    &.timeLine_Desktop
                        display: none
                    &.timeLine_Mobile
                        display: flex
                @include _under_576_width()
                    &::before
                        width: 0
                    &.timeLine_Mobile
                        .timeCard_Group
                            .timeCard
                                border-top-color: transparent
                                margin-top: 70px
                                &::before
                                    display: none
                                .imgBlock
                                    width: 100%
                                    .cardText
                                        line-height: 1.2
</style>    