<template lang="pug">
main#UseCase
    pageTop(:pageTitle="$t('__MAIN_TITLE.UseCase')" imgSrc="UseCase/reference_page top img_desktop.jpg" imgSrc_Mobile="UseCase/reference_page top img_mobile.jpg")
    
    .UseCase_Container
        section(v-for="section of useCase_Data")
            .sectionTitle
                img.icon(:src="getImgUrl(section.icon)")
                h1.containerTitle {{section.sectionName}}
            .cardGroup
                .cardBody(v-for="(card, card_index) of section.cardGroup" :key="card_index")
                    h2.cardTitle # {{card.cardTitle}}
                    swiper.cardSwiper(:options='cardSwiper')
                        swiper-slide.cardSlide(v-for="(cardImg, index) of card.imgArray" :key="index")
                            img(:src="getImgUrl(cardImg)", :alt="card.locationTitle")
                        .cardSwiper-pagination(slot="pagination")
                        .swiper-button-prev(slot='button-prev')
                            b-icon.icon(icon="chevron-left")
                        .swiper-button-next(slot='button-next')
                            b-icon.icon(icon="chevron-right")
                    .text
                        h3.locationTitle {{card.locationTitle}}
                        .location
                            b-icon.location_Icon(icon="geo-alt-fill")
                            h4.locationArea {{card.locationArea}}
        Button_Style.button_more(:btnTitle="$t('__MAIN_TITLE.Contact')" linkType="routerName" link="Contact" arrow="right")
</template>

<script>
import pageTop from "@/components/pageTop.vue"
import Button_Style from "@/components/Button_Style.vue"

export default {
    metaInfo() {
        return{
            title: `${this.$t('__MAIN_TITLE.UseCase')}-`,
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: this.$t('__SUB_TITLE.UseCase.description')
                },
                // Open Graph / Facebook
                { property: 'og:url', content: `${this.$t('__MAIN.domainName_URL')}/UseCase` },
                { property: 'og:description', content: this.$t('__SUB_TITLE.UseCase.description') },

                // Twitter
                { property: 'twitter:url', content: `${this.$t('__MAIN.domainName_URL')}/UseCase` },
                { property: 'twitter:description', content: this.$t('__SUB_TITLE.UseCase.description') },
            ]
        }
    },

    components: {
        pageTop,
        Button_Style
    },

    data() {
        return{

            cardSwiper: {
                spaceBetween: 10,
                effect: 'slide',
                pagination: {
                    el: '.cardSwiper-pagination',
                    type: 'fraction',
                    clickable: true   // 滑鼠可點擊導覽點點
                },
                navigation: {
                    prevEl: '.swiper-button-prev',
                    nextEl: '.swiper-button-next'
                },
                // pagination: '.swiper-pagination',
                // autoplay: {delay: 5000},   // 輪播
                speed: 300,   // 切換速度
                loop: false,
                autoHeight: true
            }
        }
    },

    computed: {
        useCase_Data: {
            get() {
                return [
                    // 捲材類
                    {
                        sectionName: this.$t('__TYPE.coil'),
                        icon: "banner/icon01.png",
                        cardGroup: [
                            {
                                cardTitle: this.$t('__SUB_TITLE.UseCase.card_1.title'),
                                locationTitle: this.$t('__SUB_TITLE.UseCase.card_1.locationTitle'),
                                locationArea: this.$t('__SUB_TITLE.UseCase.card_1.locationArea'),
                                imgArray: [
                                    "UseCase/coil/reference pics_cgu university1.jpg",
                                    "UseCase/coil/reference pics_cgu university2.jpg",
                                    "UseCase/coil/reference pics_cgu university3.jpg",
                                ]
                            },
                            {
                                cardTitle: this.$t('__SUB_TITLE.UseCase.card_2.title'),
                                locationTitle: this.$t('__SUB_TITLE.UseCase.card_2.locationTitle'),
                                locationArea: this.$t('__SUB_TITLE.UseCase.card_2.locationArea'),
                                imgArray: [
                                    "UseCase/coil/reference pics_house1.jpg",
                                    "UseCase/coil/reference pics_house2.jpg",
                                    "UseCase/coil/reference pics_house3.jpg",
                                ]
                            },
                            {
                                cardTitle: this.$t('__SUB_TITLE.UseCase.card_3.title'),
                                locationTitle: this.$t('__SUB_TITLE.UseCase.card_3.locationTitle'),
                                locationArea: this.$t('__SUB_TITLE.UseCase.card_3.locationArea'),
                                imgArray: [
                                    "UseCase/coil/reference pics_Sixth Naphtha1.jpg",
                                    "UseCase/coil/reference pics_Sixth Naphtha2.jpg",
                                ]
                            },
                            {
                                cardTitle: this.$t('__SUB_TITLE.UseCase.card_4.title'),
                                locationTitle: this.$t('__SUB_TITLE.UseCase.card_4.locationTitle'),
                                locationArea: this.$t('__SUB_TITLE.UseCase.card_4.locationArea'),
                                imgArray: [
                                    "UseCase/coil/reference pics_hsuehshan tunnel1.jpg",
                                    "UseCase/coil/reference pics_hsuehshan tunnel2.jpg",
                                    "UseCase/coil/reference pics_hsuehshan tunnel3.jpg",
                                ]
                            },
                            {
                                cardTitle: this.$t('__SUB_TITLE.UseCase.card_5.title'),
                                locationTitle: this.$t('__SUB_TITLE.UseCase.card_5.locationTitle'),
                                locationArea: this.$t('__SUB_TITLE.UseCase.card_5.locationArea'),
                                imgArray: [
                                    "UseCase/coil/reference pics_suhua tunnel1.jpg",
                                    "UseCase/coil/reference pics_suhua tunnel2.jpg",
                                ]
                            },
                            {
                                cardTitle: this.$t('__SUB_TITLE.UseCase.card_6.title'),
                                locationTitle: this.$t('__SUB_TITLE.UseCase.card_6.locationTitle'),
                                locationArea: this.$t('__SUB_TITLE.UseCase.card_6.locationArea'),
                                imgArray: [
                                    "UseCase/coil/reference pics_forth tunnel1.jpg",
                                    "UseCase/coil/reference pics_forth tunnel2.jpg",
                                ]
                            },
                            {
                                cardTitle: this.$t('__SUB_TITLE.UseCase.card_7.title'),
                                locationTitle: this.$t('__SUB_TITLE.UseCase.card_7.locationTitle'),
                                locationArea: this.$t('__SUB_TITLE.UseCase.card_7.locationArea'),
                                imgArray: [
                                    "UseCase/coil/reference pics_Brazil pool.jpg",
                                ]
                            },
                            {
                                cardTitle: this.$t('__SUB_TITLE.UseCase.card_8.title'),
                                locationTitle: this.$t('__SUB_TITLE.UseCase.card_8.locationTitle'),
                                locationArea: this.$t('__SUB_TITLE.UseCase.card_8.locationArea'),
                                imgArray: [
                                    "UseCase/coil/reference pics_canada pool.jpg",
                                ]
                            },
                            {
                                cardTitle: this.$t('__SUB_TITLE.UseCase.card_9.title'),
                                locationTitle: this.$t('__SUB_TITLE.UseCase.card_9.locationTitle'),
                                locationArea: this.$t('__SUB_TITLE.UseCase.card_9.locationArea'),
                                imgArray: [
                                    "UseCase/coil/reference pics_USA pool.jpg",
                                ]
                            },
                            {
                                cardTitle: this.$t('__SUB_TITLE.UseCase.card_10.title'),
                                locationTitle: this.$t('__SUB_TITLE.UseCase.card_10.locationTitle'),
                                locationArea: this.$t('__SUB_TITLE.UseCase.card_10.locationArea'),
                                imgArray: [
                                    "UseCase/coil/reference pics_AUS pool1.jpg",
                                    "UseCase/coil/reference pics_AUS pool2.jpg",
                                    "UseCase/coil/reference pics_AUS pool3.jpg",
                                ]
                            },
                            {
                                cardTitle: this.$t('__SUB_TITLE.UseCase.card_11.title'),
                                locationTitle: this.$t('__SUB_TITLE.UseCase.card_11.locationTitle'),
                                locationArea: this.$t('__SUB_TITLE.UseCase.card_11.locationArea'),
                                imgArray: [
                                    "UseCase/coil/reference pics_redmud chaiyi.jpg",
                                ]
                            },
                            {
                                cardTitle: this.$t('__SUB_TITLE.UseCase.card_12.title'),
                                locationTitle: this.$t('__SUB_TITLE.UseCase.card_12.locationTitle'),
                                locationArea: this.$t('__SUB_TITLE.UseCase.card_12.locationArea'),
                                imgArray: [
                                    "UseCase/coil/reference pics_redmud tainan.jpg",
                                ]
                            },
                        ],
                    },
                    // 塗料類
                    {
                        sectionName: this.$t('__TYPE.coating'),
                        icon: "banner/icon02.png",
                        cardGroup: [
                            {
                                cardTitle: this.$t('__SUB_TITLE.UseCase.card_13.title'),
                                locationTitle: this.$t('__SUB_TITLE.UseCase.card_13.locationTitle'),
                                locationArea: this.$t('__SUB_TITLE.UseCase.card_13.locationArea'),
                                imgArray: [
                                    "UseCase/coating/reference pics_cgu university1.jpg",
                                    "UseCase/coating/reference pics_cgu university2.jpg",
                                ]
                            },
                            {
                                cardTitle: this.$t('__SUB_TITLE.UseCase.card_14.title'),
                                locationTitle: this.$t('__SUB_TITLE.UseCase.card_14.locationTitle'),
                                locationArea: this.$t('__SUB_TITLE.UseCase.card_14.locationArea'),
                                imgArray: [
                                    "UseCase/coating/reference pics_YFY UASB1.jpg",
                                    "UseCase/coating/reference pics_YFY UASB2.jpg",
                                ]
                            },
                            {
                                cardTitle: this.$t('__SUB_TITLE.UseCase.card_15.title'),
                                locationTitle: this.$t('__SUB_TITLE.UseCase.card_15.locationTitle'),
                                locationArea: this.$t('__SUB_TITLE.UseCase.card_15.locationArea'),
                                imgArray: [
                                    "UseCase/coating/Miaoli_01.jpg",
                                    "UseCase/coating/Miaoli_02.jpg",
                                ]
                            },
                            {
                                cardTitle: this.$t('__SUB_TITLE.UseCase.card_16.title'),
                                locationTitle: this.$t('__SUB_TITLE.UseCase.card_16.locationTitle'),
                                locationArea: this.$t('__SUB_TITLE.UseCase.card_16.locationArea'),
                                imgArray: [
                                    "UseCase/coating/Yilan_01.jpg",
                                    "UseCase/coating/Yilan_02.jpg",
                                ]
                            },
                            {
                                cardTitle: this.$t('__SUB_TITLE.UseCase.card_17.title'),
                                locationTitle: this.$t('__SUB_TITLE.UseCase.card_17.locationTitle'),
                                locationArea: this.$t('__SUB_TITLE.UseCase.card_17.locationArea'),
                                imgArray: [
                                    "UseCase/coating/Taiwan_01.jpg",
                                    "UseCase/coating/Taiwan_02.jpg",
                                    "UseCase/coating/Taiwan_03.jpg",
                                    "UseCase/coating/Taiwan_04.jpg",
                                ]
                            },
                        ],
                    }
                ]
            }
        }
    },

    methods: {
        getImgUrl(pic) {
            return require('@/assets/' + pic)
        },
    },
}
</script>

<style lang="sass">
@import "@/css/my_Style.sass"
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap');

.tw #UseCase
    .UseCase_Container
        +size(90%, auto)
        max-width: $max_Container_Width
        margin: 95px auto
        @include _under_768_width()
            width: 80%
            margin: 60px auto
        section
            h1.containerTitle
                +text_Setting(calc(22px + 0.9vw), 1, 1.9px, $title1_color, 500, "Noto Sans TC", left)
                @include _Upper_1920_width()
                    font-size: 38px
            h2.cardTitle
                +text_Setting(calc(16px + 0.3vw), 1, 1.9px, $title1_color, 500, "Noto Sans TC", left)
                display: inline-block
                border-bottom: 2px solid $title1_color
                padding-bottom: 10px
                @include _Upper_1920_width()
                    font-size: 24px
            h3.locationTitle
                +text_Setting(calc(16px + 0.4vw), 1, 1.9px, #fafafa, 400, "Noto Sans TC", left)
                @include _Upper_1920_width()
                    font-size: 24px
            h4.locationArea
                +text_Setting(calc(14px + 0.3vw), 1, 1.9px, #fafafa, 300, "Noto Sans TC", left)
                margin-left: 5px
                @include _Upper_1920_width()
                    font-size: 20px
            &:nth-child(2)
                margin-top: 100px
                h1, h2
                    color: $title2_color
                    border-bottom-color: $title2_color
            
            // & 
            .sectionTitle
                +flex_Center_Setting(flex-start)
                border-bottom: 1px solid #707070
                padding-bottom: 20px
                .icon
                    +size(60px)
                    margin-right: 30px
                @include _under_576_width()
                    padding-bottom: 10px
                    .icon
                        +size(50px)
                        margin-right: 20px
            .cardGroup
                display: flex
                flex-wrap: wrap
                margin-top: 50px
                .cardBody
                    +size(31%, auto)
                    position: relative
                    &:nth-child(3n + 2)
                        margin: 0 3.5%
                        @include _under_768_width()
                            margin: 4.5%  0
                    &:nth-child(3) ~ .cardBody
                        margin-top: 80px
                    
                    .cardSwiper
                        padding-bottom: 50px
                        margin-top: 20px
                        position: relative
                        .cardSlide
                            img
                                +size(100%)
                        .cardSwiper-pagination
                            +size(100%, auto)
                            position: absolute
                            bottom: 0
                            z-index: 1
                            font-size: font(4)
                            font-weight: 500
                            text-align: center
                            color: #9B9F9F
                            span:nth-child(1)
                                color: #557FE8
                        &::before
                            +pseudoBlock(100%, calc(100% - 50px))
                            top: 0
                            z-index: 2
                            background: linear-gradient(to top, #000 0%, #7c7979 25%, transparent 100%)
                            opacity: 0.4
                    .swiper-button-prev, .swiper-button-next
                        +size(38%, 25px)
                        display: flex
                        top: inherit
                        bottom: 0
                        color: #9B9F9F
                        margin-top: 0
                        &::after
                            display: none
                        .icon
                            +size(auto, 100%)
                    .swiper-button-prev
                        left: 0
                        justify-content: flex-end
                    .swiper-button-next
                        right: 0
                        justify-content: flex-start

                    .text
                        +size(100%, auto)
                        +flex_Center_Setting(flex-end, flex-end)
                        flex-wrap: wrap
                        position: absolute
                        bottom: calc(50px + 20px)
                        right: 0
                        padding-right: 3%
                        z-index: 1
                        pointer-events: none
                        .location
                            +flex_Center_Setting(center, flex-end)
                            margin-left: 15px
                            margin-top: 10px
                            .location_Icon
                                color: #fff

                @include _under_768_width()
                    flex-wrap: nowrap
                    flex-direction: column
                    .cardBody
                        width: 100%
                        &:nth-child(1) ~ .cardBody
                            margin: 50px 0 0

        .button_more > a
            background-image: linear-gradient(97deg, #865fcd -23%, #5ab8eb 147%)
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16)
            margin: 150px auto 0 auto
            @include _under_768_width()
                margin-top: 80px

// ==== 英文版CSS ====
.en 
    // 繼承tw css
    @extend .tw
.en #UseCase
    *
        // border: 1px solid #000
    .UseCase_Container section
        h2.cardTitle, h3.locationTitle, h4.locationArea
            letter-spacing: normal
        h2.cardTitle, h3.locationTitle
            font-size: 20px
            @include _under_992_width()
                font-size: 16px
        h4.locationArea
            font-size: 18px
            @include _under_992_width()
                font-size: 14px
        .cardGroup .cardBody
            .text
                .locationTitle
                    width: 100%
                    text-align: right
                .location
                    margin-left: 0
</style>        