<template lang="pug">
#app(:class="[ {appScrollsDown: scrollsDown_to_hide}, get_CurrentLanguage]")
    navbar(:navbar_hide_Control="scrollsDown_to_hide")
    transition(name='routerAni' mode='out-in')
        router-view
    footer_Container
    button.backButton(:class="{backBtn_ScrollsDown: scrollsDown_to_hide}" v-scroll-to="{el:'#navbar'}")
        b-icon(icon="chevron-up")
</template>

<script>
import navbar from "@/section_components/navbar.vue"
import footer_Container from "@/section_components/footer.vue"
// import { mapGetters } from 'vuex'
// import Global_Function from "@/Global_Function.js"
// import {debounce} from "@/Global_Function.js"
// import footer_section from "@/section_components/footer.vue"

export default {
    metaInfo() {
        return{
            htmlAttrs: {
                lang: this.$t('__MAIN.html_lang')
            },
            title: null,
            titleTemplate: `%s${this.$t('__MAIN_TITLE.WebsiteTitle')}`,
            meta: [
                { name: 'title', content: this.$t('__MAIN_TITLE.WebsiteName') },

                // Open Graph / Facebook
                { property: 'og:type', content: 'website' },
                { property: 'og:title', content: this.$t('__MAIN_TITLE.WebsiteTitle') },
                { property: 'og:site_name', content: this.$t('__MAIN_TITLE.WebsiteName') },
                { property: 'og:image', content: 'https://www.nanya-waterproof.com/og image-NAN YA.jpg' },
                
                // Twitter
                { property: 'twitter:title', content: this.$t('__MAIN_TITLE.WebsiteTitle') },
                { property: 'twitter:card', content: "summary_large_image" },
                { property: 'twitter:image', content: "https://www.nanya-waterproof.com/og image-NAN YA.jpg" }
            ]
        }
    },

    name: "App",
    // mixins: [],
    components: {
        navbar,
        footer_Container
    },

    data() {
        return{
            scrollsDown_to_hide: false
        }
    },

    // 取當下語系值
    computed: {
        get_CurrentLanguage() {
            // return this.$store.state.currentLanguage === 'tw' ? '' : this.$store.state.currentLanguage
            return this.$store.state.currentLanguage
        }
    },

    // methods: {},

    mounted() {

        // 網頁向下捲動隱藏控制
        let prev_ScrollPosition = window.pageYOffset
        window.onscroll = () => {
            let current_ScrollPosition = window.pageYOffset
            
            // 原始 < 當下的Y位置，則隱藏 (三元運算式Expressions - Conditional operator)
            prev_ScrollPosition < current_ScrollPosition ? 
                this.scrollsDown_to_hide = true : 
                this.scrollsDown_to_hide = false

            prev_ScrollPosition = current_ScrollPosition
        }

        // console.log('%c%s', 'color: green; background: red; font-size: 74px;','陳志信說')
    },

    created() {
        let timer = null
        window.onresize = () => {
            if(timer != null){
                clearTimeout(timer)
            }
            timer = setTimeout(()=>{
                this.$store.commit('windows_resize_intoVuex')
            },15)
        }
    },

    // beforeCreate() {}
};
</script>

<style lang="sass">
@import "@/css/my_Style.sass"
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Display:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "@/css/resetCSS.sass"

+resetCSS()

*
    // border: 1px solid #000

#app
    padding-top: 85px
    overflow: hidden
    transition: padding-top 0.5s
    @include _under_768_width
        padding-top: 70px

    .backButton
        +size(60px)
        border: 2px solid white
        background-color: #212529
        border-radius: 50%
        padding: 15px
        position: fixed
        bottom: 30px
        right: 30px
        z-index: 100
        transition: 0.3s
        svg.b-icon
            +size(100%)
            color: white
        &:hover
            transform: scale(1.1)
        @include _under_992_width()
            +size(50px)
            padding: 10px
            transform: translateX(0)
        @include _under_576_width()
            bottom: 20px
            right: 20px
    .backButton.backBtn_ScrollsDown
        bottom: -100px

#app.appScrollsDown
    @include _under_768_width
        padding-top: 0

.routerAni-enter, .routerAni-leave-to
    opacity: 0
.routerAni-enter-to, .routerAni-leave
    opacity: 1
.routerAni-enter-active
    animation: fadeIn 0.3s
.routerAni-leave-active
    animation: fadeOut 0.3s
</style>
