








import Vue from 'vue'

export default Vue.extend( {
    props: {
        pageTitle: String,
        imgSrc: String,
        imgSrc_Mobile: {
            type: String,
            default: null
        }
    },

    data() {
        return{
            breadcrumb_link_data: [
                {
                    text: this.$t('__MAIN_TITLE.Home'),
                    to: { path: `/${this.$store.getters.computed_routeName_Lang_from_Vuex}`},
                    active: false
                },
            ],

            productsType_RouteName: "",
        }
    },

    computed: {
        breadcrumb_link: {
            get(): Array<object> {
                // this.breadcrumb_link_data.push(this.getRouter())
                return this.breadcrumb_link_data
            },
            set(inputArray: any) {
                this.breadcrumb_link_data.push(inputArray)
            }
        },
        getProducts_Type: {
            get() {
                return this.$router.currentRoute.params.type
            }
        }
    },

    methods: {
        getImgUrl(pic: string) {
            return require('@/assets/' + pic)
        },

        // 當下頁面的path切割，return成字串陣列
        return_currentPageArray() {
            return window.location.pathname.split('/').filter(el => {return el})
        },

        // 產品資訊router單獨處理
        getProductsRouter(input_CurrentPageArray: Array<string>) {
            const type: string = this.$router.currentRoute.params.type  //取得當下產品類別:coil或coating
            
            //取得當下語系，無的話空白
            const lang: string
                = this.$router.currentRoute.params.lang ? "_lang" : "" 
            // console.log(lang);
            // 第一層產品資訊
            this.breadcrumb_link = {
                text: `${this.$t('__MAIN_TITLE.Products')}-${this.$t(`__TYPE.${type}`)}`,
                to: {name: `Products${lang}`},
                active: false
            }
            
            // if當下的page是在個別產品，從input陣列pop出產品的name
            const productName = input_CurrentPageArray.pop()
            if(productName){
                this.breadcrumb_link = {
                    text: this.$t(`__PRODUCTS.${productName}.title`),
                    to: {name: `${productName}${lang}`},
                    active: false
                }
            }
        },

        getRouter() {
            const currentPageArray: Array<string> = this.return_currentPageArray()
            // const paramsType = this.$router.currentRoute.params.type

            // 路徑的Array第一個值代表網址中含有語系代碼，有的話shift掉
            if(currentPageArray[0].length === 2){
                currentPageArray.shift()
            }

            currentPageArray[0].length === 2 ? currentPageArray.shift() : false
        
            // if產品類別的params，return getProductsRouter的function
            const hasParams_productType: Boolean = Boolean(this.$router.currentRoute.params.type)
            if(hasParams_productType){
                currentPageArray.shift()
                return this.getProductsRouter(currentPageArray)
            }

            while(currentPageArray.length > 0) {
                const routeName: string = currentPageArray.shift() || ""
                this.breadcrumb_link = {
                    text: this.$t(`__MAIN_TITLE.${routeName}`),
                    to: {name: routeName},
                    active: false
                }
            }
        }
    },

    created() {
        this.getRouter()
    },

    watch: {
        $route(to) {
            const productType: string = to.params.type || ""
            if(productType){
                this.breadcrumb_link[1].text = `${this.$t('__MAIN_TITLE.Products')}-${this.$t(`__TYPE.${productType}`)}`
            }
        },
    }
} )
