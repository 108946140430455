

























































































import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend( {
    data() {
        return{
            year: 2021
        }
    },
    computed: {
        // 從Vuex拿getters
        ...mapGetters(['computed_routeName_Lang_from_Vuex', 'computed_routeName_Lang_addBaseline_from_Vuex']),

        // products_List: {
        //     get() {
        //         return {
        //             coil: {
        //                 tough_Waterproof: {
        //                     title: '強韌防水膜',
        //                     routeName: 'tough_Waterproof'
        //                 },

        //             }
        //         }
        //     }
        // }
    }
    // methods: {},
    // created(): void {}
} )
