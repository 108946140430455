<template lang="pug">
main#Certification
    pageTop(:pageTitle="$t('__MAIN_TITLE.Certification')" imgSrc="Certification/quality_page top img_desktop.jpg" imgSrc_Mobile="Certification/quality_page top img_mobile.jpg")
    section.CertificationPage
        .certification_Introduction_Container
            .BSMI_Group
                .BSMI_img(v-for="n in 4")
                    img(:src="require(`@/assets/Certification/BSMI_0${n}.jpg`)", :alt="$t('__SUB_TITLE.Certification.altText_1')")
            .title
                h1.containerTitle {{$t('__SUB_TITLE.Certification.BSMI_Title')}}
                p.introText {{$t('__SUB_TITLE.Certification.page_Introduce')}}
        //- 仁武廠 (捲材類)
        section.block_Container.RenwuBlock
            h1.containerTitle {{Renwu_certificate.title}}
            .certificate_Group
                .certificate(v-for="cert of Renwu_certificate.certificate")
                    img(:src="getImgUrl(cert.certImg)", :alt="cert.img_altText")
                    a.fileLink(:href="`./PDF/Certification/${cert.certFile}`", :title="cert.certTitle" target="_blank") {{cert.certTitle}}
                        b-icon(icon="download")
        //- 樹林廠 (塗料類)
        section.block_Container.ShulinBlock
            h1.containerTitle {{Shulin_certificate.title}}
            .certificate_Group
                .certificate(v-for="cert of Shulin_certificate.certificate")
                    img(:src="getImgUrl(cert.certImg)", :alt="cert.img_altText")
                    a.fileLink(:href="`./PDF/Certification/${cert.certFile}`", :title="cert.certTitle" target="_blank") {{cert.certTitle}}
                        b-icon(icon="download")
            //- 南亞樹林廠 榮獲綠建材建材標章
            .greenMaterial
                .title
                    h2.greenMaterialTitle {{$t('__SUB_TITLE.Certification.greenMaterialTitle')}}
                .contentGroup
                    p.greenMaterialText {{$t('__SUB_TITLE.Certification.greenMaterialText')}}
                    a.fileLink(href="./PDF/Certification/greenbuliding_Certification.jpg", title="" target="_blank") {{$t('__SUB_TITLE.Company.Title_2_shulin')}} {{$t('__SUB_TITLE.Certification.certTitle_5')}}
                        b-icon(icon="download")
                .greenImg
                    img(src="@/assets/Certification/semi_green_info_01_logo.png", :alt="$t('__SUB_TITLE.Certification.greenMaterial_altText')")

        Button_Style.button_more(:btnTitle="$t('__MAIN_TITLE.Contact')" linkType="routerName" link="Contact" arrow="right")
</template>

<script>
import pageTop from "@/components/pageTop.vue"
import Button_Style from "@/components/Button_Style.vue"

export default {
    metaInfo() {
        return{
            title: `${this.$t('__MAIN_TITLE.Certification')}-`,
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: this.$t('__SUB_TITLE.Certification.description')
                },
                // Open Graph / Facebook
                { property: 'og:url', content: `${this.$t('__MAIN.domainName_URL')}/Certification` },
                { property: 'og:description', content: this.$t('__SUB_TITLE.Certification.description') },

                // Twitter
                { property: 'twitter:url', content: `${this.$t('__MAIN.domainName_URL')}/Certification` },
                { property: 'twitter:description', content: this.$t('__SUB_TITLE.Certification.description') },
            ]
        }
    },

    components: {
        pageTop,
        Button_Style
    },

    // data() {
    //     return{}
    // },

    computed: {
        Renwu_certificate: {
            get() {
                return {
                    title: `${this.$t('__SUB_TITLE.Company.Title_1_renwu')} (${this.$t('__TYPE.coil')})`,
                    certificate: [
                        {
                            certTitle: `${this.$t('__SUB_TITLE.Company.Title_1_renwu')} \n ${this.$t('__SUB_TITLE.Certification.certTitle_1')}`,
                            certImg: "Certification/Renwu/ISO9001_Renwu_1.jpg",
                            img_altText: this.$t('__SUB_TITLE.Certification.renwu_Text') + this.$t('__SUB_TITLE.Certification.certTitle_1'),  // 南亞仁武廠XXX證書
                            certFile: this.$t('__SUB_TITLE.Certification.certFile_renwu[0]')
                        },
                        {
                            certTitle: `${this.$t('__SUB_TITLE.Company.Title_1_renwu')} \n ${this.$t('__SUB_TITLE.Certification.certTitle_2')}`,
                            certImg: "Certification/Renwu/ISO14001_Renwu_2.jpg",
                            img_altText: this.$t('__SUB_TITLE.Certification.renwu_Text') + this.$t('__SUB_TITLE.Certification.certTitle_2'),  // 南亞仁武廠XXX證書
                            certFile: this.$t('__SUB_TITLE.Certification.certFile_renwu[1]')
                        },
                        {
                            certTitle: `${this.$t('__SUB_TITLE.Company.Title_1_renwu')} \n ${this.$t('__SUB_TITLE.Certification.certTitle_3')}`,
                            certImg: "Certification/Renwu/OHSAS18001_Renwu_3.jpg",
                            img_altText: this.$t('__SUB_TITLE.Certification.renwu_Text') + this.$t('__SUB_TITLE.Certification.certTitle_3'),  // 南亞仁武廠XXX證書
                            certFile: this.$t('__SUB_TITLE.Certification.certFile_renwu[2]')
                        },
                        {
                            certTitle: `${this.$t('__SUB_TITLE.Company.Title_1_renwu')} \n ${this.$t('__SUB_TITLE.Certification.certTitle_4')}`,
                            certImg: "Certification/Renwu/TAF_Renwu_4.jpg",
                            img_altText: this.$t('__SUB_TITLE.Certification.renwu_Text') + this.$t('__SUB_TITLE.Certification.certTitle_4'),  // 南亞仁武廠XXX證書
                            certFile: this.$t('__SUB_TITLE.Certification.certFile_renwu[3]')
                        }
                    ]
                }
            }
        },

        Shulin_certificate: {
            get() {
                return {
                    title: `${this.$t('__SUB_TITLE.Company.Title_2_shulin')} (${this.$t('__TYPE.coating')})`,
                    certificate: [
                        {
                            certTitle: `${this.$t('__SUB_TITLE.Company.Title_2_shulin')} \n ${this.$t('__SUB_TITLE.Certification.certTitle_1')}`,
                            certImg: "Certification/Shulin/ISO9001_Shulin.jpg",
                            img_altText: this.$t('__SUB_TITLE.Certification.shulin_Text') + this.$t('__SUB_TITLE.Certification.certTitle_1'),  // 南亞樹林廠XXX證書
                            certFile: this.$t('__SUB_TITLE.Certification.certFile_shulin[0]')
                        },
                        {
                            certTitle: `${this.$t('__SUB_TITLE.Company.Title_2_shulin')} \n ${this.$t('__SUB_TITLE.Certification.certTitle_2')}`,
                            certImg: "Certification/Shulin/ISO14001_Shulin.jpg",
                            img_altText: this.$t('__SUB_TITLE.Certification.shulin_Text') + this.$t('__SUB_TITLE.Certification.certTitle_2'),  // 南亞樹林廠XXX證書
                            certFile: this.$t('__SUB_TITLE.Certification.certFile_shulin[1]')
                        },
                        {
                            certTitle: `${this.$t('__SUB_TITLE.Company.Title_2_shulin')} \n ${this.$t('__SUB_TITLE.Certification.certTitle_3')}`,
                            certImg: "Certification/Shulin/OHSAS18001_Shulin.jpg",
                            img_altText: this.$t('__SUB_TITLE.Certification.shulin_Text') + this.$t('__SUB_TITLE.Certification.certTitle_3'),  // 南亞樹林廠XXX證書
                            certFile: this.$t('__SUB_TITLE.Certification.certFile_shulin[2]')
                        },
                        {
                            certTitle: `${this.$t('__SUB_TITLE.Company.Title_2_shulin')} \n ${this.$t('__SUB_TITLE.Certification.certTitle_4')}`,
                            certImg: "Certification/Shulin/TAF_Shulin.jpg",
                            img_altText: this.$t('__SUB_TITLE.Certification.shulin_Text') + this.$t('__SUB_TITLE.Certification.certTitle_4'),  // 南亞樹林廠XXX證書
                            certFile: this.$t('__SUB_TITLE.Certification.certFile_shulin[3]')
                        }
                    ]
                }
            }
        }
    },

    methods: {
        getImgUrl(pic) {
            return require('@/assets/' + pic)
        },
    },
}
</script>

<style lang="sass">
@import "@/css/my_Style.sass"
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap');

.tw #Certification
    *
        // border: 1px solid #000

    h1.containerTitle
        +text_Setting(calc(22px + 1vw), 1.5, 2.1px, #292929, 500, "Noto Sans TC", center)
        padding: 0 5%
        @include _Upper_1920_width()
            font-size: 38px
        @include _under_768_width()
            white-space: pre-line

    h2.greenMaterialTitle
        +text_Setting(calc(20px + 1.3vw), 1.5, 2.1px, #005725, 500, "Noto Sans TC", left)
        @include _Upper_1920_width()
            font-size: 42px

    p.introText
        +text_Setting(calc(16px + 0.4vw), 1.67, 0.24px, #292929, 400, "Noto Sans TC", center)
        margin-top: 30px
        @include _Upper_1920_width()
            font-size: 24px
    
    p.greenMaterialText
        +text_Setting(calc(16px + 0.2vw), 1.6, 2px, #005725, 500, "Noto Sans TC", left)
        @include _Upper_1920_width()
            font-size: 20px

    a.fileLink
        +text_Setting(calc(14px + 0.25vw), 1.33, 0.9px, #47515b, 500, "Noto Sans TC", left)
        border-bottom: 1px solid #06192a
        padding-bottom: 6px
        @include _Upper_1920_width()
            font-size: 18px
        @include _under_576_width()
            font-size: 14px
            white-space: pre-line

    
    .CertificationPage
        padding: 100px 0
        .certification_Introduction_Container
            +size(80%, auto)
            margin: 0 auto
            max-width: $max_Container_Width
            .BSMI_Group
                +flex-Center_Setting(space-between)
                .BSMI_img
                    +size(25%, auto)
                    img
                        +size(100%)
            .title
                margin-top: 50px
        @include _under_576_width()
            padding: 40px 0
            .certification_Introduction_Container
                .BSMI_Group
                    flex-wrap: wrap
                    .BSMI_img
                        width: 50%

        .block_Container
            +size(100%, auto)
            margin-top: 100px
            padding: 60px 0 110px
            position: relative
            &::before
                +pseudoBlock(100%, 12px)
                bottom: 0
                opacity: 0.7
            .certificate_Group
                +size(90%, auto)
                display: flex
                justify-content: space-between
                margin: 60px auto 0
                max-width: $max_Container_Width
                .certificate
                    width: 23%
                    display: flex
                    flex-direction: column
                    justify-content: space-between
                    img
                        +size(100%)
                        box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.28)
                    a.fileLink
                        +flex-Center_Setting(space-around)
                        margin-top: 40px
            @include _under_768_width()
                padding-top: 52px
                .certificate_Group
                    width: 80%
                    flex-wrap: wrap
                    .certificate
                        width: 48%
                        &:nth-child(2) ~ div
                            margin-top: 60px
            @include _under_375_width()
                margin-top: 70px
                .certificate_Group
                    flex-wrap: nowrap
                    flex-direction: column
                    align-items: center
                    .certificate
                        width: 80%
                        margin: 0
                        &:nth-child(1) ~ div
                            margin-top: 40px
                        a.fileLink
                            margin-top: 20px

        .RenwuBlock
            background-color: $container1_color
            &::before
                background-image: linear-gradient(92deg, #5ab8eb 2%, #6a71dc 50%, #865fcd 98%)
            h1.containerTitle
                color: $title1_color
        .ShulinBlock
            background-color: $container2_color
            &::before
                background-image: linear-gradient(192deg, #5ab8eb 2%, #6a71dc 50%, #865fcd 98%)
            h1.containerTitle
                color: $title2_color
            .greenMaterial
                +size(80%, auto)
                +flex-Center_Setting(space-between, flex-start)
                flex-direction: column
                position: relative
                max-width: $max_Container_Width + 100px
                border: solid 3px #bfef3c
                border-radius: 24px
                background-color: #fff
                margin: 80px auto 0
                padding: 50px 70px 50px 100px
                .title
                    width: calc(100% - 312px - 35px)
                .contentGroup
                    width: calc(100% - 312px - 35px)
                    margin-top: 25px
                    display: flex
                    align-items: flex-start
                    flex-direction: column
                    p.greenMaterialText
                        width: 100%
                    //     margin-top: 25px
                    a.fileLink
                        display: inline-block
                        margin-top: 50px
                        .b-icon
                            +size(20px)
                            // margin-left: 25px
                .greenImg
                    +size(312px)
                    position: absolute
                    top: 50%
                    right: 70px
                    transform: translateY(-50%)
                    img
                        +size(100%)
            @include _under_992_width()
                .greenMaterial
                    padding-left: 45px
                    padding-right: 45px
                    .title, .contentGroup
                        width: calc(100% - 250px - 15px)
                    .greenImg
                        +size(250px)
                        right: 45px
            @include _under_768_width()
                .greenMaterial
                    align-items: center
                    .title, .contentGroup
                        +size(100%)
                    .title
                        order: 1
                        h2.greenMaterialTitle
                            text-align: center
                            white-space: pre-line
                    .contentGroup
                        order: 3
                        align-items: center
                        p.greenMaterialText
                            text-align: center
                        a.fileLink
                            text-align: center
                            margin-top: 30px
                    .greenImg
                        +size(50%, auto)
                        min-width: 200px
                        position: static
                        transform: none
                        order: 2
                        margin-top: 16px
            @include _under_576_width()
                .greenMaterial
                    +size(88%, auto)
                    padding-left: 40px
                    padding-right: 40px
        .button_more > a
            background-image: linear-gradient(97deg, #865fcd -23%, #5ab8eb 147%)
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16)
            margin: 100px auto 0
            @include _under_576_width()
                margin: 40px auto 0

// ==== 英文版CSS ====
.en 
    // 繼承tw css
    @extend .tw
.en #Certification
    *
        // border: 1px solid #000
    h1.containerTitle, h2.greenMaterialTitle
        letter-spacing: 1px
        font-size: 30px
        @include _under_992_width()
            font-size: 26px
        @include _under_768_width()
            font-size: 22px
        @include _under_576_width()
            font-size: 18px
    p.introText, p.greenMaterialText
        letter-spacing: normal
        line-height: 1.3
        font-size: 22px
        @include _under_992_width()
            font-size: 20px
        @include _under_768_width()
            font-size: 18px
        @include _under_576_width()
            font-size: 14px
    h1.containerTitle
        padding: 0
    a.fileLink
        letter-spacing: normal
        font-size: 16px
        white-space: initial
        @include _under_768_width()
            font-size: 14px
    
    .CertificationPage
        .block_Container
            .certificate_Group .certificate
                a.fileLink
                    flex-flow: row-reverse
                    justify-content: center
                    // white-space: pre-line
                    // @include _under_992_width()
                    //     white-space: pre-line
                    // @include _under_768_width()
                    //     white-space: initial
                    @include _under_576_width()
                        white-space: pre-line
                    .b-icon
                        margin-right: 15px
        .ShulinBlock
            .greenMaterial
                padding: 50px
                @include _under_768_width()
                    padding: 40px 25px
                .contentGroup a.fileLink
                    margin-top: 25px
                    display: flex
                    align-items: center
                    flex-flow: row-reverse
                    text-align: left
                    .b-icon
                        margin-right: 25px
                    // @include _under_768_width()
                    //     +flex_Center_Setting(space-around)
            
</style>        