// en.js
export const locale = {
    __MAIN: {
        domainName_URL: 'https://www.nanya-waterproof.com/en',
        html_lang: 'en-US'
    },
    __MAIN_TITLE: {
        WebsiteName: 'Nan Ya Waterproof',
        WebsiteTitle: 'Nan Ya Waterproof・PVC Film・PU Coating | Top-level protection without any water leakage',
        Home: 'Home',
        Company: 'Company',
        Products: 'Products',
        Products_T2: 'Products Information',
        UseCase: 'Application',
        QA_Page: 'FAQ',
        Certification: 'Certification',
        Contact: 'Contact',
        News: 'News',
        Company_Name: 'Nan Ya Plastics Corporation',
        Division: '1st Division',
        CopyrightRef: '(NPC, Mar 1, 2023)'
    },
    __MAIN_TITLE_TEXT: {
        ContactText: "If there's any advice or suggestion, welcome to email or call us.We're willing to respond soon and appreciate your feedbacks."
    },
    __NEWS_LIST: [
        'Both Shu-Lin No.1 Plant and Jen-Wu No.2 Plant obtained TAF qualified certificates in December 2020.',
        'Shu-Lin No.1 Factory was awarded the Green Building Material Certificates in June 2020.'
    ],
    __TYPE: {
        coil: 'membrane',
        coating: 'coating'
    },
    __BUTTON_TEXT: {
        more: 'More',
        more_NanYaProducts: "More Nan Ya Products",
        more_Products: 'More',
        PlasticMallName: 'Nan Ya Plastic Mall',
        PlasticMall_Link: 'https://www.nanya-plastics-mall.com',
        more_NanYaCyberShoppingMall: "NAN YA Cyber Shopping Mall",
        Contact: 'Contact us',
        sendData: 'Submit',
        backHome: 'Home',
        getProducts: 'Sample request',
        case: 'Application'
    },
    __BANNER: {
        __BANNER_0: {
            __TITLE1: 'Nan Ya high-quality waterproof materials,\n top-level protection without any water leakage!',
            __TITLE2: '"From tunnels to hillsides, basements to roofs, reservoirs to swimming pools, provide you with comprehensive protection."',
            img_alt: ''
        },
        __BANNER_1: {
            __TITLE1: 'Achieve the simplest waterproof membrane\n with the highest quality',
            __TITLE2: 'Nan Ya waterproof membrane with good physical strength,\n durability and good process ability, apply in various civil and environmental engineering.',
            img_alt: ''
        },
        __BANNER_2: {
            __TITLE1: 'Exclusively customized production,\n international fashion trends',
            __TITLE2: 'Nan Ya swimming pool liner meets international standards and has passed various anti-mildew,\n antibacterial and chlorine resistance tests, apply in above-ground and in-ground swimming pools.',
            img_alt: ''
        },
        __BANNER_3: {
            __TITLE1: 'Superior materials to give you safe driving in the tunnel',
            __TITLE2: 'Nan Ya tunnel waterproof membrane uses high-strength formula,\n superior physical properties and good process ability, apply in various tunnel engineering.',
            img_alt: ''
        },
        __BANNER_4: {
            __TITLE1: 'Regardless of wind, sun or rain,\n always keep you not dripping',
            __TITLE2: 'Nan Ya roof waterproof membrane has passed national quality requirements,\n weather-resistant for more than 10 years with construction, apply in various top floors and steel roofs.',
            img_alt: ''
        },
        __BANNER_5: {
            __TITLE1: 'Renewable power from Biogas electricity, promote sustainable development',
            __TITLE2: 'Nan Ya Red-mud sheet uses high-quality environmentally friendly materials, has excellent anti-aging and weather resistance, apply in various biogas storage equipment.',
            img_alt: ''
        },
        __BANNER_6: {
            __TITLE1: 'The industry time-honored products have countless achievements',
            __TITLE2: 'Nan Ya PU Passed CNS 6986 and CNS 8644 national standards,\nuses environmentally friendly raw materials, does not contain TDI, MOCA and solvents.',
            img_alt: ''
        },
        __BANNER_7: {
            __TITLE1: 'The solar reflectance is over 90%,\n which effectively reduces the temperature inside the coating',
            __TITLE2: 'Nan Ya Cooler Paint Passed green building materials certification\n and the CNS 4940 standard,low VOC and no harmful heavy metals.',
            img_alt: ''
        },
    },
    __SUB_TITLE: {
        Home: {
            description: 'Nan Ya high-quality PVC and PU waterproof materials, top-level protection without any water leakage.From tunnels to hillsides, basements to roofs, reservoirs to swimming pools, provide you with comprehensive protection.',
            introduce: {
                Block_Title: 'Why choose us?',
                Block_Text: 'Nan Ya Plastics has a set of systematic waterproof solutions, from waterproof materials, waterproof engineering design to construction technology, etc., integrating the technology and experience of various customer professional fields, just to give you the most guaranteed waterproof experience.',
                Title_1: 'Our Experience',
                Text_1: 'Our various waterproof material production process and formula are managed by data, and the quality is stable and meets various international specifications.',
                Title_2: 'Construction Projects',
                Text_2: 'The total length of the domestic Tunnel and the Highway Tunnel is about 30 km.',
                Title_3: 'Waterproofing Area',
                Text_3: 'In addition to its excellent quality,we can also customize product specifications according to customer needs, and has a professional R&D and design team.',
                Word_1: 'years over',
                Word_2: 'over',
                Word_3: 'km',
                Word_4: 'sqm',
            },
            productsBlock_Text: 'We provide various PVC and PU waterproof materials from large-scale civil engineering, commercial offices and factories to DIY on the top floor.',
            greenIntroduce: {
                Block_Title: 'Safety, Environment, and Sustainability.',
                Block_Text: 'Nan Ya plastics maintains a strong focus on safety, quality, responsible growth, and value creation during all business activities. We delegates the responsibility for environmental compliance to the operating subsidiaries.'
            },
        },
        Company: {
            description: 'Nan Ya Plastics Company Je-nwu No.2 Factory mainly produces various soft and semi-rigid tapes, including PVC waterproof materials and various plastic products for miscellaneous processing purposes; Shu-Lin No. 2 Factory mainly produces copper foil, fiberglass cloth, epoxy resin and other products , Containing PU waterproof material and heat-insulating paint and other waterproof products, the quality is excellent and stable, and the customers are all over the country and abroad.',
            page_Introduce: 'Founded in 1958, Nan Ya Plastics Corporation is one of the largest suppliers and the most productive manufacturers in the world, encompasses four types of products— Plastic Processing, Plastic Raw Material, Electronic Material, and Polyester Fiber.\
            "Diligence, perseverance, frugality and trustworthiness. Striving for perfection, sustainable operation, and contributing to society." is the core value philosophy inherited from the two founders.',
            Title_intro: 'Introduction',
            Title_1_renwu: 'Jen-Wu Plant ',
            Title_2_shulin: 'Shu-Lin Plant ',
            Title_3: 'Factory History',
            Intro_Text_1: 'Established in 1979, Nan Ya Jen-Wu 2nd plant produce all kinds of PVC sheet and membrane products which apply in decoration and building materials, sign advertising, swimming pool and waterproof materials, etc.',
            Intro_Text_2: "Nan Ya Shu-Lin No.2 Plant was established in 1974 and officially put into production of copper foil substrates in August 1975. It has continuously innovated, reformed and improved into a fully automated production plant.The main products are copper foil substrate and customer base fiberglass film. The main raw materials are copper foil, fiberglass cloth and epoxy resin. All are supplied by the company.Products are widely used in mobile phones, computers, automobiles, communication equipment and various home appliances.",
            timeLineCard_Data: {
                renwu: {
                    card_1: 'Officially passed TAF National Laboratory Certification.',
                    card_2: 'Started process optimization and equipment upgrade, comprehensively added quality testing equipment and greatly increased production capacity.',
                    card_3: 'In cooperation with the Government to promote urban development, the original Qianzhen plant was moved to Jen-Wu, the department was set up to produce interior finishes, swimming pool liners and waterproof membranes.',
                    card_4: 'Merged the masterbatch project group to set up "masterbatch department" to produce various material masterbatches, pigments and inks, etc.',
                    card_5: 'Added ten sets of PVC calendering production equipment and started mass production.',
                    card_6: 'Established in Jen-Wu, Kaohsiung, mainly produce various PVC sheet.',
                },
                shulin: {
                    card_1: 'Officially passed TAF National Laboratory Certification.',
                    card_2: 'RTPMS system establishment, refined management',
                    card_3: 'Won the Excellent Bureau of Energy Award from the Ministry of Economic Affairs.',
                    card_4: 'Officially passed OHSAS-18001 Certification.',
                    card_5: '1958 Established in Shu lin.',
                }
            }
        },
        UseCase: {
            description: 'The performance of Nan Ya PVC membrane waterproofing membrane ranges from the roof waterproofing of domestic factories and residential houses to the tunnel waterproofing projects such as snow mountains and Su-hua-gai, and the swimming pool waterproofing projects in the United States, Canada and other overseas. PU coating waterproofing materials have a number of roof waterproofing projects. And the engineering performance of the factory barrel tank insulation and waterproof coating; the excellent waterproof performance of the product is worthy of your trust and recommendation.',
            card_1: {
                title: 'Roof waterproof membrane',
                locationTitle: 'Chang Gung Stadium',
                locationArea: 'New Taipei City, Taiwan'
            },
            card_2: {
                title: 'Roof waterproof membrane',
                locationTitle: 'Factories and houses',
                locationArea: 'Taiwan'
            },
            card_3: {
                title: 'Waterproof membrane',
                locationTitle: 'No.6 Naphtha Cracker Complex',
                locationArea: 'Yunlin County, Taiwan'
            },
            card_4: {
                title: 'Tunnel waterproof membrane',
                locationTitle: 'Xueshan Tunnel',
                locationArea: 'New Taipei City, Taiwan'
            },
            card_5: {
                title: 'Tunnel waterproof membrane',
                locationTitle: 'Suhuagai Tunnel',
                locationArea: 'Hualien County, Taiwan'
            },
            card_6: {
                title: 'Tunnel waterproof membrane',
                locationTitle: 'National Freeway 4',
                locationArea: 'Taichung City, Taiwan'
            },
            card_7: {
                title: 'Pool liner',
                locationTitle: 'Home with pool',
                locationArea: 'Brazil'
            },
            card_8: {
                title: 'Pool liner',
                locationTitle: 'Villa with pool',
                locationArea: 'Canada'
            },
            card_9: {
                title: 'Pool liner',
                locationTitle: 'Villa with pool',
                locationArea: 'United states'
            },
            card_10: {
                title: 'Pool liner',
                locationTitle: 'Villa with pool',
                locationArea: 'Australia'
            },
            card_11: {
                title: 'Red-mud sheet',
                locationTitle: 'Pig farm',
                locationArea: 'Chiayi County, Taiwan'
            },
            card_12: {
                title: 'Red-mud sheet',
                locationTitle: 'Pig farm',
                locationArea: 'Tainan City, Taiwan'
            },
            card_13: {
                title: 'Cooler paint',
                locationTitle: 'Chang Gung Stadium',
                locationArea: 'New Taipei City, Taiwan'
            },
            card_14: {
                title: 'Cooler paint',
                locationTitle: 'Yong Feng Yu UASB',
                locationArea: 'New Taipei City, Taiwan'
            },
            // 2023/7/7 新增
            card_15: {
                title: 'Cooler paint',
                locationTitle: 'House Roof',
                locationArea: 'Miaoli, Taiwan'
            },
            card_16: {
                title: 'Cooler paint',
                locationTitle: 'Storage tank',
                locationArea: 'Yilan, Taiwan'
            },
            card_17: {
                title: 'Cooler paint',
                locationTitle: 'Container',
                locationArea: 'Taiwan'
            }
        },
        FAQ: {
            description: 'E我們針對客戶常詢問南亞PVC防水膜或PU防水材的產品細節、認證及防水施工等問題，彙整了詳細的防水產品問答集，如想了解更多南亞防水材產品資訊，歡迎致電或來信洽詢我司專人。',
            questionList: [
                // ===================捲材類=================== //
                {// 0
                    questionTitle: 'How to choose a waterproof membrane?',
                    answer: [
                        "(1) The roof waterproof membrane has an extra layer of man-made fiber in the middle, which is less likely to shrink and has better tensile and tear strength.",
                        "(2) The tunnel waterproof membrane is black and white double-color laminated, the white surface can make the leaking place clear, and the use of high-strength formula has good process ability.",
                        "(3) Nan Ya waterproof membranes are easier to construct than HDPE waterproof membranes, and have better weather resistance and puncture resistance."
                    ]
                },
                {// 1
                    questionTitle: 'Does Nan Ya provide construction?',
                    answer: [
                        "Nan Ya Plastics is a manufacturer and does not provide construction services; however, it can introduce professional manufacturers for consultation."
                    ]
                },
                {// 2
                    questionTitle: 'Is there a spot to buy?',
                    answer: [
                        "Our department is order-to-order production, and the delivery time is about 30~45 days.Some products with fixed specifications are planned production, please contact sales personnel for details."
                    ]
                },
                {// 3
                    questionTitle: 'Is the product certified?',
                    answer: [
                        "(1) Tunnel waterproof membrane",
                        "(2) Roof waterproof membrane ",
                        "(3) If there are other inspection requirements, they can be evaluated and sent for inspection.",
                    ]
                },
                {// 4
                    questionTitle: "Can you provide samples?",
                    answer: [
                        "Of course, please contact the professional staff to ask for samples, we are all very generous!",
                    ]
                },
                {// 5
                    questionTitle: "We want to use the roof waterproof membrane on the roof of our house. Is there a way to DIY this?",
                    answer: [
                        "It's better needs to be professionally implemented (site assessment, reinforcement and edge sealing, etc.).Time-saving, effortless and perfect!"
                    ]
                },
                {// 6
                    questionTitle: "What is the difference between the swimming pool lining and the general tile swimming pool?",
                    answer: [
                        "The swimming pool lining is made of high-quality PVC material, which is soft and elastic and can be added with different formulas to achieve different effects. Compared with traditional tiles, it is easier to construct and renovate, and more importantly, there will be no cuts or bruises!",
                    ]
                },
                {// 7
                    questionTitle: "Is it environmentally friendly to use plastic materials? What should I do if I swim and eat every day?",
                    answer: [
                        "In fact, most of the things around you are made of plastic. The focus is on which plastic and what formula. The materials we used are all low-toxic and environmentally friendly formulas, and they have passed a number of international environmental protection certifications.",
                    ]
                },
                {// 8
                    questionTitle: "How many patterns are available for swimming pool lining?",
                    answer: [
                        "We have professional design manufacturers, and also supply a large number of overseas customers such as North America, South America and Australia. If you have any needs, please contact our specialists!",
                    ]
                },
                {// 9
                    questionTitle: "Is anyone in domestic using swimming pool linings to cover swimming pools?",
                    answer: [
                        "Compared with the grand situation where there are swimming pools in every household in Europe, America, etc., home swimming pools are not so popular in Asia at present, but there are many builders that provide this service. If you have any needs, please contact our specialists.",
                    ]
                },
                {// 10
                    questionTitle: "How long can the swimming pool lining last?",
                    answer: [
                        "Our products have all add anti-chlorine formulas, and be tested to be harmless to the human body. Can be used for more than 3-5 years on average overseas,even the relatively cheap price is very suitable for hotels or resorts.",
                    ]
                },
                {// 11
                    questionTitle: "How should a pig farmer use red-mud sheet?",
                    answer: [
                        "We only provides red-mud sheet(roll). If you want to make biogas(gas storage)bags, you need the assistance of a professional processing manufacturer. We will help you contact a well-coordinated processing manufacturer and provide complete teaching and after-sales service!",
                    ]
                },
                // ===================捲材類=================== //
                {// 12
                    questionTitle: "Does PU waterproof material need to be diluted with solvent?",
                    answer: [
                        "It will be added before construction.",
                    ]
                },
                {// 13
                    questionTitle: "After stirring, the processing time is about several hours?",
                    answer: [
                        "About 1 hour.",
                    ]
                },
                {// 14
                    questionTitle: "How long does it take for the surface to dry and fully harden after coating?",
                    answer: [
                        "It will take half an hour for the surface to dry。And two days for fully harden.",
                    ]
                },
                {// 15
                    questionTitle: "Does Cooler paint need to be diluted with water again?",
                    answer: [
                        "No.",
                    ]
                },
                {// 16
                    questionTitle: "How to choose the correct primer when using Cooler Paint?",
                    answer: [
                        "According to the recommendations in the product manual, use different primers for different substrates, or contact the development staff.",
                    ]
                },
                {// 17
                    questionTitle: "How would the coating thickness of Cooler paint effect it's cooling efficient?",
                    answer: [
                        "​​The best thickness of the coating is about 110μm. Excessive thickness does not significantly help the cooling effect. If it is too thin, the cooling effect will be reduced.",
                    ]
                },
                {// 18
                    questionTitle: "Product difference analysis",
                    answer: [
                        "Our raw materials are in compliance with the green building materials label TDI, MOCA and solvents.Passed the national certification, the service life is longer his card is better.",
                    ]
                },
            ]
        },
        Certification: {
            description: '',
            renwu_Text: 'Nan Ya Jen-Wu Plant',
            shulin_Text: 'Nan Ya Shu-Lin Plant',
            altText_1: 'Nan Ya have passed various certification.',
            BSMI_Title: 'Nan Ya PVC & PU waterproof products \n Quality and service guarantee',
            page_Introduce: 'We have passed the certification of ISO 14001 Environmental Management System, OHSAS 18001 Occupational Safety and Health Management System and Taiwan Occupational Safety and Health Management System (TOSHMS).In addition to conducting environmental management operations in accordance with regulations, we also review the latest laws and regulations to ensure that related operations comply with regulations.Every year, the original verification unit (Bureau of Standards, Metrology, Inspection and Quarantine, SGS) regularly visits the factory for re-inspection, and actively improves to achieve the ISO spirit of "compliance with laws and regulations, continuous improvement".',
            greenMaterialTitle: 'Nan Ya Shu-Lin Plant \n won Green Building Material Certification',
            greenMaterialText: 'Green building materials should not cause environmental pollution at the stages of raw material acquisition, production, transportation and use of finished products; according to the "Green Building Materials Label Application for Review and Approval and Operational Points of Use".',
            greenMaterial_altText: 'Green Building Material Certification',
            certTitle_1: 'ISO9001',
            certTitle_2: 'ISO14001',
            certTitle_3: 'OHSAS18001',
            certTitle_4: 'ISO17025-TAF', 
            certTitle_5: 'Green Building Material Certification',
            certFile_renwu: [
                '仁武廠ISO9001證書_tw.pdf',
                '仁武廠ISO14001證書_tw.pdf',
                '仁武廠OHSAS18001證書_tw.pdf',
                '仁武廠ISO17025-TAF證書_tw.pdf'
            ],
            certFile_shulin: [
                '樹林廠ISO9001證書_tw.pdf',
                '樹林廠ISO14001證書_tw.pdf',
                '樹林廠OHSAS18001證書_tw.pdf',
                '樹林廠ISO17025-TAF證書_tw.pdf'
            ],
        },
        Contact: {
            description: 'If there is any advice or suggestion, welcome to email or call us. We are willing to respond soon and appreciate your feedbacks.',
            header_Title: 'Information Fill',
            header_Text: 'If there is any advice or suggestion, welcome to email or call us. We are willing to respond soon and appreciate your feedbacks.',
            name: 'Name',
            phone: 'Tel',
            mail: 'E-mail',
            products_type: 'Products Category',
            message: 'Message',
            verify_Code: 'Verification Code',
            contactCard_Title: 'Contact Information',
            contactCard: {
                card_1: {
                    title: 'Name: Chi-Feng Sun',
                    phone: '(02) 2712-2211 Ext.8200',
                    mail: 'jackshan@npc.com.tw'
                },
                card_2: {
                    title: 'Name: Pan Chieh-Yu',
                    phone: '(02) 2712-2211 Ext.6222',
                    mail: 'panlin2033@hotmail.com.tw'
                }
            },
            warnningText: {
                name: {
                    warn_1: 'Type in your name.',
                },
                phone: {
                    warn_1: 'Please enter your phone number.',
                },
                mail: {
                    warn_1: 'Enter your e-mail.',
                    warn_2: 'Error! Please enter again.',
                },
                selectClass: {
                    warn_1: 'Select category.',
                },
                message: {
                    warn_1: 'Enter your suggestion.',
                },
                code: {
                    warn_1: 'Enter verification code！',
                    warn_2: 'Error! Please enter again.',
                },
                send: {
                    warn_1: 'Form uncompleted!Please resend again after finishing the form.',
                    warn_2: 'Completed!',
                    warn_3: 'Error!'
                }
            },
            mailFormat_Data: {
                contact_Title: 'Contact Information',
                sender: 'Nan Ya Plastics-Waterproof Material',
                subject: 'We have received your message.Please go to your mailbox to confirm.',
                endText1: 'Thank you for contacting us.',
                endText2: 'If there has any question, please contact us right away. Thanks.'
            }
        },
    },
    __PRODUCTS: {
        products_Page: {
            description: '',
            page_Introduce: "Waterproofing is an indispensable condition in daily life. How to use appropriate materials is the first step to successful waterproofing. According to the different construction sites, as well as the needs of waterproof strength and service life, Nan ya is committed to researching and developing various waterproof materials. From large-scale civil engineering, commercial offices and factories to DIY on the top floor.Our high- quality waterproof materials are worth your trust",
            coil_Introduce: 'PVC film(sheet) is produced using a large-scale calendering machine, the finished product is presented in a roll form. It can be customized to any specifications according to customer needs, and different formulas can be added according to its application.',
            coating_Introduce: 'Nan Ya Cooler paint and PU waterproofing material are solvent-free and environment friendly.PU waterproof material can be used in places such as baths, toilets and roofs. Roof waterproofing can be used with Cooler paint to form a good waterproof and thermal insulation system, extend product life, and guarantee 5 years. The product is easy to construct and effectively improves the quality of home life.',
            green_Energy: {
                title: 'Green energy products',
                Block_Text: 'We committed to the research and development of environmentally friendly and green energy-related waterproof materials, and at the same time, vertical integration of resources and layout of the future green energy market. Actively adopt various forward-looking practices that are friendly to the environment,  reduce the impact and harm to the environment.',
                card1_Text: 'PU waterproof material is made of two-component solvent-free PU material, and its physical properties conform to the national standard CNS 6986.',
                card2_Text: 'The solar reflectance is over 90%, which effectively reduces the temperature inside the coating.Low VOC, no harmful heavy metals, passed the green building materials certification.',
                card3_Text: 'Nan Ya General Waterproof Membrane can prevent hazardous substances from penetrating into the ground underneath the pipeline.',
                card4_Text: 'NAN YA Roof Waterproof Membrane for color steel roofs can be integrated with new solar panels, which can reduce carbon emissions and protect the Earth.'
            },
            search_Title1: 'Product Information',
            search_Title2: 'Search result',
            search_optionList: [
                'Building',
                'Color steel',
                'Industrial',
                'Agriculture',
                'Transprotation',
                'Civil engineering',
                'Leisure life',
                'Others'
            ],
        },
        __TEMPLATE: {
            feature_Title: 'Characteristics',
            advantage_title: 'Advantage',
            application_Title: 'Applications',
            media_Title: 'Videos',
            specification: 'Specifications',
            file_Title: 'Catalogue',
            coil_Form_header: {
                thickness: 'thickness',
                width: 'width',
                rollpackaging: 'roll',
                color: 'color'
            },
            coating_Form_header: {
                cooler_T1: 'Cooler Paint',
                cooler_T2: 'Nan Ya Anti-Corrosion Paint',
                cooler_T3: 'Drum',
                cooler_T4: 'Adhesion PRIMER',
                PU_T1: 'Base material',
                PU_T2: 'Caulking material',
                PU_T3: 'Floor plank',
                PU_T4: 'Roof waterproof material',
                PU_T5: 'Anti-sag wall sheet',
                PU_T6: 'PU waterproof material',
                PU_T7: 'Surface treatment material',
            }
        },
        tough_Waterproof: {
            title: 'General Waterproof Membrane',
            home_CardText: 'High-strength, waterproof, moisture-proof, durable and puncture-resistant polyvinyl chloride material, suitable for use in major civil engineering and environmental engineering.',
            Page: {
                description: 'Nan Ya Waterproof Membrane is a civil engineering membrane with excellent shock resistance, aging resistance, puncture resistance, water resistance, acid and alkali resistance.It can resist acid and alkali corrosion for a long time, and still maintain good dimensional stability.',
                introText: 'Nan Ya Waterproof Membrane is a civil engineering membrane with excellent shock resistance, aging resistance, puncture resistance, water resistance, acid and alkali resistance. It can resist acid and alkali corrosion for a long time, and still maintain good dimensional stability.',
                featureList: [
                    "Good physical strength, weather resistance, volatility resistance, etc.",
                    "Suitable for hot-air welding and high-frequency seaming.",
                    "Comply with PGI standards(PVC Geomembrane lnstitute).",
                    "Stable physical properties and appearance quality.",
                    "Adjust the softness according to customer needs."
                ],
                advantageText: 'Nan Ya general waterproof membrane is a civil engineering membrane with excellent shock resistance, aging resistance, puncture resistance, water resistance, acid and alkali resistance. It can resist acid and alkali corrosion for a long time, and still maintain good dimensional stability.',
                applicationText: 'Basement, garbage burial sites, sewage treatment ponds, reservoirs, artificial lakes, tunnels, chemical barrel tank overflow dikes and other waterproof engineering.',
                application_Img_alt: [
                    "",
                    "",
                    "",
                    ""
                ],
                mediaList: [
                    {
                        mediaTitle: 'Nan Ya PVC Waterproof Membrane',
                        mediaSrc: 'https://www.youtube.com/embed/bmPVtNaXJ3k'
                    }
                ],
                fileList: [
                    {
                        fileTitle: "Waterproof DM (.pdf)",
                        fileLink: "normal/防水膜樣卡.pdf"
                    }
                ],
                formData: {
                    text1: 'single layer(black)'
                }
            }
        },
        roof_Waterproof: {
            title: 'Roof Waterproof Membrane',
            home_CardText: 'With reinforced materials to increase toughness, adding anti-UV formula passed the QUV test, which can apply in various roof waterproof engineering.',
            Page: {
                description: 'Nan Ya roof waterproof membrane is also a PVC waterproof laminated rubber (polymer reinforced waterproof membrane) with a strong polyester fiber mesh to prevent the tape from being excessively stretched and deformed. It is used in Long-term outdoor and harsh environment.',
                introText: 'New type of reinforced waterproof membrane is developed after adding strong polyester fiber mesh cloth to prevent excessive stretching and deformation of the waterproof membrane, and it can be used in long-term outdoor and harsh environments. RC roof waterproofing and color steel roof repairing and other waterproof projects.',
                featureList: [
                    'Good physical properties and weather resistance.',
                    'Suitable for hot-air welding without any adhesive.',
                    'Good dimensional stability, low shrinkage. With the correct construction method, it can be used for up to 10 years.',
                    'Excellent acid and alkali resistance and fire resistance.',
                    'Various specifications, supply max. width 2.0 meters.'
                ],
                advantageText: '抗拉強度及耐用度高，可用於長期室外及嚴苛環境中，如RC屋頂防水及彩鋼屋頂維修等防水工程。',
                applicationText: 'Various top floors, steel roofs and roofing, etc.',
                application_Img_alt: [
                    '',
                    '',
                    '',
                    ''
                ],
                mediaList: [
                    {
                        mediaTitle: 'Roof Waterproof Project of Chang Gung University Gymnasium',
                        mediaSrc: 'https://www.youtube.com/embed/xrg7dokp_Ps',
                    }
                ],
                fileList: [
                    {
                        fileTitle: 'Waterproof Membrane DM (.pdf)',
                        fileLink: 'normal/防水膜樣卡.pdf'
                    },
                    {
                        fileTitle: 'Waterproof Tarpaulin Leather SGS Certification (.pdf)',
                        fileLink: 'tunnel/SGS加筋防水膜-綜合板.pdf'
                    }
                ],
                formData: {
                    text1: 'double layer(white/black)'
                }
            }
        },
        tunnel_Waterproof: {
            title: 'Tunnel Waterproof Membrane',
            home_CardText: 'With two color product structure, using high-strength formula passed the DIN16726 test, which can apply in tunnel waterproof engineering.',
            Page: {
                description: 'Nan Ya Tunnel Waterproof Membrane is made of double-layer high-strength PVC material. It has high-strength physical properties and passed the DIN16726 test. It is the most suitable material for traffic and tunnel waterproof engineering.',
                introText: 'Nan Ya Tunnel Waterproof Membrane is made of double-layer high-strength PVC material. It has high-strength physical properties and passed the DIN16726 test. It is the most suitable material for traffic and tunnel waterproof engineering.',
                featureList: [
                    'Thickness above 2.0mm has excellent mechanical strength and aging resistance.',
                    'Water pressure resistance, acid and alkali resistance and fire resistance are in line with DIN and ASTM test standards.',
                    'Simple construction, hot air processing can be directly welded and bonded, and the wind pressure test is qualified.',
                    'The use of black and white double contrast color design(signal layer), easy to identify the damage can be repaired in time.'
                ],
                advantageText: 'It has passed the DIN16726 test standard and complies with the public engineering specifications. The seam strength meet its construction requirements. It has been used in major domestic and foreign tunnels No abnormalities for more than decades.',
                applicationText: 'Major public tunnel projects, such as high-speed rail, railway, MRT and expressway, etc.',
                application_Img_alt: [
                    '',
                    '',
                    '',
                    ''
                ],
                // mediaList: null,
                fileList: [
                    {
                        fileTitle: 'Waterproof Membrane DM (.pdf)',
                        fileLink: 'normal/防水膜樣卡.pdf'
                    },
                    {
                        fileTitle: 'Tunnel Waterproof Membrane SGS Certification (.pdf)',
                        fileLink: 'tunnel/SGS隧道防水膜-綜合板.pdf'
                    }
                ],
                formData: {
                    text1: 'double layer(white/black)'
                }
            }
        },
        red_Tape: {
            title: 'Red-Mud Sheet',
            home_CardText: 'Nan Ya Red-mud sheet uses high-quality environmentally friendly materials, has excellent anti-aging and weather resistance which apply in biogas collection and storage equipment.',
            Page: {
                description: 'Generally, plastic materials have poor weather resistance and are prone to aging and cracking after continued exposure. In order to overcome its shortcomings, Nan Ya has developed Red-mud sheet which has excellent weather-resistance against aging.',
                introText: 'Excellent anti-aging and weather resistance, maintain strong physical properties even after long-term exposed.',
                featureList: [
                    'Excellent anti - aging and weather resistance, maintain strong physical properties even after long - term exposed.',
                    'Use hot- melt joint construction, convenient construction and stable quality.',
                    'With a sustainable special environmentally friendly formula, it is resistant to chemicals and acids and alkalis.',
                    '用途廣泛包含建築、土木、農業、水利及工業用等。'
                ],
                advantageText: 'Good weather resistance, long exposure to the ASTM-D2565 test method, with chemical resistance characteristics, high-strength physical properties and hot-melt bonding processing advantages.',
                applicationText: 'Biogas collection and storage equipment from anaerobic decomposition. Biogas tanks, airtight rice storage silos, mushroom farms, etc.',
                application_Img_alt: [
                    '',
                    '',
                    '',
                    ''
                ],
                // mediaList: null,
                fileList: [
                    {
                        fileTitle:"Red-Mud Sheet DM (.pdf)",
                        fileLink: "red_mud/紅泥膠布DM.pdf"
                    }
                ]
            }
        },
        pool: {
            title: 'Pool liner',
            home_CardText: 'With polymer structure to replace swimming pool tiles, which is more safety and durable for construction in various swimming pool. Our quality is in line with the international standard.',
            Page: {
                description: 'Nan Ya took the lead in keeping up with international standards, using polymer materials to replace traditional tiles, and developing brand new, high-quality and durable swimming pool linings.',
                introText: 'Nan Ya took the lead in keeping up with international standards, using polymer materials to replace traditional tiles, and developing brand new, high-quality and durable swimming pool linings.',
                featureList: [
                    'High-quality PVC material can be customized with various specifications.',
                    'Various special embossing, non-slip properties for ladder and bathroom floor mats.',
                    'Reinforced structure reduce the shrinkage rate and pass the EN1107-2 test. ',
                    'Special formula chlorine resistance pass the EN15836 test, no color change over time.',
                    'High frequency or hot air to process into a swimming pool, simple construction and easy to renovate.',
                    'Professional design team can cooperate with OEM or ODM, and any design can be customized.'
                ],
                advantageText: 'Good weather resistance, low temperature can reach -37.2°C, and has the advantages of anti-mildew and antibacterial formula, good chlorine and chemical resistance, and high-frequency seaming process. Now it has been sold to all parts of the world, including America, Australia and Europe, in line with international standards and quality certification.',
                applicationText: 'Private or public swimming pools. Indoor and outdoor swimming pools for children, spa pools and competitions.Bathroom floor mats and non- slip swimming pool ladder.',
                application_Img_alt: [
                    '',
                    '',
                    '',
                    ''
                ],
                // mediaList: null,
                fileList: [
                    {
                        fileTitle: "Pool liner DM (.pdf)",
                        fileLink: "pool/游泳池裡襯DM_en.pdf"
                    }
                ],
                formData: {
                    thickness_text1: 'Above-ground 0.280~0.762 mm',
                    thickness_text2: 'In-ground 0.495~0.902 mm',
                    thickness_text3: 'Reinforced 0.9~1.5 mm',
                    roll_text1: 'General 150～300 yard/roll',
                    roll_text2: 'Reinforced 30～50 yard/roll',
                }
            }
        },
        Cooler_Paint: {
            title: 'Cooler Paint',
            home_CardText: 'Nan Ya Cooler paint has a patent, with a service life of 5 to 8 years, which is higher than that of commercially available products More than 2 times. ',
            Page: {
                description: 'Nan Ya Cooler paint can effectively reduce the indoor temperature, the energy saving effect is as high as 31.8%, and It is also waterproof.',
                introText: 'Nan Ya Cooler paint can effectively reduce the indoor temperature, the energy saving effect is as high as 31.8%, and It is also waterproof.',
                featureList: [
                    'Multi-functional water-based coating topcoat material, matched with primer, excellent weather resistance.',
                    'The solar reflectance is over 90%, which effectively reduces the temperature inside the coating.',
                    'Strong self-cleaning, not easy to get dirty, and waterproof.',
                    'Low VOC, no harmful heavy metals, passed the green building materials certification.',
                    'Passed the Taiwan standard CNS 4940 specification, and the quality certification is guaranteed.'
                ],
                advantageText: 'Nan Ya cooler paint heat insulation coating has a patent, and the service life can reach 5 to 8 years.It is more than 2 times higher than general marketed products. Effectively reduce the indoor temperature and save energy.The effect is as high as 31.8%, and it is waterproof.',
                applicationText: 'Insulation works for roofs, exterior walls, color steel, barrel troughs, etc.',
                application_Img_alt: [
                    '',
                    '',
                    '',
                    ''
                ],
                mediaList: [
                    {
                        mediaTitle: "Construction Case",
                        mediaSrc: "https://www.youtube.com/embed/tKpxQ-rP9wk",
                    },
                    {
                        mediaTitle: "House Construction",
                        mediaSrc: "https://www.youtube.com/embed/Un1WAuWw4G4",
                    },
                    {
                        mediaTitle: "Soak Test",
                        mediaSrc: "https://www.youtube.com/embed/yEjjrYffd0E",
                    },
                    // {
                    //     mediaTitle: "浸泡試驗",
                    //     mediaSrc: "https://www.youtube.com/embed/xTVa5vQqLrw",
                    // },
                    // {
                    //     mediaTitle: "美的in台灣",
                    //     mediaSrc: "https://www.youtube.com/embed/JBjuhjybJx8",
                    // },
                    // {
                    //     mediaTitle: "Construction",
                    //     mediaSrc: "https://www.youtube.com/embed/SzXl9bDUCuY",
                    // },
                ],
                fileList: [
                    {
                        fileTitle: "Cooler Paint (.pdf)",
                        fileLink: "cooler/酷樂漆DM_tw.pdf"
                    },
                    {
                        fileTitle: "Cooler Paint flame resistance test report (.pdf)",
                        fileLink: "cooler/酷樂漆耐燃測試報告.pdf"
                    },
                    {
                        fileTitle: "Cooler Paint xenon arc lamp 2500hr test report (.pdf)",
                        fileLink: "cooler/酷樂漆氙弧燈2500hr測試報告.pdf"
                    },
                    {
                        fileTitle: "Cooler Paint SGS salt spray resistance 4200hr test report (.pdf)",
                        fileLink: "cooler/酷樂漆SGS耐鹽霧4200hr測試報告.pdf"
                    },
                    {
                        fileTitle: "Nanya CSL-01 system in CX environment report (.pdf)",
                        fileLink: "cooler/南亞CSL01系統CX認證報告.pdf"
                    },
                    {
                        fileTitle: "Cooler Paint green building material label(~2023) (.pdf)",
                        fileLink: "cooler/南亞水性隔熱漆綠建材標章.pdf"
                    },
                    {
                        fileTitle: "Cooler Paint UV 1000hr test report to ASTM G154-16 (.pdf)",
                        fileLink: "cooler/酷樂漆QUV 1000小時測試報告.pdf"
                    },
                    {
                        fileTitle: "Cooler Paint VOC test report (.pdf)",
                        fileLink: "cooler/酷樂漆SGS VOC測試報告.pdf"
                    },
                    {
                        fileTitle: "Cooler Paint SRI test reports to ASTM E1980-11 (.pdf)",
                        fileLink: "cooler/酷樂漆日光反射指數測試報告.pdf"
                    },
                    {
                        fileTitle: "Cooler Paint in Taiwan invention patent I626215 (.pdf)",
                        fileLink: "cooler/酷樂漆專利證書台灣發明I626215證書.pdf"
                    },
                    {
                        fileTitle: "Nanya CSL-01 sysrem VOC test report to CNS15039-2 (.pdf)",
                        fileLink: "cooler/酷樂漆塗料公會VOC測試報告.pdf"
                    },
                    {
                        fileTitle: "Cooler Paint green building material label(~2027) (.pdf)",
                        fileLink: "cooler/酷樂漆綠建材標章證書.pdf"
                    }
                ],
                formData: {
                    text1: `the total of  24 colors \n eg: white, gray white, light sand, etc`,
                    text2: `Main agent：dark green、gray \n Hardener：brown`,
                    text3: 'RC, Tank, Corrugated Steel Sheet',
                    text4: `Main agent：19kg / 8kg \n Hardener：7kg / 3kg`,
                }
            }
        },
        PU_Waterproof: {
            title: 'PU Waterproof',
            home_CardText: 'The waterproof material is made of two-component solvent-free PU material, and its physical properties conform to the national standard CNS 6986.he main purpose is for general roof waterproofing and waterproofing of other places.',
            Page: {
                description: 'The waterproof material is made of two-component solvent-free PU material, and its physical properties conform to the national standard CNS 6986. The raw materials used meet the requirements of environmental protection, pass the inspection standards of green building materials, and do not contain toxic chemical substances such as P plasticizer, MOCA, eight heavy metals and zero environmental formaldehyde content. The main purpose is for general roof waterproofing and waterproofing of other places.',
                introText: 'The waterproof material is made of two-component solvent-free PU material, and its physical properties conform to the national standard CNS 6986.he main purpose is for general roof waterproofing and waterproofing of other places.',
                featureList: [
                    'Good physical strength, good weather resistance (cold, heat, acid, alkali), and long service life.',
                    'Low VOC, 100% solid content.',
                    'The products have passed the CNS 6986 and CNS 8644 national standards and have the orthographic mark certification.',
                    'Complies with the green building materials label, uses environmentally friendly raw materials, does not contain TDI, MOCA and solvents.',
                    "The industry's time - honored products have countless achievements."
                ],
                advantageText: 'Nan Ya PU waterproof material is a product carefully researched and developed by our company. It has good seismic resistance, aging resistance, water resistance, acid and alkali resistance, and is suitable for various construction sites on RC surfaces.',
                applicationText: 'It is suitable for waterproofing in general roofs, basements, bathrooms, interior and exterior walls and other areas that are prone to water and damp.',
                application_Img_alt: [
                    '',
                    '',
                    '',
                    ''
                ],
                mediaList: [
                    {
                        mediaTitle: "House Construction",
                        mediaSrc: "https://www.youtube.com/embed/Un1WAuWw4G4",
                    },
                ],
                fileList: [
                    {
                        fileTitle: "Nanya waterproof  material US-10 green building material label (.pdf)",
                        fileLink: "PU_Waterproof/南亞PU防水材US-10(黑色)綠建材標章證書.pdf"
                    },
                    {
                        fileTitle: "Nanya waterproof material US-20 green building material label (.pdf)",
                        fileLink: "PU_Waterproof/南亞PU防水材US-20(黑色)綠建材標章證書.pdf"
                    },
                    {
                        fileTitle: "Nanya PU building material CNS mark (.pdf)",
                        fileLink: "PU_Waterproof/南亞PU建材正字標記證書.pdf"
                    },
                ],
                formData: {
                    text1: `Main agent：20kg \n Hardener：10kg`,
                    text2: `Main agent：10kg \n Hardener：5kg`,
                    text3: `Main agent：16kg \n Hardener：4kg`,
                    text4: `Main agent：10kg \n Hardener：10kg`,
                    text5: `Main agent：9kg \n Hardener：3kg`
                }
            }
        }
    }
}